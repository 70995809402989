import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Auth } from "./components/Auth";
import { Navigation} from "./components/Navigation"
import { CustomModal } from "./components/CustomModal";
import { Profile } from "./pages/Profile";
import { Bookings } from "./pages/Bookings";
import { Loyalty } from "./pages/Loyalty";
import { Travellers } from "./pages/Travellers";
import { ROUTES_LINKS } from "./utils/routesLinks";
import { BookingDetails } from "./pages/BookingDetails";

const App: React.FC = () => {
  const { t } = useTranslation();

  // Токен для авторизации
  const getAccessToken = localStorage.getItem("accessToken");

  const [openModalAuth, setOpenModalAuth] = useState(true);
  const [isAuthUser, setIsAuthUser] = useState(!!getAccessToken);

  useEffect(() => {
    if (!getAccessToken) {
      setIsAuthUser(false)
    }
  }, []);

  console.log("isAuthUser: ", isAuthUser);

  return (
    <div className="App">
      {isAuthUser ? (
        <>
          <Navigation 
            links={ROUTES_LINKS}
          />
          <Routes>
            <Route path="/" element={<Profile />} />
            <Route path="/bookings" element={<Bookings />} />
            <Route path="/loyalty" element={<Loyalty />} />
            <Route path="/travellers" element={<Travellers />} />
            <Route path="/bookingDetails/:id" element={<BookingDetails />} />
          </Routes>
        </>
      ) : (
        <CustomModal
          open={openModalAuth}
          setOpen={(value) => setOpenModalAuth(value)}
          title={t("login")}
        >
          <Auth setIsAuthUser={(value) => setIsAuthUser(value)} />
        </CustomModal>
      )}
    </div>
  );
};

export default App;
