import { useState } from "react";
import { useTranslation } from "react-i18next";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { CustomRadioGroupProps } from "./types";
import { StyledRadioGroupWrapper } from "./CustomRadioGroup.styles";

export const CustomRadioGroup = ({
  label,
  defaultValue,
  onChange,
  children,
}: CustomRadioGroupProps) => {
  const { t } = useTranslation();

  const [value, setValue] = useState(defaultValue);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
    onChange((event.target as HTMLInputElement).value);
  };

  return (
    <FormControl>
      <StyledRadioGroupWrapper>
        <FormLabel id="radio-buttons">{t(label)}</FormLabel>
        <RadioGroup
          value={value}
          aria-labelledby="radio-buttons"
          defaultValue={defaultValue}
          name="radio-buttons-group"
          onChange={handleChange}
        >
          {children}
        </RadioGroup>
      </StyledRadioGroupWrapper>
    </FormControl>
  );
};
