export const DOCUMENTS = [
  {
    name: "Boarding pass"
  },
  {
    name: "Instruction"
  },
  {
    name: "Restaurant menu"
  }
]