import { t } from "i18next";
import IconUinion from "../../icons/IconUniom";
import { FormCheckBonuses } from "../../components/Forms/FormCheckBonuses";
import { TransactionsTable } from "../../components/TransactionsTable";
import { TransactionsListMobile } from "../../components/TransactionsListMobile";
import { TRANSACTIONS_TEST_DATA } from "../../components/TransactionsTable/consts";
import {
  StyledBackground,
  StyledLoyaltyContentColumn,
  StyledContentWrapper,
  StyledLoyaltyContent,
  StyledLoyaltyNumberCard,
  StyledLoyaltyStatus,
  StyledLoyaltyTitle,
  StyledLoyaltyWrapper,
  StyledLoyalty,
  StyledHeader,
  StyledSignature,
  StyledLevel,
  StyledBonuses,
  StyledBonusesPoints,
  StyledBonusesHold,
  StyledBonusesData,
  StyledBonusAccuralColumn,
  StyledBonusDescription,
  StyledReadMore,
  StyledFormBonusCheckWrapper,
  StyledTransactionsSectionWrapper,
  StyledTableTransactionsWrapper,
  StyledTransactionsWrapperMobile,
  StyledTransactionsSectionWrapperMobile,
} from "./Loyalty.styles";

export const Loyalty = () => {
  return (
    <StyledLoyalty>
      <StyledHeader>
        <StyledLoyaltyTitle>{t("loyalty")}</StyledLoyaltyTitle>
      </StyledHeader>

      <StyledLoyaltyWrapper>
        <StyledLoyaltyContentColumn className="loyalty">
          <StyledBackground>
            <IconUinion />
          </StyledBackground>

          <StyledContentWrapper>
            <StyledLoyaltyContent>
              <StyledLevel>
                <StyledLoyaltyStatus>7%</StyledLoyaltyStatus>
                <StyledSignature>{t("discountLevel")}</StyledSignature>
              </StyledLevel>

              <StyledBonuses>
                <StyledBonusesPoints>
                  <StyledBonusesData>808</StyledBonusesData>
                </StyledBonusesPoints>
                <StyledSignature>{t("bonusPointsAvailable")}</StyledSignature>

                <StyledBonusesHold>
                  <StyledBonusesData>0</StyledBonusesData>
                  <StyledSignature>{t("bonusesOnHold")}</StyledSignature>
                </StyledBonusesHold>
              </StyledBonuses>
            </StyledLoyaltyContent>
          </StyledContentWrapper>
          <StyledLoyaltyNumberCard>1244 5678 9012 3456</StyledLoyaltyNumberCard>
        </StyledLoyaltyContentColumn>

        <StyledBonusAccuralColumn>
          <StyledLoyaltyTitle className="another">
            {t("bonusAccrual")}
          </StyledLoyaltyTitle>

          <StyledFormBonusCheckWrapper>
            <FormCheckBonuses />
          </StyledFormBonusCheckWrapper>

          <StyledBonusDescription>
            {t("bonusDescription")}
          </StyledBonusDescription>
        </StyledBonusAccuralColumn>

        <StyledReadMore>{t("readMoreAboutLoyaltyProgram")}</StyledReadMore>
      </StyledLoyaltyWrapper>

      <StyledTransactionsSectionWrapper>
        <StyledLoyaltyTitle>{t("transactionHistory")}</StyledLoyaltyTitle>

        {/* Таблица с транзакциями (только desktop) */}
        <StyledTableTransactionsWrapper>
          <TransactionsTable transactions={TRANSACTIONS_TEST_DATA} />
        </StyledTableTransactionsWrapper>
      </StyledTransactionsSectionWrapper>

      {/* Блок с транзакциями (только mobile) */}
      <StyledTransactionsWrapperMobile>
        <StyledTransactionsSectionWrapperMobile>
          <TransactionsListMobile 
            transactions={TRANSACTIONS_TEST_DATA}
          />
        </StyledTransactionsSectionWrapperMobile>
      </StyledTransactionsWrapperMobile>
    </StyledLoyalty>
  );
};
