import styled from 'styled-components'
import { mainTheme } from "../../theme"

// Стили для таблицы
export const StyledTableWrapper = styled.div`
  background: ${mainTheme.main.colors.white};

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    display: none;
  }

  .MuiTableCell-head {
    padding-left: 0;
    color: ${mainTheme.main.colors.merin};
    font-size: ${mainTheme.main.fontSizes.small};
    font-weight: ${mainTheme.main.fontWeights.normal};
    font-family: ${mainTheme.main.fonts.dmsRegular};
    text-transform: capitalize;
  }

  .MuiTableRow-root {
    &:hover {
      background: ${mainTheme.main.colors.white} !important;
    }
  }

  .MuiTableCell-body {
    padding-left: 0;
    color: ${mainTheme.main.colors.black};
    font-size: 15px;
    font-weight: ${mainTheme.main.fontWeights.normal};
    font-family: ${mainTheme.main.fonts.dmsRegular};
  }
`

export const StyledTableCellText = styled.div`
  color: ${mainTheme.main.colors.black};
  font-size: 15px;
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
`

export const StyledTableCellBonuse = styled.div`
  color: ${mainTheme.main.colors.black};
  font-size: 15px;
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  text-align: right;

  &.plus {
    color: ${mainTheme.main.colors.green};
  }

  &.minus {
    color: ${mainTheme.main.colors.red};
  }
`





