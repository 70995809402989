import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { AdditionalServices } from "../../components/AdditionalServices";
import { AdditionalServicesMobile } from "../../components/AdditionalServicesMobile";
import { BookingDetailsInfo } from "../../components/BookingDetailsInfo";
import { BookingDetailsInfoMobile } from "../../components/BookingDetailsInfoMobile";
import { Comments } from "../../components/Comments";
import { Cruise } from "../../components/Cruise";
import { CruiseMobile } from "../../components/CruiseMobile";
import { Customer } from "../../components/Customer";
import { CustomerMobile } from "../../components/CustomerMobile";
import { DocumentsSection } from "../../components/DocumentsSection";
import { PaymentSchedule } from "../../components/PaymentSchedule";
import { PolicyRemark } from "../../components/PolicyRemark";
import { PriceDetails } from "../../components/PriceDetails";
import { Staterooms } from "../../components/Staterooms";
import { StateroomsMobile } from "../../components/StateroomsMobile";
import { ORDER_INFO } from "../../appolo/graphql/queries/order";
import {
  StyledWrapperBookingDetails,
  StyledWrapperBookingDetailsColumn,
  StyledWrapperBookingDetailsColumnMobile,
} from "./BookingDetails.styles";

export const BookingDetails = () => {

  const params = useParams()

  const { data: orderInfoData, loading, refetch } = useQuery(ORDER_INFO, {
    variables: {
      uuid: params?.id,
      context: {
        market: "ru"
      },
    },
    onCompleted: ({ order }) => {
      console.log("order: ", order)
    },
  })

  console.log("orderInfoData: ", orderInfoData);

  return (
    <StyledWrapperBookingDetails>
      {/* Только desktop */}
      <StyledWrapperBookingDetailsColumn>
        <BookingDetailsInfo />
        <Customer />
        <Cruise />
        <Staterooms />
        <AdditionalServices />
        <PolicyRemark />
        <Comments />
      </StyledWrapperBookingDetailsColumn>

      {/* Только desktop */}
      <StyledWrapperBookingDetailsColumn>
        <PriceDetails />
        <PaymentSchedule />
        <DocumentsSection />
      </StyledWrapperBookingDetailsColumn>

      {/* Только mobile */}
      <StyledWrapperBookingDetailsColumnMobile>
        <BookingDetailsInfoMobile />
        <CruiseMobile />
        <PriceDetails />
        <CustomerMobile />
        <StateroomsMobile />
        <AdditionalServicesMobile />
        <PolicyRemark />
        <Comments />
        <PaymentSchedule />
        <DocumentsSection />
      </StyledWrapperBookingDetailsColumnMobile>
    </StyledWrapperBookingDetails>
  );
};
