import { gql } from '@apollo/client'

export const CURRENT_USER = gql`
  query CURRENT_USER {
    currentUser {
      id
      uuid
      crmUserId
      kind
      cashRegisterId
      firstname
      lastname
      patronymic
      email
      phone
      sex
      markets {
        id
        name
        alias
        timezone
        settings {
          isPassengersDocumentsRequired
        }
      }
      permissions {
        canRefundAnyOrder
      }
    }
  }
`
