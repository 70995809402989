import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import { mainTheme } from "../../../theme";
import { FormForgotPasswordProps } from "./types";
import {
  StyledWrapper,
  StyledButtonsWrapper,
  StyledButtonCancel,
  StyledButtonSave,
} from "./FormForgotPassword.styles";

type IFormForgotPasswordInput = {
  password?: string;
  repeatPassword?: string;
};

export const FormForgotPassword = ({ setOpen }: FormForgotPasswordProps) => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    password: yup.string(),
    repeatPassword: yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<IFormForgotPasswordInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      password: "123",
      repeatPassword: "123",
    },
  });

  const onSubmit = async (data: IFormForgotPasswordInput) => {
    const formValue = {
      ...data,
    };
    console.log("formValue: ", formValue);
  };

  const handleCancel = () => {
    if (setOpen) {
      setOpen(false);
    }
  };

  return (
    <StyledWrapper>
      <form>
        <Controller
          control={control}
          name="password"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <TextField
              label={t("password")}
              variant="standard"
              onChange={onChange}
              value={value}
              InputProps={{
                className: "textFieldForm",
                style: {
                  color: mainTheme.main.colors.black,
                  fontSize: mainTheme.main.fontSizes.large,
                  fontWeight: mainTheme.main.fontWeights.normal,
                  fontFamily: mainTheme.main.fonts.dmsRegular,
                },
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="repeatPassword"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <TextField
              label={t("repeatPassword")}
              variant="standard"
              onChange={onChange}
              value={value}
              InputProps={{
                className: "textFieldForm",
                style: {
                  color: mainTheme.main.colors.black,
                  fontSize: mainTheme.main.fontSizes.large,
                  fontWeight: mainTheme.main.fontWeights.normal,
                  fontFamily: mainTheme.main.fonts.dmsRegular,
                },
              }}
            />
          )}
        />

        <StyledButtonsWrapper>
          <StyledButtonCancel variant="contained" onClick={handleCancel}>
            {t("cancel")}
          </StyledButtonCancel>
          <StyledButtonSave
            type="submit"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            {t("save")}
          </StyledButtonSave>
        </StyledButtonsWrapper>
      </form>
    </StyledWrapper>
  );
};
