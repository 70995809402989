export const BOOT = [
  {
    size: "8"
  },
  {
    size: "9"
  },
  {
    size: "9.5"
  }
]

export const PARKA = [
  {
    size: "XL"
  },
  {
    size: "L"
  },
  {
    size: "S"
  }
]