import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import { mainTheme } from "../../../theme";
import {
  StyledButtonsWrapper,
  StyledButtonSave,
  StyledFormCheckBonusesItem,
  StyledFormCheckBonusesWrapper,
} from "./FormCheckBonuses.styles";


interface IFormCheckBonusesInput {
  number?: string;
}

export const FormCheckBonuses = () => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    number: yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<IFormCheckBonusesInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      number: "",
    },
  });

  const onSubmit = async (data: IFormCheckBonusesInput) => {
    const formValue = {
      ...data,
    };
    console.log("formValue: ", formValue);
  };

  return (
    <StyledFormCheckBonusesWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledFormCheckBonusesItem>
          <Controller
            control={control}
            name="number"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                placeholder={t("bookingNumber")}
                label={t("bookingNumber")}
                variant="standard"
                onChange={onChange}
                value={value}
                InputProps={{
                  className: "textFieldForm",
                  style: {
                    color: mainTheme.main.colors.black,
                    fontSize: mainTheme.main.fontSizes.large,
                    fontWeight: mainTheme.main.fontWeights.normal,
                    fontFamily: mainTheme.main.fonts.dmsRegular
                  },
                }}
              />
            )}
          />
        </StyledFormCheckBonusesItem>

        <StyledButtonsWrapper>
          <StyledButtonSave
            type="submit"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            {t("check")}
          </StyledButtonSave>
        </StyledButtonsWrapper>
      </form>
    </StyledFormCheckBonusesWrapper>
  );
};
