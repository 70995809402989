import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ADDITIONAL_SERVICES } from "./consts";
import { IconDelete } from "../../icons/IconDelete";
import { ModalInfo } from "../ModalInfo";
import { ServiceDetails } from "../ServiceDetails/ServiceDetails";
import {
  StyledAdditionalServiceItem,
  StyledAdditionalServiceItemColumn,
  StyledAdditionalServiceItemColumns,
  StyledAdditionalServiceList,
  StyledAdditionalServicesSectionTitle,
  StyledAdditionalServicesWrapper,
  StyledAdditionalServiceTypeName,
  StyledButtonCancel,
  StyledButtonDelete,
  StyledButtonSave,
  StyledButtonsWrapper,
  StyledModalContent,
  StyledModalContentTwoColumns,
} from "./AdditionalServices.styles";

export const AdditionalServices = () => {
  const { t } = useTranslation();

  const [selectedService, setSelectedService] = useState();

  const [openModalDetails, setOpenModalDetails] = useState(false);
  const [openModalCancelService, setOpenModalCancelService] = useState(false);
  const [openModalSuccessfully, setOpenModalSuccessfully] = useState(false);

  const handleDelete = (item: any) => {
    setSelectedService(item);
    setOpenModalCancelService(true);
  };

  const handleYes = () => {
    setOpenModalCancelService(false);
    setOpenModalSuccessfully(true);
  };

  console.log("selectedService: ", selectedService);

  return (
    <StyledAdditionalServicesWrapper>
      <StyledAdditionalServicesSectionTitle>
        {t("additionalServices")}
      </StyledAdditionalServicesSectionTitle>

      {ADDITIONAL_SERVICES?.map((item: any, index) => (
        <StyledAdditionalServiceItem key={item?.title}>
          <StyledAdditionalServiceTypeName>
            {t(item.title)}
          </StyledAdditionalServiceTypeName>

          <StyledAdditionalServiceList>
            {item?.services.map((service: any) => (
              <StyledAdditionalServiceItemColumns>
                <StyledAdditionalServiceItemColumn
                  className="name"
                  onClick={() => setOpenModalDetails(true)}
                >
                  {service?.name}
                </StyledAdditionalServiceItemColumn>
                <StyledAdditionalServiceItemColumn>
                  {service?.traveller}
                </StyledAdditionalServiceItemColumn>
                <StyledAdditionalServiceItemColumn>
                  {service?.price}
                </StyledAdditionalServiceItemColumn>
                <StyledAdditionalServiceItemColumn className="last">
                  <StyledButtonDelete onClick={() => handleDelete(service)}>
                    <IconDelete />
                  </StyledButtonDelete>
                </StyledAdditionalServiceItemColumn>
              </StyledAdditionalServiceItemColumns>
            ))}
          </StyledAdditionalServiceList>
        </StyledAdditionalServiceItem>
      ))}

      {/* Модальное окно "Details" */}
      <ModalInfo
        title="Details"
        open={openModalDetails}
        setOpen={(value: boolean) => setOpenModalDetails(value)}
      >
        <ServiceDetails />
      </ModalInfo>

      {/* Модальное окно "Cancel Service" */}
      <ModalInfo
        title="cancelService"
        open={openModalCancelService}
        setOpen={(value: boolean) => setOpenModalCancelService(value)}
      >
        <StyledModalContent>
          {t("questionCancel")}
        </StyledModalContent>

        <StyledButtonsWrapper>
          <StyledButtonCancel 
            onClick={() => setOpenModalCancelService(false)}
          >
            {t("cancel")}
          </StyledButtonCancel>
          <StyledButtonSave 
            onClick={handleYes}
          >
            {t("yes")}
          </StyledButtonSave>
        </StyledButtonsWrapper>
      </ModalInfo>

      {/* Модальное окно "Your request has been successfully..." */}
      <ModalInfo
        title=""
        open={openModalSuccessfully}
        setOpen={(value: boolean) => setOpenModalSuccessfully(value)}
      >
        <StyledModalContentTwoColumns>
          <StyledModalContent>
            {t("yourRequestBeenSuccessfully")}
          </StyledModalContent>

          <StyledButtonSave 
            onClick={() => setOpenModalSuccessfully(false)}
          >
            {t("close")}  
          </StyledButtonSave>
        </StyledModalContentTwoColumns>
      </ModalInfo>
    </StyledAdditionalServicesWrapper>
  );
};
