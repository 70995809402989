const IconEdit = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={12}
    fill="none"
  >
    <path
      stroke="#004155"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M8.333 1.433a1.336 1.336 0 1 1 1.89 1.889L3.846 9.697l-2.597.708.708-2.597 6.375-6.375Z"
    />
  </svg>
)
export default IconEdit
