export const CUSTOMER_DATA = {
  fullName: {
    value: "Mr. William James Smith"
  },
  email: {
    value: "j.smith@yahoo.com"
  },
  phone: {
     value: "+44 077 1417 0414"
  },
  loyaltyNumber: {
    value: "124456789 (Tier 2)"
  }
}
