import styled from 'styled-components'
import { mainTheme } from "../../theme"

export const StyledWrapperBookingDetails = styled.div`
  display: grid;
  padding: 12px 20px;
  grid-template-columns: minmax(60%, auto) minmax(300px, 300px);
  gap: 12px;
  grid-auto-flow: row;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
   display: flex;
   flex-direction: column;
   box-sizing: border-box;
  }
`

export const StyledWrapperBookingDetailsColumn = styled.div`
  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
   display: none;
  }
`

export const StyledWrapperBookingDetailsColumnMobile = styled.div`
  display: none;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
   display: flex;
   flex-direction: column;
   flex-wrap: wrap;
   box-sizing: border-box;
  }
`