const IconUinion = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={310}
    height={211}
    fill="none"
  >
    <path
      fill="#668D99"
      fillRule="evenodd"
      d="M246.67 210.398h44.887c19.351 0 22.27-14.257 12.959-30.012L232.947 56.504c-6.67-11.642-.173-22.24 7.817-26.91a20.196 20.196 0 0 1 14.779-1.958 20.26 20.26 0 0 1 12.112 8.72l26.299 45.977s2.467 4.287 5.906 3.242c3.44-1.046 1.668-6.972 1.668-6.972l-6.219-17.881s7.053-19.45-.729-33.812C278.077-3.485 247.469-3.939 228.57 5.194c-18.17 8.75-34.951 38.099-20.29 63.51l66.948 115.691h-22.269c-9.381 0-12.473-2.266-17.024-10.109L156.411 37.019C136.052 1.709 106 4.602 106 4.602l108.673 187.461a37.58 37.58 0 0 0 13.601 13.38 37.43 37.43 0 0 0 18.396 4.955Zm-54.233.268h-75.286a37.32 37.32 0 0 1-18.483-4.907 37.473 37.473 0 0 1-13.653-13.428l-49.542-85.505s30.052-2.893 50.41 32.383l20.533 35.345c4.551 7.843 7.643 10.108 17.024 10.108h22.269L71.083 56.04s30.052-2.893 50.411 32.382l70.943 122.244Zm-129.901.008h-31.79L0 157.621s30.538-1.952 50.376 32.417l12.16 20.636Z"
      clipRule="evenodd"
      opacity={0.4}
    />
  </svg>
)
export default IconUinion
