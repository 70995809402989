
const IconCheckRadio = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
  >
    <rect
      width={16}
      height={16}
      x={4}
      y={4}
      fill="#004155"
      stroke="#004155"
      strokeWidth={2}
      rx={8}
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeWidth={2}
      d="m8 11 3 3 5-5"
    />
  </svg>
)
export default IconCheckRadio
