import { useTranslation } from "react-i18next";
import {
  StyledCustomerColumn,
  StyledCustomerColumnContent,
  StyledCustomerColumns,
  StyledCustomerHeaderColumn,
  StyledCustomerWrapper,
  StyledIconWrapper,
  StyledStateroomsSectionTitle,
} from "./Customer.styles";
import { CUSTOMER_DATA, } from "./consts";
import { IconLoylaty } from "../../icons/IconLoylaty";

export const Customer = () => {
  const { t } = useTranslation();

  return (
    <StyledCustomerWrapper>
    <StyledStateroomsSectionTitle>
      {t("customer")} 
    </StyledStateroomsSectionTitle>

    <StyledCustomerColumns>
      <StyledCustomerColumn>
        <StyledCustomerHeaderColumn>
          {t("fullName")}
        </StyledCustomerHeaderColumn>
        <StyledCustomerColumnContent>
          {CUSTOMER_DATA.fullName.value}
        </StyledCustomerColumnContent>
      </StyledCustomerColumn>

      <StyledCustomerColumn>
        <StyledCustomerHeaderColumn>
          {t("email")}
        </StyledCustomerHeaderColumn>
        <StyledCustomerColumnContent>
          {CUSTOMER_DATA.email.value}
        </StyledCustomerColumnContent>
      </StyledCustomerColumn>

      <StyledCustomerColumn>
        <StyledCustomerHeaderColumn>
          {t("phone")}
        </StyledCustomerHeaderColumn>
        <StyledCustomerColumnContent>
          {CUSTOMER_DATA.phone.value}
        </StyledCustomerColumnContent>
      </StyledCustomerColumn>

      <StyledCustomerColumn>
        <StyledCustomerHeaderColumn>
          {t("loyaltyNumber")}
        </StyledCustomerHeaderColumn>
        <StyledCustomerColumnContent className="row">
          <StyledIconWrapper>
            <IconLoylaty />
          </StyledIconWrapper>
          {CUSTOMER_DATA.loyaltyNumber.value}
        </StyledCustomerColumnContent>
      </StyledCustomerColumn>

    </StyledCustomerColumns>
  </StyledCustomerWrapper>
  );
};
