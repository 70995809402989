import { useTranslation } from "react-i18next";
import {
  StyledCruiseColumn,
  StyledCruiseColumnContent,
  StyledCruiseColumnWrapper,
  StyledCruiseHeaderColumn,
  StyledCruiseRow,
  StyledCruiseWrapper,
  StyledDash,
  StyledNameShip,
  StyledRouteDate,
  StyledRouteLink,
  StyledRouteName,
  StyledRoutePort,
  StyledRoutesColumn,
  StyledRoutesRow,
  StyledSectionTitle,
} from "./CruiseMobile.styles";
import { CRUISE_DATA } from "./consts";
import { IconLoylaty } from "../../icons/IconLoylaty";

export const CruiseMobile = () => {
  const { t } = useTranslation();

  return (
    <StyledCruiseWrapper>
      <StyledSectionTitle>
        {t("cruise")} 
      </StyledSectionTitle>
      <>
        <StyledCruiseRow>
          <StyledCruiseColumn>
            <StyledCruiseHeaderColumn>
              {t("cruiseCode")}
            </StyledCruiseHeaderColumn>
            <StyledCruiseColumnContent>
              {CRUISE_DATA.cruiseCode.value}
            </StyledCruiseColumnContent>
          </StyledCruiseColumn>

          <StyledCruiseColumn className="last">
            <StyledCruiseHeaderColumn>
              {t("ship")}
            </StyledCruiseHeaderColumn>
            <StyledCruiseColumnContent className="ship">
              <IconLoylaty />
              <StyledNameShip>
                {CRUISE_DATA.ship.value}
              </StyledNameShip>
            </StyledCruiseColumnContent>
          </StyledCruiseColumn>
        </StyledCruiseRow>

        <StyledCruiseRow>
          <StyledCruiseColumn>
            <StyledCruiseHeaderColumn>
              {t("cruiseName")}
            </StyledCruiseHeaderColumn>
            <StyledCruiseColumnContent>
              {CRUISE_DATA.cruiseName.value}
            </StyledCruiseColumnContent>
          </StyledCruiseColumn>

          <StyledCruiseColumn className="last">
            <StyledCruiseHeaderColumn>
              {t("boardingTime")}
            </StyledCruiseHeaderColumn>

            <StyledCruiseColumnContent className="last">
              {CRUISE_DATA.boardingTime.value}
            </StyledCruiseColumnContent>
          </StyledCruiseColumn>
        </StyledCruiseRow>

        <>
          <StyledRoutesRow>
            <StyledRoutesColumn>
              <StyledRouteDate>{CRUISE_DATA.route.date[0].day}</StyledRouteDate>
            </StyledRoutesColumn>

            <StyledRoutesColumn>
              <StyledRouteDate>{CRUISE_DATA.route.date[1].day}</StyledRouteDate>
            </StyledRoutesColumn>
          </StyledRoutesRow>

          <StyledDash />

          <StyledRoutesRow>
            <StyledRouteName>{CRUISE_DATA.route.startPort}</StyledRouteName>
            <StyledRouteName>{CRUISE_DATA.route.endPort}</StyledRouteName>
          </StyledRoutesRow>

          <StyledRoutesRow>
            <StyledRouteDate>{CRUISE_DATA.route.date[0].day}</StyledRouteDate>
            <StyledRouteDate>{CRUISE_DATA.route.date[1].day}</StyledRouteDate>
          </StyledRoutesRow>
        </>
      </>
    </StyledCruiseWrapper>
  );
};
