import { useTranslation } from "react-i18next";
import { TypeFormEnum } from "../ModalFormEdit/types";
import { PersonalInformationProps } from "./types";
import {
  StyledBookingInfoItem,
  StyledBookingInfoItemTitle,
  StyledBookingInfoPersonal,
  StyledBookingInfoPersonalInfoContent,
  StyledBookingInfoPersonalInfoContentItem,
  StyledBookingInfoPersonalInfoDock,
  StyledBookingInfoPersonalInfoList,
  StyledBookingInfoPersonalInfoListItem,
  StyledBookingInfoPersonalInfoListItemColumn,
  StyledBookingInfoPersonalInfoListItemColumnRow,
  StyledBookingInfoPersonalInfoListItemTitle,
  StyledBookingInfoPersonalInfoSection,
  StyledBookingInfoPersonalInfoWrapper,
  StyledChange,
} from "./PersonalInformation.styles";

export const PersonalInformation = ({ editForm, currentUser }: PersonalInformationProps) => {
  const { t } = useTranslation();

  const handleChange = () => {
    if (editForm) {
      editForm({
        title: "personalInformation",
        typeEditForm: TypeFormEnum.personalInformation,
      });
    }
  };

  console.log("currentUser: ", currentUser);

  return (
    <StyledBookingInfoPersonalInfoSection>
      <StyledBookingInfoPersonalInfoWrapper>
        {/* Персональная данные */}
        <StyledBookingInfoItem>
          <StyledBookingInfoItemTitle>
            {t("personalInformation")}
          </StyledBookingInfoItemTitle>

          <StyledBookingInfoPersonalInfoContent>
            <StyledBookingInfoPersonalInfoContentItem>
              <StyledBookingInfoPersonalInfoList>
                <StyledBookingInfoPersonalInfoListItemColumn>
                  <StyledBookingInfoPersonalInfoListItem>
                    <StyledBookingInfoPersonalInfoListItemTitle>
                      {t("fullName")}
                    </StyledBookingInfoPersonalInfoListItemTitle>
                    <StyledBookingInfoPersonal>
                      Mr. William James Smith
                    </StyledBookingInfoPersonal>
                  </StyledBookingInfoPersonalInfoListItem>

                  <StyledBookingInfoPersonalInfoListItem>
                    <StyledBookingInfoPersonalInfoListItemTitle>
                      {t("phone")}
                    </StyledBookingInfoPersonalInfoListItemTitle>
                    <StyledBookingInfoPersonal>
                      +44 077 1417 0414
                    </StyledBookingInfoPersonal>
                  </StyledBookingInfoPersonalInfoListItem>
                </StyledBookingInfoPersonalInfoListItemColumn>

                <StyledBookingInfoPersonalInfoListItemColumn className="lastRow">
                  <StyledBookingInfoPersonalInfoListItem className="downContent">
                    <StyledBookingInfoPersonalInfoListItemTitle>
                      {t("email")}
                    </StyledBookingInfoPersonalInfoListItemTitle>
                    <StyledBookingInfoPersonal>
                      j.smith@yahoo.com
                    </StyledBookingInfoPersonal>
                  </StyledBookingInfoPersonalInfoListItem>

                </StyledBookingInfoPersonalInfoListItemColumn>

                <StyledBookingInfoPersonalInfoListItemColumn>

                  <StyledBookingInfoPersonalInfoListItemColumnRow>
                    <StyledBookingInfoPersonalInfoListItem>
                      <StyledBookingInfoPersonalInfoListItemTitle>
                        {t("birthDate")}
                      </StyledBookingInfoPersonalInfoListItemTitle>
                      <StyledBookingInfoPersonal>
                        03.01.1999
                      </StyledBookingInfoPersonal>
                    </StyledBookingInfoPersonalInfoListItem>

                    <StyledBookingInfoPersonalInfoListItemColumn>
                      <StyledBookingInfoPersonalInfoListItem>
                        <StyledBookingInfoPersonalInfoListItemTitle>
                          {t("nationality")}
                        </StyledBookingInfoPersonalInfoListItemTitle>
                        <StyledBookingInfoPersonal>UK</StyledBookingInfoPersonal>
                      </StyledBookingInfoPersonalInfoListItem>
                    </StyledBookingInfoPersonalInfoListItemColumn>
                  </StyledBookingInfoPersonalInfoListItemColumnRow>

                  <StyledBookingInfoPersonalInfoListItem className="desktop">
                    <StyledBookingInfoPersonalInfoListItemTitle>
                      {t("addressLine1")}
                    </StyledBookingInfoPersonalInfoListItemTitle>
                    <StyledBookingInfoPersonal>
                      2d, Caradon Hill, Udimore, TN31 5QG, England, UK
                    </StyledBookingInfoPersonal>
                  </StyledBookingInfoPersonalInfoListItem>
                </StyledBookingInfoPersonalInfoListItemColumn>
              </StyledBookingInfoPersonalInfoList>
            </StyledBookingInfoPersonalInfoContentItem>

            <StyledBookingInfoPersonalInfoListItem className="mobile">
              <StyledBookingInfoPersonalInfoListItemTitle>
                {t("addressLine1")}
              </StyledBookingInfoPersonalInfoListItemTitle>
              <StyledBookingInfoPersonal>
                2d, Caradon Hill, Udimore, TN31 5QG, England, UK
              </StyledBookingInfoPersonal>
            </StyledBookingInfoPersonalInfoListItem>
          </StyledBookingInfoPersonalInfoContent>

          <StyledChange onClick={handleChange}>{t("edit")}</StyledChange>
        </StyledBookingInfoItem>
      </StyledBookingInfoPersonalInfoWrapper>
    </StyledBookingInfoPersonalInfoSection>
  );
};
