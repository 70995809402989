import { useEffect, useState } from "react";
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Box,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { t } from "i18next";
import { TravellersTableProps } from "./types";
import { CommonTable } from "../CustomTable";
import IconEdit from "../../icons/IconEdit";
import { TypeFormEnum } from "../ModalFormEdit/types";
import { HEAD_CEALS } from "./consts";
import {
  StyledIconEditWrapper,
  StyledTableCellEmail,
  StyledTableCellText,
  StyledTableWrapper,
} from "./TravellersTable.styles";

type TableHeaderColumnProps = {
  onRequestSort: (event: any, property: any) => void;
  order: any;
  orderBy: string;
};

type HeadCellProps = {
  id: any;
  numeric: boolean;
  label: string;
};

const TableHeaderColumn = ({
  onRequestSort,
  order,
  orderBy,
}: TableHeaderColumnProps) => {
  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {HEAD_CEALS.map((headCell: HeadCellProps, index) => {
          return (
            <TableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {t(headCell.id)}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

const TableCellFullname = ({ traveller }: any) => {
  return (
    <TableCell>
      <StyledTableCellText>
        {traveller?.fullName ?? "-"}
      </StyledTableCellText>
    </TableCell>
  );
};


const TableCellPassport = ({ traveller }: any) => {
  return (
    <TableCell>
      <StyledTableCellText>
        {traveller?.passport}
      </StyledTableCellText>
    </TableCell>
  );
};

const TableCellLoyaltyNumber = ({ traveller }: any) => {
  return (
    <TableCell>
      <StyledTableCellText>
        {traveller?.loyaltyNumber}
      </StyledTableCellText>
    </TableCell>
  );
};

const TableCellPhone = ({ traveller }: any) => {
  return (
    <TableCell>
      <StyledTableCellText>
        {traveller?.phone}
      </StyledTableCellText>
    </TableCell>
  );
};

const TableCellEmail = ({ traveller, setCurrentEditForm }: any) => {

  const handleEdit = () => {
    setCurrentEditForm({
      title: "traveller",
      typeEditForm: TypeFormEnum.traveller,
    })
  }

  return (
    <TableCell>
      <StyledTableCellEmail>
        <StyledTableCellText>
          {traveller?.email}
        </StyledTableCellText>
        <StyledIconEditWrapper
          onClick={handleEdit}
        >
          <IconEdit />
        </StyledIconEditWrapper>
      </StyledTableCellEmail>
    </TableCell>
  );
};

const TableTravellersRows = ({ traveller, onClickRow, setCurrentEditForm }: any) => {
  const handleClick = () => {
    onClickRow(traveller);
  };

  return (
    <TableRow onClick={handleClick}>
      <TableCellFullname traveller={traveller} />
      <TableCellPassport traveller={traveller} />
      <TableCellLoyaltyNumber traveller={traveller} />
      <TableCellPhone traveller={traveller} />
      <TableCellEmail 
        traveller={traveller}
        setCurrentEditForm={(value: any) => setCurrentEditForm(value)}
      />
    </TableRow>
  );
};

export const TravellersTable = ({ travellers, setCurrentEditForm, setCurrentTraveller }: TravellersTableProps) => {
  const [currentSelectedRowInTable, setCurrentSelectedRowInTable] =
    useState(null);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    if (setCurrentTraveller) {
      setCurrentTraveller(currentSelectedRowInTable)
    }
  }, [currentSelectedRowInTable]);

  console.log("order: ", order);
  console.log("orderBy: ", orderBy);
  console.log("currentSelectedRowInTable: ", currentSelectedRowInTable);

  return (
    <StyledTableWrapper>
      <CommonTable
        headerList={
          <TableHeaderColumn
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
        }
      >
        {travellers?.map((item: any) => (
          <TableTravellersRows
            key={item?.code?.number}
            traveller={item}
            onClickRow={(value: any) => setCurrentSelectedRowInTable(value)}
            setCurrentEditForm={(value: any) => setCurrentEditForm(value)}
          />
        ))}
      </CommonTable>
    </StyledTableWrapper>
  );
};
