export const IconVerifySuccess = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={12}
    fill="none"
  >
    <rect
      width={9.6}
      height={9.6}
      x={1.2}
      y={1.2}
      fill="#088905"
      stroke="#088905"
      strokeWidth={1.2}
      rx={4.8}
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeWidth={1.2}
      d="m3.6 5.4 1.8 1.8 3-3"
    />
  </svg>
)

