import { TFunction } from "i18next"
import { CurrentEditFormType } from "../../utils/globalTypes"

export enum TypeFormEnum {
  personalInformation = "personalInformation",
  contactDetails = "contactDetails",
  passportDetails = "passportDetails",
  detailsTravel = "detailsTravel",
  additionalRequirements = "additionalRequirements",  
  emailSubscriptions = "emailSubscriptions",
  emergencyContact = "emergencyContact",
  loyalty = "loyalty",
  healt = "healt",
  healtInfo = "healtInfo",
  traveller = "traveller",
  newBoking = "newBoking",
  registration = "registration",
  forgotPassword = "forgotPassword",
  bedType = "bedType",
  healthDeclaration = "healthDeclaration",
  travelArrangement = "travelArrangement",
  comment = "comment",
}

export type ModalFormEditProps = {
  title: TFunction | any
  typeForm?: TypeFormEnum      
  currentEditForm: CurrentEditFormType
  setCurrentEditForm: (value: CurrentEditFormType) => void
  children?: any
}    