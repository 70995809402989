export const PAYMENT_SCHEDULE = [
  {
    price: "$ 13,773",
    date: "until 21.03",
    status: "Unpaid"
  },
  {
    price: "$ 13,773",
    date: "until 21.03",
    status: "Unpaid"
  }
]