import styled from "styled-components";
import { mainTheme } from "../../../../theme";

export const StyledRadioGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;

  label {
    color: ${mainTheme.main.colors.grayOpacity};
    font-size: ${mainTheme.main.fontSizes.small};
    font-weight: ${mainTheme.main.fontWeights.normal};
    background: ${mainTheme.main.colors.white};
    font-family: ${mainTheme.main.fonts.dmsRegular};
  }

  .MuiInputLabel-root {
    font-size: ${mainTheme.main.fontSizes.large};
    font-weight: ${mainTheme.main.fontWeights.normal};
    color: ${mainTheme.main.colors.black} !important;
    font-family: ${mainTheme.main.fonts.dmsRegular};
    opacity: 0.5;
  }

  .MuiFormLabel-colorPrimary {
    font-size: ${mainTheme.main.fontSizes.small};
    font-weight: ${mainTheme.main.fontWeights.normal};
    color: ${mainTheme.main.colors.black} !important;
    font-family: ${mainTheme.main.fonts.dmsRegular};
    opacity: 0.5;
  }

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .MuiTypography-root {
    color: ${mainTheme.main.colors.black};
    font-size: ${mainTheme.main.fontSizes.medium};
    font-weight: ${mainTheme.main.fontWeights.normal};
    font-family: ${mainTheme.main.fonts.dmsRegular};
  }

  .MuiFormControlLabel-label {
    color: ${mainTheme.main.colors.black};
    font-size: ${mainTheme.main.fontSizes.medium};
    font-weight: ${mainTheme.main.fontWeights.normal};
    font-family: ${mainTheme.main.fonts.dmsRegular};
  }
`