export const BED_TYPES = [
  {
    label: "Double bed",
    value: 1
  },
  {
    label: "Twin bed",
    value: 2
  },
  {
    label: "Single sofa bed or extra bed",
    value: 3
  },
  {
    label: "Double sofa bed or extra bed",
    value: 4
  }
];

export const MESSAGE = `How would you like stateroom/suite arranged, including hotel if booked with us? Please note, that "SINGLE SOFA BED or EXTRA BED" and "DOUBLE SOFA BED or EXTRA BED" are available only if there are more than 2 guests in the same booking`;