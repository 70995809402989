import { useTranslation } from "react-i18next";
import {
  StyledColumn,
  StyledColumnContent,
  StyledHeaderColumn,
  StyledMainWrapper,
  StyledWrapperOneColumn,
  StyledWrapperTwoColumns,
} from "./ServiceDetails.styles";
import { DETAILS } from "./consts";

export const ServiceDetails = () => {
  const { t } = useTranslation();

  return (
    <StyledMainWrapper>
      {/* Name / Date Time */}
      <StyledWrapperTwoColumns>
        <StyledColumn>
          <StyledHeaderColumn>{t("name")}</StyledHeaderColumn>

          <StyledColumnContent>{DETAILS?.name}</StyledColumnContent>
        </StyledColumn>

        <StyledColumn>
          <StyledHeaderColumn>{t("dateBooking")}</StyledHeaderColumn>

          <StyledColumnContent>{DETAILS?.dateTime}</StyledColumnContent>
        </StyledColumn>
      </StyledWrapperTwoColumns>

      {/* Route / Address */}
      <StyledWrapperOneColumn>
        <StyledColumn>
          <StyledHeaderColumn>{t("routeAddress")}</StyledHeaderColumn>

          <StyledColumnContent>{DETAILS?.routeAddress}</StyledColumnContent>
        </StyledColumn>
      </StyledWrapperOneColumn>

      {/* Class fare / Number */}
      <StyledWrapperTwoColumns>
        <StyledColumn>
          <StyledHeaderColumn>{t("classFare")}</StyledHeaderColumn>

          <StyledColumnContent>{DETAILS?.classFare}</StyledColumnContent>
        </StyledColumn>

        <StyledColumn>
          <StyledHeaderColumn>{t("number")}</StyledHeaderColumn>

          <StyledColumnContent>{DETAILS?.number}</StyledColumnContent>
        </StyledColumn>
      </StyledWrapperTwoColumns>

      {/* Email / Phone */}
      <StyledWrapperTwoColumns className="dash">
        <StyledColumn>
          <StyledHeaderColumn>{t("phone")}</StyledHeaderColumn>

          <StyledColumnContent>{DETAILS?.phone}</StyledColumnContent>
        </StyledColumn>

        <StyledColumn>
          <StyledHeaderColumn>{t("email")}</StyledHeaderColumn>

          <StyledColumnContent>{DETAILS?.email}</StyledColumnContent>
        </StyledColumn>
      </StyledWrapperTwoColumns>

      {/* Comments */}
      <StyledWrapperOneColumn>
        <StyledColumn>
          <StyledHeaderColumn>{t("comments")}</StyledHeaderColumn>

          <StyledColumnContent>{DETAILS?.comments}</StyledColumnContent>
        </StyledColumn>
      </StyledWrapperOneColumn>
    </StyledMainWrapper>
  );
};
