export const IconStatusUser = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={11}
    height={12}
    fill="none"
  >
    <path
      fill="#000"
      d="M6.928 8.122c-.03-.385-.039-.772-.025-1.158v.017c.368-.357.598-.82.652-1.314l.001-.01c.166-.013.428-.164.505-.763a.524.524 0 0 0-.025-.31.562.562 0 0 0-.198-.249h-.002c.27-.761.832-3.115-1.039-3.358C6.605.66 6.112.5 5.472.5 2.908.544 2.599 2.31 3.16 4.334a.564.564 0 0 0-.2.252.526.526 0 0 0-.023.312v-.004c.078.599.338.75.504.764.058.5.295.966.673 1.323.012.393.003.786-.029 1.178l.003-.037C3.579 9.398.157 9.039 0 11.5h11c-.158-2.46-3.565-2.102-4.072-3.378Z"
    />
  </svg>
)

