import { Button } from '@mui/material'
import styled from 'styled-components'
import { mainTheme } from "../../theme"

export const StyledStateroomsWrapper = styled.div`
  background: ${mainTheme.main.colors.white};
  padding: 16px;
  box-shadow: 0px 3px 14px -6px rgba(0,0,0,0.40);
  margin-top: 12px;
`

export const StyledStateroomsSectionTitle = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-weight: ${mainTheme.main.fontWeights.bold};
  font-family: ${mainTheme.main.fonts.chapRegular};
  font-size: 22px;
  margin-bottom: 14px;
  text-transform: uppercase;

  &.guest {
    margin-top: 5px;
    margin-bottom: 0px;
    font-size: ${mainTheme.main.fontSizes.large};
    text-transform: none;
  }
`

export const StyledStateroomsCabinsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 14px;
  margin-bottom: 20px;
`

// Кнопки выбора каюты по номеру
export const StyledStateroomsCabinButton = styled(Button)`
  &.MuiButtonBase-root {
    background: ${mainTheme.main.colors.white};
    border-radius: 0px;
    color: ${mainTheme.main.colors.grayOpacity};
    font-weight: ${mainTheme.main.fontWeights.bold};
    font-family: ${mainTheme.main.fonts.dmsRegular};
    font-size: 16px;
    min-width: auto;
    padding: 0px;
  }

  &.active {
    &.MuiButtonBase-root {
      color: ${mainTheme.main.colors.yellow};
      font-weight: ${mainTheme.main.fontWeights.bold};
      border-bottom: 3px solid ${mainTheme.main.colors.yellow};
    }
  }
`

export const StyledStateroomsColumnsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const StyledStateroomsColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-bottom: 8px;

  &.last {
    align-items: flex-end;
  }

`

export const StyledStateroomsColumnTitle = styled.div`
  color: ${mainTheme.main.colors.merin};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  font-size: ${mainTheme.main.fontSizes.small};
`

export const StyledStateroomsColumnContent = styled.div`
  color: ${mainTheme.main.colors.black};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  font-size: ${mainTheme.main.fontSizes.medium};
`

export const StyledStateroomsColumnEditWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const StyledStateroomsColumnEditButton = styled(Button)`
  &.MuiButtonBase-root {
    background: ${mainTheme.main.colors.white};
    border-radius: 0px;
    min-width: auto;
    margin: 0;
    padding: 0;
    margin-left: 10px;
  }
`

export const StyledSelectedGuestWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 14px;
  margin-bottom: 20px;
`

// Кнопки выбора пассажира по номеру
export const StyledGuestNumberButton = styled(Button)`
  &.MuiButtonBase-root {
    background: ${mainTheme.main.colors.white};
    border-radius: 0px;
    color: ${mainTheme.main.colors.grayOpacity};
    font-weight: ${mainTheme.main.fontWeights.bold};
    font-family: ${mainTheme.main.fonts.dmsRegular};
    font-size: 16px;
    min-width: auto;
    padding: 0px;
  }

  &.active {
    &.MuiButtonBase-root {
      color: ${mainTheme.main.colors.yellow};
      font-weight: ${mainTheme.main.fontWeights.bold};
      border-bottom: 3px solid ${mainTheme.main.colors.yellow};
    }
  }
`

export const StyledGuestDataWrapper = styled.div``

export const StyledGuestDataItemWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 10px;

  &.borderRow {
    padding-bottom: 4px;
    border-bottom: 1px solid ${mainTheme.main.colors.graySilver};
  }
`

export const StyledGuestDataItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;

  &.last {
    align-items: flex-end;
  }
`

export const StyledGuestDataItemTitle = styled.div`
  color: ${mainTheme.main.colors.merin};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  font-size: ${mainTheme.main.fontSizes.small};
`

export const StyledGuestDataItemContent = styled.div`
  color: ${mainTheme.main.colors.black};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  font-size: ${mainTheme.main.fontSizes.medium};

  &.boldContent {
    color: ${mainTheme.main.colors.blue};
    font-weight: ${mainTheme.main.fontWeights.boldExtra};
  }
`

export const StyledDetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`

export const StyledDetails = styled.div`
  text-decoration: underline;
  color: ${mainTheme.main.colors.blue};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  font-size: ${mainTheme.main.fontSizes.medium};
`

export const StyledButtonDownloadWrapper = styled.div`
  display: flex;
  padding-left: 40px;
`

export const StyledButtonDownload = styled(Button)`
  &.MuiButtonBase-root {
    padding: 0;
    width: auto;
    min-width: auto;
  }
` 