export const STATEROOM_TRAVELER_DATA = {
  id: "123",
  initials: "Mr",
  fio: "William James Smith",
  birth: "25.07.1975",
  country: "UK",
  document: {
    statusSuccessful: true,
    series: "30**",
    number: "**72",
  },
  loyalty: "123456789",
  emergency: {
    statusSuccessful: true,
    phone: "+44 077 1417 0414",
    email: "j.smith@yahoo.com",
  },
  additional: {
    statusSuccessful: true,
    boot: {
      bootSizeType: "EU",
      size: "8",
    },
    parka: {
      size: "XL",
    },
  },
  arrangement: {
    statusSuccessful: true,
  },
  health: {
    statusSuccessful: true,
  },
  price: {
    fare: "Door-to-door",
    total: "$ 55,650",
  },
  documents: "",
  phone: "+44 077 1417 0414",
  email: "j.smith@yahoo.com",
};

export const STATEROOM_CABINS_NUMBER = [
  {
    id: "1",
    number: "105"
  },
  {
    id: "2",
    number: "106"
  },
  {
    id: "3",
    number: "107"
  }
];

export const STATEROOM_DATA = {
  id: "12021",
  number: "105",
  deck: "6",
  category: "Premium suite",
  bedType: "Single sofa bed or extra bed",
};
