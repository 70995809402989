import { useTranslation } from "react-i18next";
import { POLICY, REMARK } from "./consts";
import {
  StyledPolicyList,
  StyledPolicyListItem,
  StyledPolicyRemarkWrapper,
  StyledPolicySection,
  StyledPolicyTitle,
  StyledRemarkSection,
  StyledRemarkText,
  StyledRemarkTitle,
} from "./PolicyRemark.styles";

export const PolicyRemark = () => {
  const { t } = useTranslation();

  return (
    <StyledPolicyRemarkWrapper>
      <StyledPolicySection>
        <StyledPolicyTitle>
          {t("cancellationPolicy")}
        </StyledPolicyTitle>

        <StyledPolicyList>
          {POLICY?.map((item) => (
            <StyledPolicyListItem>
              {item.rule}
            </StyledPolicyListItem>
          ))}
        </StyledPolicyList>
      </StyledPolicySection>

      <StyledRemarkSection>
        <StyledRemarkTitle>
          {t("swanHellenicBookingRemark")}
        </StyledRemarkTitle>
        <StyledRemarkText>
          {REMARK}
        </StyledRemarkText>
      </StyledRemarkSection>
    </StyledPolicyRemarkWrapper>
  );
};
