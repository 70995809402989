/* eslint-disable */
import React, { useMemo } from 'react'
import { redirect } from 'react-router-dom';
import { ApolloClient, ApolloProvider, from, HttpLink, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'

const ApolloGraphqlProvider = ({ children }: { children: React.ReactNode }) => {

  const getAccessToken = localStorage.getItem("accessToken");

  const client = useMemo(() => {
    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (
        graphQLErrors?.some(({ message }) => {
          try {
            const { code } = JSON.parse(message)
            return code === 403
          } catch (e: any) {
            console.log(e.message)
          }
        })
      ) {
        redirect('auth')
        return
      }

      if (networkError) console.log(`[Network error]: ${networkError}`)
    })
    const httpLink = new HttpLink({
      uri: "https://middleware-stage.swanhellenic.com/api/v2/main",
      headers: {
        authorization: getAccessToken ? `Bearer ${getAccessToken}` : "",
      },
      credentials: 'include',
    })

    return new ApolloClient({
      cache: new InMemoryCache({
        addTypename: false,
      }),
      link: from([errorLink, httpLink]),
    })
  }, [])

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export { ApolloGraphqlProvider }
