export const IconEmail = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10}
    height={11}
    fill="none"
  >
    <path
      fill="#004155"
      d="M9 2.875C9 2.394 8.64 2 8.2 2H1.8c-.44 0-.8.394-.8.875v5.25c0 .481.36.875.8.875h6.4c.44 0 .8-.394.8-.875v-5.25Zm-.8 0L5 5.063 1.8 2.874h6.4Zm0 5.25H1.8V3.75L5 5.938 8.2 3.75v4.375Z"
    />
  </svg>
)
