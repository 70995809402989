import { Button } from "@mui/material";
import styled from "styled-components";
import { mainTheme } from "../../../theme";

export const StyledFormCheckBonusesWrapper = styled.div`
  form {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: flex-end;

    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    label {
      color: ${mainTheme.main.colors.merin};
      font-size: ${mainTheme.main.fontSizes.small};
      font-weight: ${mainTheme.main.fontWeights.normal};
    }

    .MuiInputLabel-root {
      font-size: ${mainTheme.main.fontSizes.large};
      font-weight: ${mainTheme.main.fontWeights.normal};
      color: ${mainTheme.main.colors.black} !important;
      font-family: ${mainTheme.main.fonts.dmsRegular};
      opacity: 0.5;
    }

    .MuiInputBase-input { 
      color: ${mainTheme.main.colors.black};
      font-size: ${mainTheme.main.fontSizes.large};
      font-weight: ${mainTheme.main.fontWeights.normal};
      font-family: ${mainTheme.main.fonts.dmsRegular};
    }

    .textFieldForm {
      width: 100%;
    }
  }
`;

export const StyledFormCheckBonusesItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 286px;
`;

export const StyledButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;

export const StyledButtonSave = styled(Button)`
  &.MuiButton-root {
    border-radius: 24px;
    color: ${mainTheme.main.colors.white};
    font-size: ${mainTheme.main.fontSizes.large};
    font-weight: ${mainTheme.main.fontWeights.normal};
    font-family: ${mainTheme.main.fonts.dmsRegular};
    background: ${mainTheme.main.colors.blue};
    width: 153px;
    height: 48px;
    border: 1px solid ${mainTheme.main.colors.blue};
    text-transform: capitalize;
    box-shadow: none;

    &:hover {
      background: ${mainTheme.main.colors.blue};
    }

    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
      width: 100%;
      padding: 9px 50px;
    }
  }
`;
