import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  StyledCommentsText,
  StyledCommentsWrapper,
  StyledCommetHeader,
  StyledCommetsTitle,
  StyledEditButton,
} from "./Comments.styles";
import IconEdit from "../../icons/IconEdit";

import { CurrentEditFormType } from "../../utils/globalTypes";
import { ModalFormEdit } from "../ModalFormEdit";
import { TypeFormEnum } from "../ModalFormEdit/types";

export const Comments = () => {
  const { t } = useTranslation();

  const [currentEditForm, setCurrentEditForm] = useState<CurrentEditFormType>({
    title: "",
    typeEditForm: "",
  });

  const handleEdit = () => {
    setCurrentEditForm({
      title: "Comment",
      typeEditForm: TypeFormEnum.comment,
    })
  };

  return (
    <StyledCommentsWrapper>
      <StyledCommetHeader>
        <StyledCommetsTitle>{t("comment")}</StyledCommetsTitle>

        <StyledEditButton 
          onClick={handleEdit}
        >
          <IconEdit />
        </StyledEditButton>
      </StyledCommetHeader>

      <StyledCommentsText>-</StyledCommentsText>

      {/* Модальное окно в котором формы для редактирования данных */}
      <ModalFormEdit
        title={currentEditForm.title}
        currentEditForm={currentEditForm}
        setCurrentEditForm={(value) => setCurrentEditForm(value)}
      />
    </StyledCommentsWrapper>
  );
};
