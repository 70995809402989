export const ADDITIONAL_SERVICES = [
  {
    title: "precruise",
    services: [
      {
        name: "Hotel + Transfer package",
        traveller: "Mr. William James Smith",
        price: "Included"
      },
      {
        name: "Farm cider tasting",
        traveller: "Mr. William James Smith",
        price: "$ 1.200"
      },
    ]
  },
  {
    title: "onBoard",
    services: [
      {
        name: "Kronid Gogolev Picture Gallery",
        traveller: "Mr. William James Smith",
        price: "$ 1.000"
      },
      {
        name: "Panorama tour in Reykjavik",
        traveller: "Mrs. Barbara Smith",
        price: "$ 3.500"
      },
    ]
  },
  {
    title: "excursion",
    services: [
      {
        name: "Kronid Gogolev Picture Gallery",
        traveller: "Mr. William James Smith",
        price: "$ 1.000"
      },
    ]
  },
  {
    title: "postCruise",
    services: [
      {
        name: "Kronid Gogolev Picture Gallery",
        traveller: "Mr. William James Smith",
        price: "$ 1.000"
      },
      {
        name: "Panorama tour in Reykjavik",
        traveller: "Mrs. Barbara Smith",
        price: "$ 3.500"
      },
    ]
  }
]