export const CRUISE_DATA = {
  cruiseCode: {
    label: "Cruise code",
    value: "D2523102411",
  },
  cruiseName: {
    label: "Cruise name",
    value: "Iceland in Depth",
  },

  ship: {
    label: "Ship",
    value: "Diana",
  },

  boardingTime: {
    label: "Boarding time",
    value: "September 1, 2024 04:00 pm (UTC +1)",
  },

  route: {
    startPort: "Reykjavik",
    endPort: "Reykjavik",
    date: [
      {
        day: "Sunday, 06 Aug 23",
        port: "Pier (To Embark): Midbakki",
      },
      {
        day: "Monday, 14 Aug 23",
        port: "Pier (Debark): Faxagardur Pier",
      },
    ],
  },
};
