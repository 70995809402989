export const IconDownloadDocuments = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={24}
    fill="none"
  >
    <path
      fill="#004155"
      d="M16 6.857H10.43v8.216l2.822-2.822a.857.857 0 0 1 1.212 1.212l-4.285 4.286a.857.857 0 0 1-1.212 0L4.68 13.463a.857.857 0 0 1 1.212-1.212l2.822 2.822V6.857h-5.57a3.003 3.003 0 0 0-3 3V21a3.003 3.003 0 0 0 3 3H16a3.003 3.003 0 0 0 3-3V9.857a3.003 3.003 0 0 0-3-3Zm-5.571-6a.857.857 0 1 0-1.715 0v6h1.715v-6Z"
    />
  </svg>
)

