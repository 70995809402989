export type RoutesLinksType = {
  name: string,
  path: string,
  key: string
  ignore?: boolean
}

export const ROUTES_LINKS: RoutesLinksType[] = [
  {
    name: "Profile",
    path: "/",
    key: "profile"
  },
  {
    name: "Bookings",
    path: "/bookings",
    key: "bookings"
  },
  {
    name: "Loyalty",
    path: "/loyalty",
    key: "loyalty"
  },
  {
    name: "Travellers",
    path: "/travellers",
    key: "travellers"
  },
  {
    name: "BookingDetails",
    path: "/bookingDetails/:id",
    key: "bookingDetails",
    ignore: true
  }
]