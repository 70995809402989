import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { MenuItem, TextField } from "@mui/material";
import { CustomSelect } from "../components/CustomSelect";
import { VariantCustomSelectEnum } from "../components/CustomSelect/types";
import { mainTheme } from "../../../theme";
import {
  StyledButtonCancel,
  StyledButtonSave,
  StyledButtonsWrapper,
  StyledFormFieldsWrapper,
  StyledFormFieldsWrapperTwoColumns,
} from "./FormEditEmergencyContact.styles";
import { RELATIONSHIP } from "./consts";

export type FormEditEmergencyContactProps = {
  setOpen: (value: boolean) => void;
};

type FormEditAdditionalRequirementsInputType = {
  firstName: string;
  lastName: string;
  relationship: string;
  phone: string;
  email: string;
};

export const FormEditEmergencyContact = ({
  setOpen,
}: FormEditEmergencyContactProps) => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    firstName: yup.string().required(t("fieldRequired")),
    lastName: yup.string().required(t("fieldRequired")),
    relationship: yup.string().required(t("fieldRequired")),
    phone: yup.string().required(t("fieldRequired")),
    email: yup.string().required(t("fieldRequired")),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormEditAdditionalRequirementsInputType>({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: "Barbara",
      lastName: "Smith",
      relationship: "Wife",
      phone: "+44 077 1248 2695",
      email: "b.smith@yahoo.com",
    },
  });

  const onSubmit = async (data: FormEditAdditionalRequirementsInputType) => {
    const formValue = {
      ...data,
    };
    console.log("formValue: ", formValue);
  };

  const handleCancel = () => {
    if (setOpen) {
      setOpen(false);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledFormFieldsWrapper>
          <StyledFormFieldsWrapperTwoColumns>
            <div>
              <Controller
                control={control}
                name="firstName"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <TextField
                    placeholder={t("firstName")}
                    label={t("firstName")}
                    variant="standard"
                    onChange={onChange}
                    value={value}
                    InputProps={{
                      className: "textFieldForm",
                      style: {
                        color: mainTheme.main.colors.black,
                        fontSize: mainTheme.main.fontSizes.large,
                        fontWeight: mainTheme.main.fontWeights.normal,
                        fontFamily: mainTheme.main.fonts.dmsRegular,
                      },
                    }}
                  />
                )}
              />
            </div>

            <div>
              <Controller
                control={control}
                name="lastName"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <TextField
                    placeholder={t("lastName")}
                    label={t("lastName")}
                    variant="standard"
                    onChange={onChange}
                    value={value}
                    InputProps={{
                      className: "textFieldForm",
                      style: {
                        color: mainTheme.main.colors.black,
                        fontSize: mainTheme.main.fontSizes.large,
                        fontWeight: mainTheme.main.fontWeights.normal,
                        fontFamily: mainTheme.main.fonts.dmsRegular,
                      },
                    }}
                  />
                )}
              />
            </div>
          </StyledFormFieldsWrapperTwoColumns>

          <div>
            <Controller
              control={control}
              name="relationship"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <CustomSelect
                  label={t("relationship")}
                  onChange={onChange}
                  variant={VariantCustomSelectEnum.standard}
                  defaultValue={value}
                >
                  {RELATIONSHIP?.map((item) => (
                    <MenuItem key={item.name} value={item?.name}>
                      {item.name}
                    </MenuItem>
                  ))}
                </CustomSelect>
              )}
            />
          </div>

          <StyledFormFieldsWrapperTwoColumns>
            <div>
              <Controller
                control={control}
                name="phone"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <TextField
                    placeholder={t("phone")}
                    label={t("phone")}
                    variant="standard"
                    onChange={onChange}
                    value={value}
                    InputProps={{
                      className: "textFieldForm",
                      style: {
                        color: mainTheme.main.colors.black,
                        fontSize: mainTheme.main.fontSizes.large,
                        fontWeight: mainTheme.main.fontWeights.normal,
                        fontFamily: mainTheme.main.fonts.dmsRegular,
                      },
                    }}
                  />
                )}
              />
            </div>

            <div>
              <Controller
                control={control}
                name="email"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <TextField
                    placeholder={t("email")}
                    label={t("email")}
                    variant="standard"
                    onChange={onChange}
                    value={value}
                    InputProps={{
                      className: "textFieldForm",
                      style: {
                        color: mainTheme.main.colors.black,
                        fontSize: mainTheme.main.fontSizes.large,
                        fontWeight: mainTheme.main.fontWeights.normal,
                        fontFamily: mainTheme.main.fonts.dmsRegular,
                      },
                    }}
                  />
                )}
              />
            </div>
          </StyledFormFieldsWrapperTwoColumns>
        </StyledFormFieldsWrapper>

        <StyledButtonsWrapper>
          <StyledButtonCancel variant="contained" onClick={handleCancel}>
            {t("cancel")}
          </StyledButtonCancel>

          <StyledButtonSave
            type="submit"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            {t("save")}
          </StyledButtonSave>
        </StyledButtonsWrapper>
      </form>
    </div>
  );
};
