import styled from 'styled-components'
import { Link } from "react-router-dom";
import { mainTheme } from "../../theme"

export const StyledNavigationWrapper = styled.div`
  margin-top: 34px;
  margin-left: 20px;
  margin-bottom: 12px;
  display: grid;
  grid-template-columns: minmax(80px, 76px) minmax(80px, 95px) minmax(80px, 82px) minmax(80px, 98px);



  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    display: grid;
    grid-template-columns: minmax(80px, auto) minmax(80px, auto) minmax(80px, auto) minmax(80px, auto);
    grid-auto-flow: row;
    gap: 0px;

    margin: 0;
    background: ${mainTheme.main.colors.whiteGray};
    padding-top: 12px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 12px;;
  }
`

export const StyledLink = styled(Link)`
  padding: 10px 16px;
  color: ${mainTheme.main.colors.blue};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-size: ${mainTheme.main.fontSizes.medium};
  border: 1px solid ${mainTheme.main.colors.graySilver};
  background: ${mainTheme.main.colors.white};
  text-decoration: none;
  box-sizing: border-box;

  display: flex;
  justify-content: center;

  &.active {
    background: ${mainTheme.main.colors.blue};
    color: ${mainTheme.main.colors.white};
    border: 1px solid ${mainTheme.main.colors.merin};
  }
`