import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { FormControlLabel, Radio } from "@mui/material";
import {
  StyledButtonCancel,
  StyledButtonSave,
  StyledButtonsWrapper,
  StyledFormFieldsWrapper,
  StyledMessageInfo,
  StyledRadioGroupWrapper,
} from "./FormEditBedType.styles";
import { CustomRadioGroup } from "../components/CustomRadioGroup";
import IconCheckRadio from "../../../icons/IconCheckRadio";
import { BED_TYPES, MESSAGE } from "./consts";

export type FormEditBedTypeProps = {
  setOpen: (value: boolean) => void;
};

type FormEditBedTypeInputType = {
  typeBed: string;
};

export const FormEditBedType = ({ setOpen }: FormEditBedTypeProps) => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    typeBed: yup.string().required(t("fieldRequired")),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormEditBedTypeInputType>({
    resolver: yupResolver(schema),
    defaultValues: {
      typeBed: "",
    },
  });

  const onSubmit = async (data: FormEditBedTypeInputType) => {
    const formValue = {
      ...data,
    };
    console.log("formValue: ", formValue);
  };

  const handleCancel = () => {
    if (setOpen) {
      setOpen(false);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledFormFieldsWrapper>
          <StyledMessageInfo>
            {MESSAGE}
          </StyledMessageInfo>
          <StyledRadioGroupWrapper>
            <Controller
              control={control}
              name="typeBed"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <CustomRadioGroup
                  label={""}
                  onChange={onChange}
                  defaultValue="1"
                >
                  {BED_TYPES.map((item) => (
                    <FormControlLabel
                      key={item.value}
                      value={item.value}
                      control={<Radio checkedIcon={<IconCheckRadio />} />}
                      // @ts-ignore
                      label={t(item.label)}
                    />
                  ))}
                </CustomRadioGroup>
              )}
            />
          </StyledRadioGroupWrapper>
        </StyledFormFieldsWrapper>

        <StyledButtonsWrapper>
          <StyledButtonCancel variant="contained" onClick={handleCancel}>
            {t("cancel")}
          </StyledButtonCancel>

          <StyledButtonSave
            type="submit"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            {t("save")}
          </StyledButtonSave>
        </StyledButtonsWrapper>
      </form>
    </div>
  );
};
