import { gql } from '@apollo/client'

export const AUTH = gql`
  mutation AUTH($login: String!, $password: String!) {
    login(login: $login, password: $password) {
      accessToken {
        createdAt
        token
      }
      refreshToken {
        createdAt
        token
      }
    }
  }
`

export const LOGOUT = gql`
  mutation LOGOUT {
      logout
    }
`
