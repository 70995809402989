import { TableCell } from "@mui/material";
import styled from 'styled-components'
import { mainTheme } from "../../theme"

export const StyledProfileWrapper = styled.div`
  padding: 10px 20px 34px 20px;
  background: ${mainTheme.main.colors.whiteGray};
  box-sizing: border-box;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    padding: 0;
  }
`

export const StyledProfileColumnsWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(55%, auto) minmax(410px, 410px);
  gap: 10px;
  grid-auto-flow: row;
  box-sizing: border-box;
  margin-bottom: 12px;

  @media (max-width: 1220px) {
    grid-template-columns: minmax(100%, auto)
  }
`

export const StyledColumnOne = styled.div``

export const StyledColumnTwo = styled.div`
  padding: 24px;
  box-sizing: border-box;
  background: ${mainTheme.main.colors.white};

  &.loyalty {
    position: relative;
    background: ${mainTheme.main.colors.blue};
    overflow: hidden;
    padding: 20px;
    min-height: 225px;
  }

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    padding: 0;
  }
`

export const EmailSubscriptionsWrapperDesktop = styled.div`
  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    display: none;
  }
`

export const EmailSubscriptionsWrapperMobile = styled.div`
  display: none;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    display: block;
    margin-top: 15px;
  }
`

export const StyledBackground = styled.div`
  position: absolute;
  right: 20px;
  bottom: -6px;
  box-sizing: border-box;
  z-index: 1;
`

export const StyledContentWrapper = styled.div`
  z-index: 2;
`

export const StyledWrapperSearch = styled.div`
  display: none;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    display: flex;
    width: 100%;
  }
`

export const StyledLoyaltyTitle = styled.div`
  color: ${mainTheme.main.colors.grayOpacity};
  font-size: ${mainTheme.main.fontSizes.large};
  font-weight: ${mainTheme.main.fontWeights.bold};
  font-family: ${mainTheme.main.fonts.chapRegular};
  z-index: 999;
  position: relative;
`

export const StyledLoyaltyContent = styled.div`
  position: relative;
  z-index: 999;
`

export const StyledLoyaltyStatus = styled.div`
  color: ${mainTheme.main.colors.merin};
  font-size: 55px;
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.interRegular};
  z-index: 999;
  position: relative;
  margin-top: 5px;
`

export const StyledLoyaltyNumberCard = styled.div`
  color: ${mainTheme.main.colors.white};
  font-size: 32px;
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  z-index: 999;
  position: relative;
  margin-top: 20px;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    font-size: 28px;
  }
`

export const StyledDetailsLoyalty = styled.div`
  color: ${mainTheme.main.colors.merin};
  font-size: ${mainTheme.main.fontSizes.medium};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  text-decoration: underline;
  margin-top: 15px;
`

export const StyledSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${mainTheme.main.colors.white};

`

export const StyledSectionColumn = styled.div`
  display: flex;
  justify-content: space-between;
  background: ${mainTheme.main.colors.white};
  padding: 24px 30px 0px 30px;

  &:last-child {
    padding-top: 0;
  }

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    padding: 24px 16px 0px 16px;
    display: flex;
    flex-direction: row;

    &:last-child {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
`

export const StyledTitleSection = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-size: ${mainTheme.main.fontSizes.large};
  font-weight: ${mainTheme.main.fontWeights.bold};
  font-family: ${mainTheme.main.fonts.chapRegular};
  text-transform: uppercase;
`

export const StyledViewAllButton = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-size: 15px;
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  text-decoration: underline;
`