import { Button, TableCell } from "@mui/material";
import styled from 'styled-components'
import { mainTheme } from "../../theme"

// Стили для таблицы
export const StyledTableWrapper = styled.div`
  background: ${mainTheme.main.colors.white};
  padding-left: 0px;
  padding-right: 0px;
  width: 100%;

  .MuiTable-root {
    min-width: 1100px;
  }

  .MuiTableCell-head {
    padding-left: 0;
    color: ${mainTheme.main.colors.merin};
    font-size: ${mainTheme.main.fontSizes.xsmall};
    font-weight: ${mainTheme.main.fontWeights.normal};
    font-family: ${mainTheme.main.fonts.dmsRegular};
    text-transform: capitalize;
    padding-bottom: 6px;
    padding-top: 8px;
  }

  .MuiTableRow-root {
    &:hover {
      background: ${mainTheme.main.colors.white} !important;
    }
  }

  .MuiTableCell-body {
    padding-left: 0;
    color: ${mainTheme.main.colors.black};
    font-size: 15px;
    font-weight: ${mainTheme.main.fontWeights.normal};
    font-family: ${mainTheme.main.fonts.dmsRegular};
  }
`

export const StyledTravelerNumberWrapper = styled.div`
  .MuiTableCell-root {
    border: none;
  }
`

export const StyledTravelerNumber = styled.div`
  color: ${mainTheme.main.colors.black};
  font-size: ${mainTheme.main.fontSizes.medium};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
`

export const StyledTravelerMainData = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

export const StyledTravelerMainDataFio = styled.div`
  display: flex;
  color: ${mainTheme.main.colors.black};
  font-size: ${mainTheme.main.fontSizes.medium};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  text-decoration: underline;
`

export const StyledButtonEdit = styled(Button)`
  &.MuiButtonBase-root {
    padding: 0;
    width: auto;
    min-width: auto;
  }
`

export const StyledTravelerDocumentData = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledTravelerDocumentDataItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`

export const StyledTravelerDocumentColumn = styled.div`
  display: flex;
  gap: 5px;
`

export const StyledTravelerDocumentType = styled.div`
  color: ${mainTheme.main.colors.merin};
  font-size: ${mainTheme.main.fontSizes.xsmall};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
`

export const StyledTravelerDocumentDataText = styled.div`
  color: ${mainTheme.main.colors.black};
  font-size: ${mainTheme.main.fontSizes.xsmall};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
`

export const StyledTravelerLoylatySection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
`

export const StyledTravelerContactsWrapper = styled.div`
  display: table-cell;
  vertical-align: inherit;
  padding-top: 15px;
`

export const StyledStatusSection = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  text-decoration: underline;
`

export const StyledNameSection = styled.div`
  display: flex;
  margin-top: -3px;
  color: ${mainTheme.main.colors.blue};
  font-size: ${mainTheme.main.fontSizes.xsmall};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
`

export const StyledContactPhone = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  color: ${mainTheme.main.colors.black};
  font-size: ${mainTheme.main.fontSizes.xsmall};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
`

export const StyledContactEmail = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  color: ${mainTheme.main.colors.black};
  font-size: ${mainTheme.main.fontSizes.xsmall};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
`

export const StyledTravelerAdditionalWrapper = styled.div`
  display: table-cell;
  vertical-align: inherit;
  padding-top: 15px;
`

export const StyledAdditionalColumns = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledAdditionalColumnItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`

export const StyledAdditionalColumnTitle = styled.div`
  color: ${mainTheme.main.colors.merin};
  font-size: ${mainTheme.main.fontSizes.xsmall};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
`

export const StyledAdditionalColumnText = styled.div`
  color: ${mainTheme.main.colors.black};
  font-size: ${mainTheme.main.fontSizes.xsmall};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
`

export const StyledTravelerArrangement = styled.div`
  display: table-cell;
`

export const StyledHealthDeclaration = styled.div`
  display: table-cell;
`

export const StyledPriceColumns = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledPriceColumn = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`

export const StyledPriceTitleColumn = styled.div`
  color: ${mainTheme.main.colors.merin};
  font-size: ${mainTheme.main.fontSizes.xsmall};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
`

export const StyledPriceText = styled.div`
  color: ${mainTheme.main.colors.black};
  font-size: ${mainTheme.main.fontSizes.xsmall};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
`

export const StyledButtonDownloadWrapper = styled.div`
  display: flex;
  padding-left: 40px;
`

export const StyledButtonDownload = styled(Button)`
  &.MuiButtonBase-root {
    padding: 0;
    width: auto;
    min-width: auto;
  }
` 