export const IconLoylaty = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={11}
    height={9}
    fill="none"
  >
    <path
      fill="#004155"
      d="M8.769 8.5h1.59c.687 0 .79-.542.46-1.141l-2.537-4.71c-.236-.443-.006-.846.277-1.024a.673.673 0 0 1 .524-.074c.177.048.33.166.43.331l.932 1.749s.087.163.21.123c.121-.04.058-.265.058-.265l-.22-.68s.25-.74-.026-1.286C9.882.367 8.797.35 8.127.697c-.644.333-1.239 1.45-.719 2.415l2.373 4.4h-.79c-.332 0-.441-.087-.603-.385L5.57 1.907C4.848.566 3.783.676 3.783.676l3.852 7.128c.117.211.283.386.482.508.198.123.423.187.652.189ZM6.82 8.5H4.153c-.23 0-.455-.065-.655-.187a1.378 1.378 0 0 1-.484-.51L1.257 4.55s1.065-.11 1.787 1.232l.728 1.344c.161.298.27.384.603.384h.79l-2.646-4.89s1.066-.11 1.787 1.23L6.821 8.5ZM2.217 8.5H1.09L0 6.483s1.082-.074 1.786 1.232l.43.785Z"
    />
  </svg>
)

