import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { TextField } from "@mui/material";
import {
  StyledButtonCancel,
  StyledButtonSave,
  StyledButtonsWrapper,
  StyledFormFieldsWrapper,
} from "./FormComment.styles";
import { mainTheme } from "../../../theme";

export type FormCommentProps = {
  setOpen: (value: boolean) => void;
};

type FormCommentInputType = {
  comment: string;
};

export const FormComment = ({ setOpen }: FormCommentProps) => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    comment: yup.string().required(t("fieldRequired")),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormCommentInputType>({
    resolver: yupResolver(schema),
    defaultValues: {
      comment: "",
    },
  });

  const onSubmit = async (data: FormCommentInputType) => {
    const formValue = {
      ...data,
    };
    console.log("formValue: ", formValue);
  };

  const handleCancel = () => {
    if (setOpen) {
      setOpen(false);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledFormFieldsWrapper>
          <Controller
            control={control}
            name="comment"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                placeholder={t("comment")}
                label={t("comment")}
                variant="standard"
                onChange={onChange}
                value={value}
                InputProps={{
                  className: "textFieldForm",
                  style: {
                    color: mainTheme.main.colors.black,
                    fontSize: mainTheme.main.fontSizes.large,
                    fontWeight: mainTheme.main.fontWeights.normal,
                    fontFamily: mainTheme.main.fonts.dmsRegular,
                  },
                }}
              />
            )}
          />
        </StyledFormFieldsWrapper>

        <StyledButtonsWrapper>
          <StyledButtonCancel variant="contained" onClick={handleCancel}>
            {t("cancel")}
          </StyledButtonCancel>

          <StyledButtonSave
            type="submit"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            {t("send")}
          </StyledButtonSave>
        </StyledButtonsWrapper>
      </form>
    </div>
  );
};
