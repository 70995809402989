export const PRE_CRUISE_TRAVEL_ARRANGEMENTS = [
  {
    label: "Pre cruise is required",
    value: 1
  },
  {
    label: "To be advised later",
    value: 2
  },
  {
    label: "Under own arrangement",
    value: 3
  }
];

export const POST_CRUISE_TRAVEL_ARRANGEMENTS = [
  {
    label: "Post cruise is required",
    value: 1
  },
  {
    label: "To be advised later",
    value: 2
  },
  {
    label: "Under own arrangement",
    value: 3
  }
];

export const AIRLINE = [
  {
    label: "LHR",
    value: "LHR value"
  },
  {
    label: "GBT",
    value: "GBT value"
  },
  {
    label: "AZUR",
    value: "AZUR value"
  }
]

