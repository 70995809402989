import { useState } from "react";
import { useTranslation } from "react-i18next";
import { SwitchBotton } from "../SwitchButton";
import {
  StyledEmailSubscriptionsSection,
  StyledEmailSubscriptionsSectionTitle,
  StyledEmailSubscriptionsSwitchsWrapper,
} from "./EmailSubscriptions.styles";
import { SUBSCRIPTIONS_ARRAY } from "./consts";

export const EmailSubscriptions = () => {
  const { t } = useTranslation();

  const [subscriptions, setSubscriptions] = useState(SUBSCRIPTIONS_ARRAY);

  console.log("subscriptions: ", subscriptions);

  return (
    <StyledEmailSubscriptionsSection>
      <StyledEmailSubscriptionsSectionTitle>
        {t("emailSubscriptions")}
      </StyledEmailSubscriptionsSectionTitle>
      <StyledEmailSubscriptionsSwitchsWrapper>
        <SwitchBotton
          subscriptions={subscriptions}
          onChange={(value) => setSubscriptions(value)}
        />
      </StyledEmailSubscriptionsSwitchsWrapper>
    </StyledEmailSubscriptionsSection>
  );
};
