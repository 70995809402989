import styled from "styled-components";
import { mainTheme } from "../../theme";

export const StyledTransactionsList = styled.div``

export const StyledTransactionsListBox = styled.div`
  padding: 12px;
  background: ${mainTheme.main.colors.white};
  margin-bottom: 12px;
  box-shadow: 0px 3px 14px -6px rgba(0,0,0,0.40);
`

export const StyledTransactionsListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${mainTheme.main.colors.graySilver};
  padding-top: 8px;
  padding-bottom: 8px;

  &:last-child {
    border: none;
  }
`

export const StyledTransactionsListItemColumnFirst = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const StyledTransactionsListItemColumnLast = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const StyledTransactionsListItemColumnFirstName = styled.div`
 color: ${mainTheme.main.colors.merin};
 font-size: ${mainTheme.main.fontSizes.small};
 font-weight: ${mainTheme.main.fontWeights.normal};
 font-family: ${mainTheme.main.fonts.dmsRegular};
`

export const StyledTransactionsListItemColumnLastData = styled.div`
 color: ${mainTheme.main.colors.black};
 font-size: ${mainTheme.main.fontSizes.medium};
 font-weight: ${mainTheme.main.fontWeights.normal};
 font-family: ${mainTheme.main.fonts.dmsRegular};

 &.plus {
  color: ${mainTheme.main.colors.green};
 }

 &.minus {
  color: ${mainTheme.main.colors.red};
 }
`