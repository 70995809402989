import styled from 'styled-components'
import { mainTheme } from "../../theme"

export const StyledLoyalty = styled.div`
  padding: 10px 20px 34px 20px;
  background: ${mainTheme.main.colors.whiteGray};
  box-sizing: border-box;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    padding-left: 0;
    padding-right: 0;
  }
`

export const StyledLoyaltyWrapper = styled.div`
  padding: 10px 20px 34px 20px;
  background: ${mainTheme.main.colors.white};
  box-sizing: border-box;

  display: grid;
  grid-template-columns: minmax(320px, 407px) minmax(auto, auto);
  gap: 20px 40px;
  grid-auto-flow: row;
  box-sizing: border-box;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    display: flex;
    flex-direction: column;
    padding: 9px;
    padding-left: 16px;
    padding-right: 16px;
  }
`

// Стили для блока с карточкой Лояльности
export const StyledLoyaltyContentColumn = styled.div`
  padding: 24px;
  box-sizing: border-box;
  background: ${mainTheme.main.colors.white};
  
  &.loyalty {
    position: relative;
    background: ${mainTheme.main.colors.blue};
    overflow: hidden;
    padding: 20px;
    min-height: 225px;
  }

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    padding: 0;
  }
`

export const StyledBackground = styled.div`
  position: absolute;
  right: 20px;
  bottom: -6px;
  box-sizing: border-box;
  z-index: 1;
`

export const StyledContentWrapper = styled.div`
  z-index: 2;
`

export const StyledWrapperSearch = styled.div`
  display: none;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    display: flex;
    width: 100%;
  }
`

export const StyledHeader = styled.div`
  padding: 24px 20px 0px 20px;
  box-sizing: border-box;
  background: ${mainTheme.main.colors.white};

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    padding: 24px 16px 0px 16px;
  }
`

export const StyledLoyaltyTitle = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-size: ${mainTheme.main.fontSizes.large};
  font-weight: ${mainTheme.main.fontWeights.bold};
  font-family: ${mainTheme.main.fonts.chapRegular};
  text-transform: uppercase;

  &.another {
    text-transform: none;
  }
`

export const StyledLoyaltyContent = styled.div`
  position: relative;
  z-index: 999;
  display: flex;
  justify-content: space-between;
`

export const StyledLoyaltyStatus = styled.div`
  color: ${mainTheme.main.colors.merin};
  font-size: 85px;
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.interRegular};
  z-index: 999;
  position: relative;
  margin-top: 5px;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    font-size: 65px;
    margin-bottom: 20px;
  }
`

export const StyledLoyaltyNumberCard = styled.div`
  color: ${mainTheme.main.colors.white};
  font-size: 32px;
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  z-index: 999;
  position: relative;
  margin-top: 20px;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    font-size: 26px;
    margin-top: 46px;
  }
`

export const StyledDetailsLoyalty = styled.div`
  color: ${mainTheme.main.colors.merin};
  font-size: ${mainTheme.main.fontSizes.medium};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  text-decoration: underline;
  margin-top: 15px;
`

export const StyledSignature = styled.div`
  color: ${mainTheme.main.colors.white};
  font-size: 15px;
  font-weight: ${mainTheme.main.fontWeights.bold};
  font-family: ${mainTheme.main.fonts.chapRegular};
`

export const StyledLevel = styled.div``

export const StyledBonuses = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
  margin-top: 20px;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    margin-top: 15px;
  }
`

export const StyledBonusesData = styled.div`
  color: ${mainTheme.main.colors.merin};
  font-size: 25px;
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  margin-bottom: 4px;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    font-size: 21px
  }
`

export const StyledBonusesPoints = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`

export const StyledBonusesHold = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    margin-top: 10px;
  }
`

// Стили для блока с формой
export const StyledBonusAccuralColumn = styled.div`
  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    margin-top: 15px;
  }
`

export const StyledBonusDescription = styled.div`
  color: ${mainTheme.main.colors.grayOpacity};
  font-size: 15px;
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  margin-top: 28px;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    margin-top: 15px;
  }
`

export const StyledFormBonusCheckWrapper = styled.div`
  margin-top: 25px;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    margin-top: 10px;
  }
`

export const StyledReadMore = styled.div`
  color: ${mainTheme.main.colors.merin};
  font-size: 15px;
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  text-decoration: underline;
`

export const StyledTransactionsSectionWrapper = styled.div`
  padding: 24px 20px 34px 20px;
  background: ${mainTheme.main.colors.white};
  box-sizing: border-box;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    padding: 9px;
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 12px;
  }
` 

export const StyledTableTransactionsWrapper = styled.div`
  display: block;
 
  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    display: none;
  }
`

export const StyledTransactionsWrapperMobile = styled.div`
  display: none;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    display: block;
  }
`

export const StyledTransactionsSectionWrapperMobile = styled.div`
  background-color: ${mainTheme.main.colors.whiteGray};
  background-color: ${mainTheme.main.colors.white};
  padding: 16px;
`