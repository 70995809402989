import { useState } from "react";
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Box,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { t } from "i18next";
import { CruisesTableProps } from "./types";
import { CommonTable } from "../CustomTable";
import { HEAD_CEALS } from "./consts";
import {
  StyledTableCellText,
  StyledTableWrapper,
  StyledTwoRow,
  StyledTwoRowColumn,
} from "./CruisesTable.styles";

type TableHeaderColumnProps = {
  onRequestSort: (event: any, property: any) => void;
  order: any;
  orderBy: string;
};

type HeadCellProps = {
  id: any;
  numeric: boolean;
  label: string;
};

const TableHeaderColumn = ({
  onRequestSort,
  order,
  orderBy,
}: TableHeaderColumnProps) => {
  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {HEAD_CEALS.map((headCell: HeadCellProps, index) => {
          return (
            <TableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
              align={index + 1 === HEAD_CEALS?.length ? "right" : "left"}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {t(headCell.id)}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

const TableCellDay = ({ cruise }: any) => {
  return (
    <TableCell>
      <StyledTableCellText className="fantomColor">
        {cruise?.day ?? "-"}
      </StyledTableCellText>
    </TableCell>
  );
};

const TableCellDate = ({ cruise }: any) => {
  return (
    <TableCell>
      <StyledTableCellText className="fantomColor">
        {cruise?.date}
      </StyledTableCellText>
    </TableCell>
  );
};

const TableCellCountry = ({ cruise }: any) => {
  return (
    <TableCell>
      <StyledTableCellText>{cruise?.country}</StyledTableCellText>
    </TableCell>
  );
};

const TableCellLocation = ({ cruise }: any) => {
  return (
    <TableCell>
      <StyledTableCellText>{cruise?.location}</StyledTableCellText>
    </TableCell>
  );
};

const TableCellArrive = ({ cruise }: any) => {
  return (
    <TableCell>
      <StyledTableCellText>
        <StyledTwoRow>
          <StyledTwoRowColumn className="fantomColor">
            {cruise?.arriveDate}
          </StyledTwoRowColumn>
          <StyledTwoRowColumn>{cruise?.arriveTime}</StyledTwoRowColumn>
        </StyledTwoRow>
      </StyledTableCellText>
    </TableCell>
  );
};

const TableCellDuration = ({ cruise }: any) => {
  return (
    <TableCell>
      <StyledTableCellText className="grayColor">
        {cruise?.duration}
      </StyledTableCellText>
    </TableCell>
  );
};

const TableCellDeaprt = ({ cruise }: any) => {
  return (
    <TableCell>
      <StyledTableCellText>
        <StyledTwoRow>
          <StyledTwoRowColumn className="fantomColor">
            {cruise?.departDate}
          </StyledTwoRowColumn>
          <StyledTwoRowColumn>{cruise?.departTime}</StyledTwoRowColumn>
        </StyledTwoRow>
      </StyledTableCellText>
    </TableCell>
  );
};

const TableCruisesRows = ({ cruise, onClickRow }: any) => {
  const handleClick = () => {
    onClickRow(cruise);
  };

  return (
    <TableRow onClick={handleClick}>
      <TableCellDay cruise={cruise} />
      <TableCellDate cruise={cruise} />
      <TableCellCountry cruise={cruise} />
      <TableCellLocation cruise={cruise} />
      <TableCellArrive cruise={cruise} />
      <TableCellDuration cruise={cruise} />
      <TableCellDeaprt cruise={cruise} />
    </TableRow>
  );
};

export const CruisesTable = ({ cruises }: CruisesTableProps) => {
  const [currentSelectedRowInTable, setCurrentSelectedRowInTable] =
    useState(null);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  console.log("order: ", order);
  console.log("orderBy: ", orderBy);
  console.log("currentSelectedRowInTable: ", currentSelectedRowInTable);

  return (
    <StyledTableWrapper>
      <CommonTable
        headerList={
          <TableHeaderColumn
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
        }
      >
        {cruises?.map((item: any) => (
          <TableCruisesRows
            key={item?.code?.number}
            cruise={item}
            onClickRow={(value: any) => setCurrentSelectedRowInTable(value)}
          />
        ))}
      </CommonTable>
    </StyledTableWrapper>
  );
};
