import { useEffect, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { t } from "i18next";
import { axiosInstance } from "../../utils/axios";
import { PersonalInformation } from "../../components/PersonalInformation";
import IconUinion from "../../icons/IconUniom";
import { ModalFormEdit } from "../../components/ModalFormEdit";
import { TypeFormEnum } from "../../components/ModalFormEdit/types";
import { PassportDetails } from "../../components/PassportDetails";
import { EmailSubscriptions } from "../../components/EmailSubscriptions";
import { BookingsListMobile } from "../../components/BookingsListMobile";
import { Search } from "../../components/Search";
import { BookingsTable } from "../../components/BookingsTable";
import { CurrentEditFormType, PaginationType } from "../../utils/globalTypes";
import { CurrentUserType } from "./types";
import { ORDER_LIST } from "../../appolo/graphql/queries/orderList";
import { CURRENT_USER } from "../../appolo/graphql/queries/currentUser";
import {
  EmailSubscriptionsWrapperDesktop,
  EmailSubscriptionsWrapperMobile,
  StyledBackground,
  StyledColumnOne,
  StyledColumnTwo,
  StyledContentWrapper,
  StyledDetailsLoyalty,
  StyledLoyaltyContent,
  StyledLoyaltyNumberCard,
  StyledLoyaltyStatus,
  StyledLoyaltyTitle,
  StyledProfileColumnsWrapper,
  StyledProfileWrapper,
  StyledSectionColumn,
  StyledSectionWrapper,
  StyledTitleSection,
  StyledViewAllButton,
  StyledWrapperSearch,
} from "./Profile.styles";

export const Profile = () => {
  const [currentEditForm, setCurrentEditForm] = useState<CurrentEditFormType>({
    title: "",
    typeEditForm: "",
  });

  // Данные авторизованого пользователя
  const { data: currentUserData } = useQuery(CURRENT_USER, {
    // skip: skip,
    onCompleted: ({ currentUser }) => {},
  });

  // Количество записей в таблице бронирования (по-умолчанию)
  const countViewBooking = 5;

  // Для таблицы Bookings
  const [getBookings, { data: orderListData, loading: orderListDataLoading }] = useLazyQuery(ORDER_LIST);

  const paginationTableBookings: PaginationType = orderListData?.orderList.pagination;

  const [searchValue, setSearchValue] = useState("");
  const [currentUser, setCurrentUser] = useState<CurrentUserType>();
  const [currentPage, setCurrentPage] = useState(paginationTableBookings?.currentPage ?? 1);

  useEffect(() => {
    getBookings({
      fetchPolicy: 'network-only',
      variables: {
        perPage: countViewBooking,
        page: currentPage, // если переходить дальше чем на 2-ю страницу метод падает
        context: {
          market: "ru",
        },
      }
    })
  }, [currentPage]);

  // Показать все записи в таблице
  const handleViewAll = () => {
    getBookings({
      fetchPolicy: 'network-only',
      variables: {
        perPage: paginationTableBookings?.totalCount, // сейчас метод падает если указать получить все записи
        page: 1,
        context: {
          market: "ru",
        },
      }
    })
  };

  const getCurrentUser = async () => {
    await axiosInstance
      .get(`ru/users/current`)
      .then(function (response) {
        setCurrentUser(response?.data);
        return response;
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  };

  useEffect(() => {
    getCurrentUser()
  }, [])

  console.log("searchValue: ", searchValue);
  console.log("currentUserData: ", currentUserData);
  console.log("orderListData: ", orderListData);
  console.log("paginationTableBookings: ", paginationTableBookings);

  return (
    <>
      <StyledProfileWrapper>
        {/* Раздел Personal Information */}
        <StyledProfileColumnsWrapper>
          <StyledColumnOne>
            <PersonalInformation
              editForm={(value) => setCurrentEditForm(value)}
              currentUser={currentUser}
            />
          </StyledColumnOne>

          <StyledColumnTwo className="loyalty">
            <StyledBackground>
              <IconUinion />
            </StyledBackground>
            <StyledContentWrapper>
              <StyledLoyaltyTitle>
                {t("swanHellenicLoyaltyNumber")}
              </StyledLoyaltyTitle>

              <StyledLoyaltyContent>
                <StyledLoyaltyStatus>
                  7%
                </StyledLoyaltyStatus>
                <StyledLoyaltyNumberCard>
                  1244  5678  9012  3456
                </StyledLoyaltyNumberCard>
                <StyledDetailsLoyalty>
                  {t("details")}
                </StyledDetailsLoyalty>
              </StyledLoyaltyContent>
            </StyledContentWrapper>
          </StyledColumnTwo>
        </StyledProfileColumnsWrapper>

        {/* Раздел Passport details */}
        <StyledProfileColumnsWrapper>
          <StyledColumnOne>
            <PassportDetails editForm={(value) => setCurrentEditForm(value)} />
          </StyledColumnOne>
          <StyledColumnTwo>
            <EmailSubscriptionsWrapperDesktop>
              <EmailSubscriptions />
            </EmailSubscriptionsWrapperDesktop>
          </StyledColumnTwo>
        </StyledProfileColumnsWrapper>

        {/* Секция с Заголовком раздела */}
        <StyledSectionWrapper>
          <StyledSectionColumn>
            <StyledTitleSection>
              {t("bookings")}
            </StyledTitleSection>
            <StyledViewAllButton onClick={handleViewAll}>
              {t("viewAll")}
            </StyledViewAllButton>
          </StyledSectionColumn>

          {/* Поле поиска (только mobile) */}
          <StyledSectionColumn>
            <StyledWrapperSearch>
              <Search 
                label="search"
                value={searchValue}
                setValue={(value) => setSearchValue(value)}
              />
            </StyledWrapperSearch>
          </StyledSectionColumn>
        </StyledSectionWrapper>

        {/* Таблица с бронированиями (только desktop) */}
        <BookingsTable 
          bookings={orderListData?.orderList.list ?? []} 
          setCurrentPage={(value: number) => setCurrentPage(value)}
          pageCount={paginationTableBookings?.pageCount}
          orderListDataLoading={orderListDataLoading}
        />
      </StyledProfileWrapper>

      {/* Бронирования (только mobile) */}
      <BookingsListMobile 
        bookings={orderListData?.orderList.list ?? []}
        setCurrentPage={(value: number) => setCurrentPage(value)}
        pageCount={paginationTableBookings?.pageCount}
        orderListDataLoading={orderListDataLoading}
      />

      <EmailSubscriptionsWrapperMobile>
        <EmailSubscriptions />
      </EmailSubscriptionsWrapperMobile>

      {/* Модальное окно в котором формы для редактирования данных */}
      <ModalFormEdit
        title={currentEditForm.title}
        typeForm={TypeFormEnum.loyalty}
        currentEditForm={currentEditForm}
        setCurrentEditForm={(value) => setCurrentEditForm(value)}
      />
    </>
  );
};
