import Modal from "@mui/material/Modal";
import {
  StyledContentWrapper,
  StyledMain,
  StyledModalWrapper,
  StyledTitle,
} from "./CustomModal.styles";
import { CustomModalProps } from "./types";

export const CustomModal = ({
  open,
  setOpen,
  children,
  title,
}: CustomModalProps) => {
  const handleClose = () => setOpen(false);

  return (
    <StyledModalWrapper>
      <Modal open={open} onClose={handleClose}>
        <StyledContentWrapper>
          <>
            <StyledTitle>{title}</StyledTitle>
            <StyledMain>
              {children}
            </StyledMain>
          </>
        </StyledContentWrapper>
      </Modal>
    </StyledModalWrapper>
  );
};
