import { t } from "i18next";
import { TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { StyledFieldWrapper, StyledIconWrapper } from "./Search.styles";

type SearchProps = {
  label: any;
  value: string;
  setValue: (value: string) => void;
};

export const Search = ({ label, value, setValue }: SearchProps) => {
  return (
    <StyledFieldWrapper sx={{ display: "flex", alignItems: "flex-end" }}>
      <StyledIconWrapper>
        <SearchIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
      </StyledIconWrapper>
      <TextField
        variant="outlined" 
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder={t(label)}
      />
    </StyledFieldWrapper>
  );
};
