export enum VariantCustomSelectEnum {
  filled = "filled",
  outlined = "outlined",
  standard = "standard",
}

export type CustomSelectProps = {
  label: any
  children: any,
  defaultValue?: string
  onChange: (value: any) => void
  variant?: VariantCustomSelectEnum
}