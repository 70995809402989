import styled from "styled-components";
import { mainTheme } from "../../theme";

export const StyledTravellersList = styled.div``

export const StyledTravellersListBox = styled.div`
  padding: 12px;
  background: ${mainTheme.main.colors.white};
  margin-bottom: 12px;
  box-shadow: 0px 3px 14px -6px rgba(0,0,0,0.40);
`

export const StyledTravellersListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;

  &:last-child {
    border: none;
  }
`

export const StyledTravellersListItemColumnFirst = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
`

export const StyledTravellersListItemColumnLast = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-start;
`

export const StyledTravellersListItemColumnFirstName = styled.div`
 color: ${mainTheme.main.colors.merin};
 font-size: ${mainTheme.main.fontSizes.small};
 font-weight: ${mainTheme.main.fontWeights.normal};
 font-family: ${mainTheme.main.fonts.dmsRegular};
`

export const StyledTravellersListItemColumnLastData = styled.div`
 color: ${mainTheme.main.colors.black};
 font-size: ${mainTheme.main.fontSizes.medium};
 font-weight: ${mainTheme.main.fontWeights.normal};
 font-family: ${mainTheme.main.fonts.dmsRegular};
`

export const StyledIconEditWrapper = styled.div`
  &:hover {
    cursor: pointer;
  }
`
