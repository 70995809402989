import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { FormControlLabel, Radio, TextField } from "@mui/material";
import { mainTheme } from "../../../theme";
import { CustomSelect } from "../components/CustomSelect";
import { FieldDatePicker } from "../components/FieldDatePicker";
import { CustomRadioGroup } from "../components/CustomRadioGroup";
import IconCheckRadio from "../../../icons/IconCheckRadio";
import { VariantCustomSelectEnum } from "../components/CustomSelect/types";
import { CustomCheckbox } from "../components/CustomCheckbox";
import { GENDER, INITIALS } from "./consts";
import { FormEditPersonalInformationProps } from "./types";
import {
  StyledButtonCancel,
  StyledButtonSave,
  StyledButtonsWrapper,
  StyledFiedlsRow,
  StyledFormEditPersonalInformationWrapper,
  StyledSectionFieldsTitle,
} from "./FormEditPersonalInformation.styles";
import { axiosInstance } from "../../../utils/axios";
import { CitiesListType, CountriesListType } from "../../../utils/globalTypes";

type IFormEditPersonalInformationInput = {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  title?: string;
  birthDate?: string;
  nationality?: string;
  gender?: string;
  phone?: string;
  email?: string;
  addressLine?: string;
  country?: string;
  countyState?: string;
  city?: string;
  postCodeZipCode?: string;
  agreement?: boolean
};

export const FormEditPersonalInformation = ({
  setOpen,
}: FormEditPersonalInformationProps) => {
  const { t } = useTranslation();

  const [countriesList, setCountriesList] = useState<CountriesListType[]>([])

  const [citiesList, setCitiesList] = useState<CitiesListType[]>([])

  const schema = yup.object().shape({
    firstName: yup.string(),
    middleName: yup.string(),
    lastName: yup.string(),
    title: yup.string(),
    birthDate: yup.string(),
    nationality: yup.string(),
    gender: yup.string(),
    phone: yup.string(),
    email: yup.string(),
    addressLine: yup.string(),
    country: yup.string(),
    countyState: yup.string(),
    city: yup.string(),
    postCodeZipCode: yup.string(),
    agreement: yup.boolean()
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<IFormEditPersonalInformationInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: "William",
      middleName: "James",
      lastName: "Smith",
      title: "1",
      birthDate: "03.01.1990",
      nationality: "1",
      gender: "1",
      phone: "+4407714170414",
      email: "j.smith@yahoo.com",
      addressLine: "2d, Caradon Hill",
      country: "1",
      countyState: "England",
      city: "London",
      postCodeZipCode: "TN31 5QG",
      agreement: false
    },
  });

  const onSubmit = async (data: IFormEditPersonalInformationInput) => {
    const formValue = {
      ...data,
      birthDate: dayjs(data.birthDate).format("YYYY.MM.DD"),
    };
    console.log("formValue: ", formValue);
  };

  const handleCancel = () => {
    if (setOpen) {
      setOpen(false);
    }
  };

  const getCountriesList = async () => {
    await axiosInstance
      .get(`ru/suggestions/countries`)
      .then(function (response) {
        setCountriesList(response?.data)
        return response;
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  };

  const getCitiesList = async () => {
    await axiosInstance
      .get(`ru/suggestions/cities`)
      .then(function (response) {
        setCitiesList(response?.data)
        return response;
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  };


  useEffect(() => {
    getCountriesList();
    getCitiesList();
  }, [])

  return (
    <StyledFormEditPersonalInformationWrapper>
      <form>
        <StyledFiedlsRow>
          <Controller
            control={control}
            name="firstName"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                label={t("firstName")}
                variant="standard"
                onChange={onChange}
                value={value}
                InputProps={{
                  className: "textFieldForm",
                  style: {
                    color: mainTheme.main.colors.black,
                    fontSize: mainTheme.main.fontSizes.large,
                    fontWeight: mainTheme.main.fontWeights.normal,
                    fontFamily: mainTheme.main.fonts.dmsRegular,
                  },
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="middleName"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                label={t("middleName")}
                variant="standard"
                onChange={onChange}
                value={value}
                InputProps={{
                  className: "textFieldForm",
                  style: {
                    color: mainTheme.main.colors.black,
                    fontSize: mainTheme.main.fontSizes.large,
                    fontWeight: mainTheme.main.fontWeights.normal,
                    fontFamily: mainTheme.main.fonts.dmsRegular,
                  },
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="lastName"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                label={t("lastName")}
                variant="standard"
                onChange={onChange}
                value={value}
                InputProps={{
                  className: "textFieldForm",
                  style: {
                    color: mainTheme.main.colors.black,
                    fontSize: mainTheme.main.fontSizes.large,
                    fontWeight: mainTheme.main.fontWeights.normal,
                    fontFamily: mainTheme.main.fonts.dmsRegular,
                  },
                }}
              />
            )}
          />
        </StyledFiedlsRow>

        <StyledFiedlsRow>
          <Controller
            control={control}
            name="title"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <CustomSelect
                label={t("initials")}
                onChange={onChange}
                defaultValue="1"
                variant={VariantCustomSelectEnum.standard}
              >
                {INITIALS.map((item) => (
                  <MenuItem value={item.value}>{item.label}</MenuItem>
                ))}
              </CustomSelect>
            )}
          />

          <Controller
            control={control}
            name="birthDate"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <FieldDatePicker
                onChange={onChange}
                value={value ? dayjs(value) : null}
                label={t("birthDate")}
              />
            )}
          />

          <Controller
            control={control}
            name="nationality"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <CustomSelect
                label={t("nationality")}
                onChange={onChange}
                // defaultValue="1"
                variant={VariantCustomSelectEnum.standard}
              >
                {countriesList?.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.value}
                  </MenuItem>
                ))}
              </CustomSelect>
            )}
          />
        </StyledFiedlsRow>

        <StyledFiedlsRow className="oneColumns">
          <Controller
            control={control}
            name="gender"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <CustomRadioGroup
                label={t("gender")}
                onChange={onChange}
                defaultValue="1"
              >
                {GENDER.map((item) => (
                  <FormControlLabel
                    key={item.value}
                    value={item.value}
                    control={<Radio checkedIcon={<IconCheckRadio />} />}
                    // @ts-ignore
                    label={t(item.label)}
                  />
                ))}
              </CustomRadioGroup>
            )}
          />
        </StyledFiedlsRow>

        <StyledFiedlsRow className="twoColumns">
          <Controller
            control={control}
            name="phone"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                label={t("phone")}
                variant="standard"
                onChange={onChange}
                value={value}
                InputProps={{
                  className: "textFieldForm",
                  style: {
                    color: mainTheme.main.colors.black,
                    fontSize: mainTheme.main.fontSizes.large,
                    fontWeight: mainTheme.main.fontWeights.normal,
                    fontFamily: mainTheme.main.fonts.dmsRegular,
                  },
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="email"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                label={t("email")}
                variant="standard"
                onChange={onChange}
                value={value}
                InputProps={{
                  className: "textFieldForm",
                  style: {
                    color: mainTheme.main.colors.black,
                    fontSize: mainTheme.main.fontSizes.large,
                    fontWeight: mainTheme.main.fontWeights.normal,
                    fontFamily: mainTheme.main.fonts.dmsRegular,
                  },
                }}
              />
            )}
          />
        </StyledFiedlsRow>

        <StyledSectionFieldsTitle>
          {t("contactDetails")}
        </StyledSectionFieldsTitle>

        <StyledFiedlsRow className="oneColumns">
          <Controller
            control={control}
            name="addressLine"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                label={t("addressLine1")}
                variant="standard"
                onChange={onChange}
                value={value}
                InputProps={{
                  className: "textFieldForm",
                  style: {
                    color: mainTheme.main.colors.black,
                    fontSize: mainTheme.main.fontSizes.large,
                    fontWeight: mainTheme.main.fontWeights.normal,
                    fontFamily: mainTheme.main.fonts.dmsRegular,
                  },
                }}
              />
            )}
          />
        </StyledFiedlsRow>

        <StyledFiedlsRow className="twoColumns">
          <Controller
            control={control}
            name="country"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <CustomSelect
                label={t("country")}
                onChange={onChange}
                // defaultValue="1"
                variant={VariantCustomSelectEnum.standard}
              >
                {countriesList?.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.value}
                  </MenuItem>
                ))}
              </CustomSelect>
            )}
          />

          <Controller
            control={control}
            name="countyState"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                label={t("countyState")}
                variant="standard"
                onChange={onChange}
                value={value}
                InputProps={{
                  className: "textFieldForm",
                  style: {
                    color: mainTheme.main.colors.black,
                    fontSize: mainTheme.main.fontSizes.large,
                    fontWeight: mainTheme.main.fontWeights.normal,
                    fontFamily: mainTheme.main.fonts.dmsRegular,
                  },
                }}
              />
            )}
          />
        </StyledFiedlsRow>

        <StyledFiedlsRow className="twoColumns">
          <Controller
            control={control}
            name="city"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <CustomSelect
                label={t("city")}
                onChange={onChange}
                // defaultValue="1"
                variant={VariantCustomSelectEnum.standard}
              >
                {citiesList?.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.value}
                  </MenuItem>
                ))}
              </CustomSelect>
            )}
          />

          <Controller
            control={control}
            name="postCodeZipCode"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                label={t("postCodeZipCode")}
                variant="standard"
                onChange={onChange}
                value={value}
                InputProps={{
                  className: "textFieldForm",
                  style: {
                    color: mainTheme.main.colors.black,
                    fontSize: mainTheme.main.fontSizes.large,
                    fontWeight: mainTheme.main.fontWeights.normal,
                    fontFamily: mainTheme.main.fonts.dmsRegular,
                  },
                }}
              />
            )}
          />
        </StyledFiedlsRow>

        <Controller
          control={control}
          name="agreement"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <CustomCheckbox 
              value={value}
              label={t("agreement")}
              setValue={onChange}
            />
          )}
        />
        
        <StyledButtonsWrapper>
          <StyledButtonCancel variant="contained" onClick={handleCancel}>
            {t("cancel")}
          </StyledButtonCancel>
          <StyledButtonSave
            type="submit"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            {t("save")}
          </StyledButtonSave>
        </StyledButtonsWrapper>
      </form>
    </StyledFormEditPersonalInformationWrapper>
  );
};
