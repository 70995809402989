import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { FormControlLabel, MenuItem, Radio, TextField } from "@mui/material";
import { CustomSelect } from "../components/CustomSelect";
import { VariantCustomSelectEnum } from "../components/CustomSelect/types";
import { mainTheme } from "../../../theme";
import {
  StyledButtonCancel,
  StyledButtonSave,
  StyledButtonsWrapper,
  StyledFormFieldsWrapper,
  StyledFormFieldsWrapperThreeColumns,
  StyledFormFieldsWrapperTwoColumns,
  StyledPostFieldsWrapper,
  StyledPreFieldsWrapper,
  StyledRadioGroupWrapper,
} from "./FormEditArrangementsBooked.styles";
import {
  AIRLINE,
  POST_CRUISE_TRAVEL_ARRANGEMENTS,
  PRE_CRUISE_TRAVEL_ARRANGEMENTS,
} from "./consts";
import { CustomRadioGroup } from "../components/CustomRadioGroup";
import IconCheckRadio from "../../../icons/IconCheckRadio";
import { FieldDatePicker } from "../components/FieldDatePicker";

export type FormEditArrangementsBookedProps = {
  setOpen: (value: boolean) => void;
};

type FormEditArrangementsBookedTInputype = {
  // Pre
  preCruise: string;
  preAirline: string;
  preFlightNumber: string;
  preAirport: string;
  preArrivalDate: string;
  preArrivalTime: string;
  // Post
  postCruise: string;
  postAirline: string;
  postFlightNumber: string;
  postAirport: string;
  departureDate: string;
  departureTime: string;
};

export const FormEditArrangementsBooked = ({
  setOpen,
}: FormEditArrangementsBookedProps) => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    // Pre
    preCruise: yup.string().required(t("fieldRequired")),
    preAirline: yup.string().required(t("fieldRequired")),
    preFlightNumber: yup.string().required(t("fieldRequired")),
    preAirport: yup.string().required(t("fieldRequired")),
    preArrivalDate: yup.string().required(t("fieldRequired")),
    preArrivalTime: yup.string().required(t("fieldRequired")),
    // Post
    postCruise: yup.string().required(t("fieldRequired")),
    postAirline: yup.string().required(t("fieldRequired")),
    postFlightNumber: yup.string().required(t("fieldRequired")),
    postAirport: yup.string().required(t("fieldRequired")),
    departureDate: yup.string().required(t("fieldRequired")),
    departureTime: yup.string().required(t("fieldRequired")),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormEditArrangementsBookedTInputype>({
    resolver: yupResolver(schema),
    defaultValues: {
      // Pre
      preCruise: "",
      preAirline: "",
      preFlightNumber: "",
      preAirport: "",
      preArrivalDate: "",
      preArrivalTime: "",
      // Post
      postCruise: "",
      postAirline: "",
      postFlightNumber: "",
      postAirport: "",
      departureDate: "",
      departureTime: "",
    },
  });

  const onSubmit = async (data: FormEditArrangementsBookedTInputype) => {
    const formValue = {
      ...data,
    };
    console.log("formValue: ", formValue);
  };

  const handleCancel = () => {
    if (setOpen) {
      setOpen(false);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledFormFieldsWrapper>
          {/* Pre fields */}
          <StyledPreFieldsWrapper>
            <StyledRadioGroupWrapper>
              <Controller
                control={control}
                name="preCruise"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <CustomRadioGroup
                    label={t("precruiseTravelArrangements")}
                    onChange={onChange}
                    defaultValue="1"
                  >
                    {PRE_CRUISE_TRAVEL_ARRANGEMENTS.map((item) => (
                      <FormControlLabel
                        key={item.value}
                        value={item.value}
                        control={<Radio checkedIcon={<IconCheckRadio />} />}
                        // @ts-ignore
                        label={t(item.label)}
                      />
                    ))}
                  </CustomRadioGroup>
                )}
              />
            </StyledRadioGroupWrapper>

            <StyledFormFieldsWrapperTwoColumns>
              <div>
                <Controller
                  control={control}
                  name="preAirline"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <CustomSelect
                      label={t("airline")}
                      onChange={onChange}
                      variant={VariantCustomSelectEnum.standard}
                      defaultValue={value}
                    >
                      {AIRLINE?.map((item) => (
                        <MenuItem key={item.value} value={item?.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  )}
                />
              </div>

              <div>
                <Controller
                  control={control}
                  name="preFlightNumber"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <TextField
                      placeholder={t("flightNumber")}
                      label={t("flightNumber")}
                      variant="standard"
                      onChange={onChange}
                      value={value}
                      InputProps={{
                        className: "textFieldForm",
                        style: {
                          color: mainTheme.main.colors.black,
                          fontSize: mainTheme.main.fontSizes.large,
                          fontWeight: mainTheme.main.fontWeights.normal,
                          fontFamily: mainTheme.main.fonts.dmsRegular,
                        },
                      }}
                    />
                  )}
                />
              </div>
            </StyledFormFieldsWrapperTwoColumns>

            <StyledFormFieldsWrapperThreeColumns>
              <div>
                <Controller
                  control={control}
                  name="preAirport"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <TextField
                      placeholder={t("airport")}
                      label={t("airport")}
                      variant="standard"
                      onChange={onChange}
                      value={value}
                      InputProps={{
                        className: "textFieldForm",
                        style: {
                          color: mainTheme.main.colors.black,
                          fontSize: mainTheme.main.fontSizes.large,
                          fontWeight: mainTheme.main.fontWeights.normal,
                          fontFamily: mainTheme.main.fonts.dmsRegular,
                        },
                      }}
                    />
                  )}
                />
              </div>

              <div>
                <Controller
                  control={control}
                  name="preArrivalDate"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <FieldDatePicker
                      onChange={onChange}
                      value={value ? dayjs(value) : null}
                      label={t("arrivalDate")}
                    />
                  )}
                />
              </div>

              <div>
                <Controller
                  control={control}
                  name="preArrivalTime"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <TextField
                      placeholder={t("arrivalTime")}
                      label={t("arrivalTime")}
                      variant="standard"
                      onChange={onChange}
                      value={value}
                      InputProps={{
                        className: "textFieldForm",
                        style: {
                          color: mainTheme.main.colors.black,
                          fontSize: mainTheme.main.fontSizes.large,
                          fontWeight: mainTheme.main.fontWeights.normal,
                          fontFamily: mainTheme.main.fonts.dmsRegular,
                        },
                      }}
                    />
                  )}
                />
              </div>
            </StyledFormFieldsWrapperThreeColumns>
          </StyledPreFieldsWrapper>

          {/* Post fields */}
          <StyledPostFieldsWrapper>
            <StyledRadioGroupWrapper>
              <Controller
                control={control}
                name="postCruise"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <CustomRadioGroup
                    label={t("postCruiseTravelArrangements")}
                    onChange={onChange}
                    defaultValue="1"
                  >
                    {POST_CRUISE_TRAVEL_ARRANGEMENTS.map((item) => (
                      <FormControlLabel
                        key={item.value}
                        value={item.value}
                        control={<Radio checkedIcon={<IconCheckRadio />} />}
                        // @ts-ignore
                        label={t(item.label)}
                      />
                    ))}
                  </CustomRadioGroup>
                )}
              />
            </StyledRadioGroupWrapper>

            <StyledFormFieldsWrapperTwoColumns>
              <div>
                <Controller
                  control={control}
                  name="postAirline"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <CustomSelect
                      label={t("airline")}
                      onChange={onChange}
                      variant={VariantCustomSelectEnum.standard}
                      defaultValue={value}
                    >
                      {AIRLINE?.map((item) => (
                        <MenuItem key={item.value} value={item?.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  )}
                />
              </div>

              <div>
                <Controller
                  control={control}
                  name="postFlightNumber"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <TextField
                      placeholder={t("flightNumber")}
                      label={t("flightNumber")}
                      variant="standard"
                      onChange={onChange}
                      value={value}
                      InputProps={{
                        className: "textFieldForm",
                        style: {
                          color: mainTheme.main.colors.black,
                          fontSize: mainTheme.main.fontSizes.large,
                          fontWeight: mainTheme.main.fontWeights.normal,
                          fontFamily: mainTheme.main.fonts.dmsRegular,
                        },
                      }}
                    />
                  )}
                />
              </div>
            </StyledFormFieldsWrapperTwoColumns>

            <StyledFormFieldsWrapperThreeColumns>
              <div>
                <Controller
                  control={control}
                  name="postAirport"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <TextField
                      placeholder={t("airport")}
                      label={t("airport")}
                      variant="standard"
                      onChange={onChange}
                      value={value}
                      InputProps={{
                        className: "textFieldForm",
                        style: {
                          color: mainTheme.main.colors.black,
                          fontSize: mainTheme.main.fontSizes.large,
                          fontWeight: mainTheme.main.fontWeights.normal,
                          fontFamily: mainTheme.main.fonts.dmsRegular,
                        },
                      }}
                    />
                  )}
                />
              </div>

              <div>
                <Controller
                  control={control}
                  name="departureDate"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <FieldDatePicker
                      onChange={onChange}
                      value={value ? dayjs(value) : null}
                      label={t("departureDate")}
                    />
                  )}
                />
              </div>

              <div>
                <Controller
                  control={control}
                  name="departureTime"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <TextField
                      placeholder={t("departureTime")}
                      label={t("departureTime")}
                      variant="standard"
                      onChange={onChange}
                      value={value}
                      InputProps={{
                        className: "textFieldForm",
                        style: {
                          color: mainTheme.main.colors.black,
                          fontSize: mainTheme.main.fontSizes.large,
                          fontWeight: mainTheme.main.fontWeights.normal,
                          fontFamily: mainTheme.main.fonts.dmsRegular,
                        },
                      }}
                    />
                  )}
                />
              </div>
            </StyledFormFieldsWrapperThreeColumns>
          </StyledPostFieldsWrapper>
        </StyledFormFieldsWrapper>

        <StyledButtonsWrapper>
          <StyledButtonCancel variant="contained" onClick={handleCancel}>
            {t("cancel")}
          </StyledButtonCancel>

          <StyledButtonSave
            type="submit"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            {t("save")}
          </StyledButtonSave>
        </StyledButtonsWrapper>
      </form>
    </div>
  );
};
