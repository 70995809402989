import { t } from "i18next";
import { TransactionsListMobileProps } from "./types";
import {
  StyledTransactionsList,
  StyledTransactionsListBox,
  StyledTransactionsListItem,
  StyledTransactionsListItemColumnFirst,
  StyledTransactionsListItemColumnFirstName,
  StyledTransactionsListItemColumnLast,
  StyledTransactionsListItemColumnLastData,
} from "./TransactionsListMobile.styles";

export const TransactionsListMobile = ({
  transactions,
}: TransactionsListMobileProps) => {
  return (
    <StyledTransactionsList>
      {transactions?.map((item: any, index: number) => (
        <StyledTransactionsListBox>
          <StyledTransactionsListItem>
            <StyledTransactionsListItemColumnFirst>
              <StyledTransactionsListItemColumnFirstName>
                {t("dateBooking")}
              </StyledTransactionsListItemColumnFirstName>
            </StyledTransactionsListItemColumnFirst>

            <StyledTransactionsListItemColumnLast>
              <StyledTransactionsListItemColumnLastData>
                {item?.dateTime}
              </StyledTransactionsListItemColumnLastData>
            </StyledTransactionsListItemColumnLast>
          </StyledTransactionsListItem>

          <StyledTransactionsListItem>
            <StyledTransactionsListItemColumnFirst>
              <StyledTransactionsListItemColumnFirstName>
                {t("operationType")}
              </StyledTransactionsListItemColumnFirstName>
            </StyledTransactionsListItemColumnFirst>

            <StyledTransactionsListItemColumnLast>
              <StyledTransactionsListItemColumnLastData>
                {item?.operationType}
              </StyledTransactionsListItemColumnLastData>
            </StyledTransactionsListItemColumnLast>
          </StyledTransactionsListItem>

          <StyledTransactionsListItem>
            <StyledTransactionsListItemColumnFirst>
              <StyledTransactionsListItemColumnFirstName>
                {t("bookingCode")}
              </StyledTransactionsListItemColumnFirstName>
            </StyledTransactionsListItemColumnFirst>

            <StyledTransactionsListItemColumnLast>
              <StyledTransactionsListItemColumnLastData>
                {item?.bookingCode}
              </StyledTransactionsListItemColumnLastData>
            </StyledTransactionsListItemColumnLast>
          </StyledTransactionsListItem>

          <StyledTransactionsListItem>
            <StyledTransactionsListItemColumnFirst>
              <StyledTransactionsListItemColumnFirstName>
                {t("bonuses")}
              </StyledTransactionsListItemColumnFirstName>
            </StyledTransactionsListItemColumnFirst>

            <StyledTransactionsListItemColumnLast>
              <StyledTransactionsListItemColumnLastData
                className={item?.bonuses?.up ? "plus" : "minus"}
              >
                {item?.bonuses?.number}
              </StyledTransactionsListItemColumnLastData>
            </StyledTransactionsListItemColumnLast>
          </StyledTransactionsListItem>
        </StyledTransactionsListBox>
      ))}
    </StyledTransactionsList>
  );
};
