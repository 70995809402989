import { Box, Table, TableBody, TableContainer } from "@mui/material";

type CommonTableProps = {
  headerList: any;
  children: any;
};

export const CommonTable = ({ headerList, children }: CommonTableProps) => {
  return (
    <Box>
      <TableContainer>
        <Table>
          {headerList}
          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
