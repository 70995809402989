import { useTranslation } from "react-i18next";
import { StyledButtonDownloadAll, StyledDocumentIconWrapper, StyledDocumentLink, StyledDocumentsList, StyledDocumentsListItem, StyledDocumentsWrapper, StyledTitle } from "./DocumentsSection.styles";
import { DOCUMENTS } from "./consts";
import IconPdfDoc from "../../icons/IconPdfDoc";

export const DocumentsSection = () => {
  const { t } = useTranslation();

  const handleDownloadDocument = (document: any) => {
    console.log("handleDownloadDocument: ", document);
  }

  const handleDownloadAll = () => {
    console.log("handleDownloadAll")
  }

  return (
    <StyledDocumentsWrapper>
      <StyledTitle>
        {t("documents")}
      </StyledTitle>

      <StyledDocumentsList>
        {DOCUMENTS?.map((item) => (
         <StyledDocumentsListItem>
            <StyledDocumentIconWrapper>
              <IconPdfDoc />
            </StyledDocumentIconWrapper>
   
            <StyledDocumentLink
              onClick={() => handleDownloadDocument(item)}
            >
              {item?.name}
            </StyledDocumentLink>
         </StyledDocumentsListItem> 
        ))}
      </StyledDocumentsList>

      <StyledButtonDownloadAll
        onClick={handleDownloadAll}
      >
        {t("downloadAll")}
      </StyledButtonDownloadAll>

    </StyledDocumentsWrapper>
  );
};
