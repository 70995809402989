import { TableCell } from "@mui/material";
import styled from 'styled-components'
import { mainTheme } from "../../theme"

// Стили для таблицы
export const StyledTableWrapper = styled.div`
  background: ${mainTheme.main.colors.white};
  padding-left: 30px;
  padding-right: 20px;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    display: none;
  }

  .MuiTableCell-head {
    padding-left: 0;
    color: ${mainTheme.main.colors.merin};
    font-size: ${mainTheme.main.fontSizes.small};
    font-weight: ${mainTheme.main.fontWeights.normal};
    font-family: ${mainTheme.main.fonts.dmsRegular};
    text-transform: capitalize;
  }

  .MuiTableRow-root {
    &:hover {
      background: ${mainTheme.main.colors.white} !important;
    }
  }

  .MuiTableCell-body {
    padding-left: 0;
    color: ${mainTheme.main.colors.black};
    font-size: 15px;
    font-weight: ${mainTheme.main.fontWeights.normal};
    font-family: ${mainTheme.main.fonts.dmsRegular};
  }
`

export const StyledSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${mainTheme.main.colors.white};
`

export const StyledSectionColumn = styled.div`
  display: flex;
  justify-content: space-between;
  background: ${mainTheme.main.colors.white};
  padding: 24px 30px 0px 30px;

  &:last-child {
    padding-top: 0;
  }

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    padding: 24px 16px 0px 16px;
    display: flex;
    flex-direction: row;

    &:last-child {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
`

export const StyledTitleSection = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-size: ${mainTheme.main.fontSizes.large};
  font-weight: ${mainTheme.main.fontWeights.bold};
  font-family: ${mainTheme.main.fonts.chapRegular};
  text-transform: uppercase;
`

export const StyledViewAllButton = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-size: 15px;
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  text-decoration: underline;
`

// Стили для ячейки внутри с несколькими ячейками
export const StyledBoxColumn = styled(TableCell)`
  padding-top: 16px;
  &.MuiTableCell-body {
    padding-top: 16px;
    vertical-align: baseline;
  }
`

export const StyledDoubleColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  .MuiTableCell-root {
    border: none;
    padding: 0 0 2px 0;
  }

  &.rowColumn {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: flex-start;
  }
`

export const StyledTableCellBookingCode = styled(TableCell)`
  &.MuiTableCell-body {
    color: ${mainTheme.main.colors.black};
    font-size: 15px;
    font-weight: ${mainTheme.main.fontWeights.normal};
    font-family: ${mainTheme.main.fonts.dmsRegular};
    margin-top: -34px;
    vertical-align: baseline;
  }

  a {
    color: ${mainTheme.main.colors.merin}; 
  }

`

export const StyledTableColumnBox = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledTableCellDay = styled(TableCell)`
  &.MuiTableCell-body {
    color: ${mainTheme.main.colors.blue};
    font-size: 15px;
    font-weight: ${mainTheme.main.fontWeights.normal};
    font-family: ${mainTheme.main.fonts.dmsRegular};
  }
`

export const StyledTableCellTime = styled(TableCell)`
  &.MuiTableCell-body {
    color: ${mainTheme.main.colors.merin};
    font-size: ${mainTheme.main.fontSizes.xsmall};
    font-weight: ${mainTheme.main.fontWeights.normal};
    font-family: ${mainTheme.main.fonts.dmsRegular};
  }
`

export const StyledTimeZone = styled.span`
  padding-left: 4px;
`

export const StyledTableCellNameShip = styled(TableCell)`
  margin-top: -5px;
  &.MuiTableCell-body {
    color: ${mainTheme.main.colors.blue};
    font-size: ${mainTheme.main.fontSizes.xsmall};
    font-weight: ${mainTheme.main.fontWeights.normal};
    font-family: ${mainTheme.main.fonts.interRegular};
    text-decoration: underline;
    text-transform: uppercase;
  }
`

export const StyledTableCellCodeShip = styled(TableCell)`
  &.MuiTableCell-body {
    color: ${mainTheme.main.colors.merin};
    font-size: 11px;
    font-weight: ${mainTheme.main.fontWeights.normal};
    font-family: ${mainTheme.main.fonts.dmsRegular};
  }
`

export const StyledTableCellRouteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 1px solid ${mainTheme.main.colors.grayLigth};
  gap: 0px;
  height: 30px;
  padding-left: 10px;
  margin-top: 6px;
`

export const StyledTableCellRouteStart = styled(TableCell)`
  position: relative;
  &.MuiTableCell-body {
    color: ${mainTheme.main.colors.black};
    font-size: 15px;
    font-weight: ${mainTheme.main.fontWeights.bold};
    font-family: ${mainTheme.main.fonts.dmsRegular};
    margin-top: -6px;
  }
  &:before {
    position: absolute;
    left: -15px;
    top: 5px;
    content: '';
    display: flex;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background: ${mainTheme.main.colors.white};
    border: 2px solid ${mainTheme.main.colors.black};
  }
`

export const StyledTableCellRouteEnd = styled(TableCell)`
  position: relative;
  &.MuiTableCell-body {
    color: ${mainTheme.main.colors.black};
    font-size: 15px;
    font-weight: ${mainTheme.main.fontWeights.bold};
    font-family: ${mainTheme.main.fonts.dmsRegular};
    margin-top: -3px;
  }
  &:before {
    position: absolute;
    left: -15px;
    top: 5px;
    content: '';
    display: flex;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background: ${mainTheme.main.colors.white};
    border: 2px solid ${mainTheme.main.colors.black};
  }
`

export const StyledTabeCellStatus = styled(TableCell)`
  border-radius: 4px;
  background: ${mainTheme.main.colors.blueLigth};
  
  &.MuiTableCell-body {
    padding: 3px 12px;
    color: ${mainTheme.main.colors.blueFantom};
    font-size: ${mainTheme.main.fontSizes.xsmall};
    font-weight: ${mainTheme.main.fontWeights.boldExtra};
    font-family: ${mainTheme.main.fonts.dmsRegular};
  }
`

export const StyledTableCellRoomNumber = styled(TableCell)`
  &.MuiTableCell-body {
    color: ${mainTheme.main.colors.black};
    font-size: 15px;
    font-weight: ${mainTheme.main.fontWeights.normal};
    font-family: ${mainTheme.main.fonts.dmsRegular};
  }
`

export const StyledTableCellRoomPlace = styled(TableCell)`
  background: #e0e8eb;
  border-radius: 4px;
  padding: 2px 6px;
  margin-bottom: 6px;
  &.MuiTableCell-body {
    padding: 3px 12px;
    color: ${mainTheme.main.colors.merin};
    font-size: ${mainTheme.main.fontSizes.xsmall};
    font-weight: ${mainTheme.main.fontWeights.normal};
    font-family: ${mainTheme.main.fonts.dmsRegular};
  }
`

export const StyledTableCellPassenger = styled(TableCell)`
  &.MuiTableCell-body {
    color: ${mainTheme.main.colors.black};
    font-size: 15px;
    font-weight: ${mainTheme.main.fontWeights.normal};
    font-family: ${mainTheme.main.fonts.dmsRegular};
    vertical-align: baseline;
    text-transform: none;
  }
`

export const StyledTableCellPrice = styled(TableCell)`
  &.MuiTableCell-body {
    color: ${mainTheme.main.colors.black};
    font-size: 15px;
    font-weight: ${mainTheme.main.fontWeights.normal};
    font-family: ${mainTheme.main.fonts.dmsRegular};
    vertical-align: baseline;
  }
`

export const StyledTableCellPriceColumn = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  flex-wrap: nowrap;
  box-sizing: border-box;
`

export const StyledTableCellPaymentTotal = styled(TableCell)`
  &.MuiTableCell-body {
    color: ${mainTheme.main.colors.black};
    font-size: 15px;
    font-weight: ${mainTheme.main.fontWeights.normal};
    font-family: ${mainTheme.main.fonts.dmsRegular};
    vertical-align: baseline;
  }
`

export const StyledTableCellPaymentCode = styled(TableCell)`
  &.MuiTableCell-body {
    color: ${mainTheme.main.colors.merin};
    font-size: ${mainTheme.main.fontSizes.xsmall};
    font-weight: ${mainTheme.main.fontWeights.normal};
    font-family: ${mainTheme.main.fonts.dmsRegular};
    vertical-align: baseline;
  }
`

export const StyledTableCellStatusName = styled(TableCell)`
  padding: 4px 6px;
  border-radius: 4px;
  &.MuiTableCell-body {
    color: ${mainTheme.main.colors.merin};
    font-size: ${mainTheme.main.fontSizes.xsmall};
    font-weight: ${mainTheme.main.fontWeights.normal};
    font-family: ${mainTheme.main.fonts.interRegular};
    vertical-align: baseline;
  }
  &.paid {
    background: #A5FC8F;
    color: #088905;
    padding: 4px 6px;
    border-radius: 4px;
  }
  &.unpaid {
    background: #FCDBFF;
    color: #971CA1;
    padding: 4px 6px;
    border-radius: 4px;
  }
`

export const StyledTableCellStatusPrice = styled(TableCell)`
  &.MuiTableCell-body {
    color: ${mainTheme.main.colors.black};
    font-size: 15px;
    font-weight: ${mainTheme.main.fontWeights.normal};
    font-family: ${mainTheme.main.fonts.dmsRegular};
    vertical-align: baseline;
  }
`

export const StyledTableCellStatusTitle = styled(TableCell)`
  &.MuiTableCell-body {
    color: ${mainTheme.main.colors.merin};
    font-size: ${mainTheme.main.fontSizes.xsmall};
    font-weight: ${mainTheme.main.fontWeights.normal};
    font-family: ${mainTheme.main.fonts.dmsRegular};
    vertical-align: baseline;
    padding: 4px 6px;
    border-radius: 4px;
  }

  &.NOT_PAID {
    background: #fde3eb;
    color: #9E2044;
  }

  &.PAID {
    background: #A5FC8F;
    color: #088905;
  }

  &.PARTIALLY {
    background: #E3F097;
    color: #5C732C;
  }

  &.OVERPAID {
    background: #FCDBFF;
    color: #971CA1;
  }
`

export const StyledPreloaderWrapper = styled.div`
  color: ${mainTheme.main.colors.merin};
  font-size: ${mainTheme.main.fontSizes.medium};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  padding-left: 30px;
  padding-top: 12px;
`

export const StyledPaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 34px;

  .MuiButtonBase-root {
    background: ${mainTheme.main.colors.white};
    margin: 0;
    color: ${mainTheme.main.colors.blue};
    font-size: ${mainTheme.main.fontSizes.medium};
    font-weight: ${mainTheme.main.fontWeights.normal};
    font-family: ${mainTheme.main.fonts.dmsRegular};
    width: 42px;
    height: 35px;
    border-radius: 0px;
    border: 1px solid ${mainTheme.main.colors.graySilver};

    &.Mui-selected {
      background: ${mainTheme.main.colors.blue};
      color: ${mainTheme.main.colors.white};
      border: 1px solid ${mainTheme.main.colors.blue};
    }
  }

  @media (max-width: ${mainTheme.main.breakPoints.mobile}){
    display: none;
  }
`