import { useTranslation } from "react-i18next";
import { BOOKING_DETAILS_INFO_DATA } from "./consts";
import {
  StyledBookingDetailsInfoWrapper,
  StyledBookingDetailsInfoRow,
  StyledBookingDetailsInfoColumn,
  StyledBookingDetailsInfoHeaderColumn,
  StyledBookingDetailsInfoColumnContent,
} from "./BookingDetailsInfoMobile.styles";

export const BookingDetailsInfoMobile = () => {
  const { t } = useTranslation();

  return (
    <StyledBookingDetailsInfoWrapper>
      <>
        <StyledBookingDetailsInfoRow>
          <StyledBookingDetailsInfoColumn>
            <StyledBookingDetailsInfoHeaderColumn>
              {t("bookingCode")}
            </StyledBookingDetailsInfoHeaderColumn>
            <StyledBookingDetailsInfoColumnContent className="code">
              {BOOKING_DETAILS_INFO_DATA.bookingCode.value}
            </StyledBookingDetailsInfoColumnContent>

            <StyledBookingDetailsInfoColumnContent className="status">
              {BOOKING_DETAILS_INFO_DATA.status.value}
            </StyledBookingDetailsInfoColumnContent>
          </StyledBookingDetailsInfoColumn>
        </StyledBookingDetailsInfoRow>

        <StyledBookingDetailsInfoRow className="row">
          <StyledBookingDetailsInfoColumn>
            <StyledBookingDetailsInfoHeaderColumn>
              {t("bookedAt")}
            </StyledBookingDetailsInfoHeaderColumn>
            <StyledBookingDetailsInfoColumnContent>
              {BOOKING_DETAILS_INFO_DATA.bookedAt.value}
            </StyledBookingDetailsInfoColumnContent>
          </StyledBookingDetailsInfoColumn>

          <StyledBookingDetailsInfoColumn className="last">
            <StyledBookingDetailsInfoHeaderColumn>
              {t("bookingTimeLimit")}
            </StyledBookingDetailsInfoHeaderColumn>
            <StyledBookingDetailsInfoColumnContent className="last timeLimit">
              {BOOKING_DETAILS_INFO_DATA.bookingTimeLimit.value}
            </StyledBookingDetailsInfoColumnContent>
          </StyledBookingDetailsInfoColumn>
        </StyledBookingDetailsInfoRow>
      </>
    </StyledBookingDetailsInfoWrapper>
  );
};
