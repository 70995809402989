export const HEAD_CEALS = [
  {
    id: "bookingCode",
    numeric: false,
    disablePadding: true,
    label: "Booking code",
  },
  {
    id: "dateBooking",
    numeric: true,
    disablePadding: false,
    label: "Date / Time",
  },
  {
    id: "shipRoute",
    numeric: true,
    disablePadding: false,
    label: "Ship / Route",
  },
  {
    id: "stateroom",
    numeric: true,
    disablePadding: false,
    label: "Stateroom",
  },
  {
    id: "passengers",
    numeric: true,
    disablePadding: false,
    label: "Passengers",
  },
  {
    id: "price",
    numeric: true,
    disablePadding: false,
    label: "Price",
  },
  {
    id: "paymentSchedule",
    numeric: true,
    disablePadding: false,
    label: "Payment schedule",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
];
