import { useTranslation } from "react-i18next";
import { AccordionBooking } from "../AccordionBooking";
import {
  AccordionWrapper,
  StyledDate,
  StyledPaymentInfo,
  StyledPaymentInfoItem,
  StyledPaymentInfoItemColumn,
  StyledPaymentScheduleWrapper,
  StyledPrice,
  StyledStatusPay,
} from "./PaymentSchedule.styles";
import { PAYMENT_SCHEDULE } from "./consts";

export const PaymentSchedule = () => {
  const { t } = useTranslation();

  return (
    <StyledPaymentScheduleWrapper>
      <AccordionWrapper>
        <AccordionBooking title={t("paymentSchedule")}>
          <StyledPaymentInfo>
            {PAYMENT_SCHEDULE?.map((item) => (
              <StyledPaymentInfoItem>
                <StyledPaymentInfoItemColumn>
                  <StyledPrice>
                    {item?.price}
                  </StyledPrice>

                  <StyledDate>
                    {item?.date}
                  </StyledDate>
                </StyledPaymentInfoItemColumn>

                <StyledPaymentInfoItemColumn>
                  <StyledStatusPay>
                    {item?.status}
                  </StyledStatusPay>
                </StyledPaymentInfoItemColumn>
              </StyledPaymentInfoItem>
            ))}
          </StyledPaymentInfo>
        </AccordionBooking>
      </AccordionWrapper>
    </StyledPaymentScheduleWrapper>
  );
};
