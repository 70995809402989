export const MESSAGE =
  "Your booking request is currently being processed, please wait. We will contact you shortly. Payment will be available after your booking is confirmed.";

export const PRICE_DETAILS = [
  {
    nameSection: "Cabins",
    price: "53,0000 $",
    sales: [],
  },
  {
    nameSection: "Additional services",
    price: "4,700 $",
    sales: [],
  },
  {
    nameSection: "Total discounts",
    price: "-2,050 $",
    sales: [
      {
        name: "Promotional",
        type: "10%",
        price: "-1,000 $",
      },
      {
        name: "Loyalty member",
        type: "Tier 2",
        price: "-700 $",
      },
      {
        name: "Promocode",
        type: "SHquerty",
        price: "-350 $",
      },
    ],
  }
];
