import { Button } from '@mui/material'
import styled from 'styled-components'
import { mainTheme } from "../../theme"

export const StyledAdditionalServicesWrapper = styled.div`
  background: ${mainTheme.main.colors.white};
  padding: 16px;
  box-shadow: 0px 3px 14px -6px rgba(0,0,0,0.40);
  margin-top: 12px;
`

export const StyledAdditionalServicesSectionTitle = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-weight: ${mainTheme.main.fontWeights.bold};
  font-family: ${mainTheme.main.fonts.chapRegular};
  font-size: ${mainTheme.main.fontSizes.large};
  margin-bottom: 14px;
  text-transform: uppercase;
`

export const StyledAdditionalServiceItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const StyledAdditionalServiceTypeName = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-weight: ${mainTheme.main.fontWeights.bold};
  font-family: ${mainTheme.main.fonts.chapRegular};
  font-size: ${mainTheme.main.fontSizes.large};
  margin-top: 24px;
  margin-bottom: 20px;
`

export const StyledAdditionalServiceList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const StyledAdditionalServiceItemColumns = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(auto, 1fr));
  gap: 20px; 
  width: 100%;
  border-bottom: 1px solid ${mainTheme.main.colors.graySilver};;
`

export const StyledAdditionalServiceItemColumn = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  font-size: ${mainTheme.main.fontSizes.medium};
  
  &.name {
    text-decoration: underline;
  }

  &.last {
    text-align: right;
  }
`

export const StyledButtonDelete = styled(Button)`
  &.MuiButtonBase-root {
    background: ${mainTheme.main.colors.white};
    border-radius: 0px;
    &:hover {
      background: ${mainTheme.main.colors.white};
    }
  }
`

export const StyledModalContent = styled.div`
  color: ${mainTheme.main.colors.black};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  font-size: ${mainTheme.main.fontSizes.medium};
`

export const StyledButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 30px;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;

export const StyledButtonSave = styled(Button)`
  &.MuiButton-root {
    border-radius: 24px;
    color: ${mainTheme.main.colors.white};
    font-size: ${mainTheme.main.fontSizes.large};
    font-weight: ${mainTheme.main.fontWeights.normal};
    font-family: ${mainTheme.main.fonts.dmsRegular};
    background: ${mainTheme.main.colors.blue};
    width: 153px;
    border: 1px solid ${mainTheme.main.colors.blue};
    text-transform: capitalize;
    box-shadow: none;
    padding: 4px;

    &:hover {
      background: ${mainTheme.main.colors.blue};
    }

    @media (max-width: ${mainTheme.main.breakPoints.mobile}){
      width: 100%;
    }
  }
`;

export const StyledButtonCancel = styled(Button)`
  &.MuiButton-root {
    border-radius: 20px;
    color: ${mainTheme.main.colors.black};
    font-size: ${mainTheme.main.fontSizes.medium};
    font-weight: ${mainTheme.main.fontWeights.normal};
    background: ${mainTheme.main.colors.white};
    width: 138px;
    border: 1px solid ${mainTheme.main.colors.graySilver};
    box-shadow: none;

    &:hover {
      background: ${mainTheme.main.colors.white};
    }

    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
      width: 100%;
    }
  }
`;

export const StyledModalContentTwoColumns = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, auto) minmax(120px, auto);
  gap: 40px;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
`