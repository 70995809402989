import styled from 'styled-components'
import { mainTheme } from "../../theme"

export const StyledCruiseWrapper = styled.div`
  gap: 12px;
  background: ${mainTheme.main.colors.white};
  padding: 16px;
  box-shadow: 0px 3px 14px -6px rgba(0,0,0,0.40);
  margin-top: 12px;
`

export const StyledCruiseHeaderColumns = styled.div``

export const StyledSectionTitle = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-weight: ${mainTheme.main.fontWeights.bold};
  font-family: ${mainTheme.main.fonts.chapRegular};
  font-size: ${mainTheme.main.fontSizes.large};
  margin-bottom: 14px;
`

export const StyledCruiseHeaderColumn = styled.div`
  color: ${mainTheme.main.colors.merin};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  font-size: ${mainTheme.main.fontSizes.small};
`

export const StyledCruiseColumns = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 20px; 
`

export const StyledCruiseColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 12px;
`

export const StyledCruiseColumnContent = styled.div`
  color: ${mainTheme.main.colors.black};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  font-size: ${mainTheme.main.fontSizes.medium};

  &.ship {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
  }

`

export const StyledNameShip = styled.div`
  text-decoration: underline;
  color: ${mainTheme.main.colors.black};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  font-size: ${mainTheme.main.fontSizes.medium};
`

export const StyledCruiseColumnWrapper = styled.div``

export const StyledRoutesColumns = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const StyledRouteItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-bottom: 8px;

  &::before {
    position: absolute;
    left: -19px;
    top: 5px;
    content: "";
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: ${mainTheme.main.colors.white};
    border: 2px solid ${mainTheme.main.colors.blue};
    z-index: 999;
  }

  &::after {
    content: "";
    position: absolute;
    height: 5rem;
    width: 1px;
    background: ${mainTheme.main.colors.grayLigth};;
    display: block;
    left: -15px;
    top: 5px;
  }

  &:last-child {
    &::after {
      display: none;
    }
  }
`

export const StyledRouteName = styled.div`
  font-size: ${mainTheme.main.fontSizes.medium};
  font-weight: ${mainTheme.main.fontWeights.bold};
  color: ${mainTheme.main.colors.black};
  font-family: ${mainTheme.main.fonts.dmsRegular};
`

export const StyledRouteDate = styled.div`
  font-size: ${mainTheme.main.fontSizes.xsmall};
  font-weight: ${mainTheme.main.fontWeights.normal};
  color: ${mainTheme.main.colors.merin};
  font-family: ${mainTheme.main.fonts.dmsRegular};
`

export const StyledRoutePort = styled.div`
  font-size: ${mainTheme.main.fontSizes.xsmall};
  font-weight: ${mainTheme.main.fontWeights.normal};
  color: ${mainTheme.main.colors.merin};
  font-family: ${mainTheme.main.fonts.dmsRegular};
`

export const StyledRouteLink = styled.div`
  margin-bottom: 8px;
  font-size: ${mainTheme.main.fontSizes.medium};
  font-weight: ${mainTheme.main.fontWeights.normal};
  color: ${mainTheme.main.colors.blue};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  text-decoration: underline;
`
export const StyledWrapperModal = styled.div``