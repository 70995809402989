import { Button } from '@mui/material'
import styled from 'styled-components'
import { mainTheme } from "../../theme"

export const StyledCommentsWrapper = styled.div`
  background: ${mainTheme.main.colors.white};
  padding: 16px;
  box-shadow: 0px 3px 14px -6px rgba(0,0,0,0.40);
  margin-top: 12px;
`

export const StyledCommetHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`

export const StyledCommetsTitle = styled.div``

export const StyledEditButton = styled(Button)`
  &.MuiButtonBase-root {
    background: ${mainTheme.main.colors.white};
    border-radius: 0px;
    min-width: auto;
    margin: 0;
    padding: 0;
  }
`

export const StyledCommentsText = styled.div``