import { useState } from "react";
import { FormAuth } from "../FormAuth";
import { StyledAuthWrapper } from "./Auth.styles";
import { CurrentEditFormType } from "../../utils/globalTypes";
import { ModalFormEdit } from "../ModalFormEdit";
import { TypeFormEnum } from "../ModalFormEdit/types";
import { AuthProps } from "./types";

export const Auth = ({ setIsAuthUser }: AuthProps) => {
  const [currentEditForm, setCurrentEditForm] = useState<CurrentEditFormType>({
    title: "",
    typeEditForm: "",
  });

  return (
    <StyledAuthWrapper>
      <FormAuth
        setIsAuthUser={(value) => setIsAuthUser(value)}
        setCurrentEditForm={(value: any) => setCurrentEditForm(value)}
      />

      {/* Модальное окно в котором формы для редактирования данных */}
      <ModalFormEdit
        title={currentEditForm.title}
        typeForm={TypeFormEnum.registration}
        currentEditForm={currentEditForm}
        setCurrentEditForm={(value) => setCurrentEditForm(value)}
      />
    </StyledAuthWrapper>
  );
};
