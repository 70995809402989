import { t } from "i18next";
import { TravellersListMobileProps } from "./types";
import IconEdit from "../../icons/IconEdit";
import { TypeFormEnum } from "../ModalFormEdit/types";
import {
  StyledIconEditWrapper,
  StyledTravellersList,
  StyledTravellersListBox,
  StyledTravellersListItem,
  StyledTravellersListItemColumnFirst,
  StyledTravellersListItemColumnFirstName,
  StyledTravellersListItemColumnLast,
  StyledTravellersListItemColumnLastData,
} from "./TravellersListMobile.styles";

export const TravellersListMobile = ({
  travellers,
  setCurrentEditForm,
  setCurrentTraveller,
}: TravellersListMobileProps) => {

  const handleClickEdit = (traveller: any) => {
    setCurrentEditForm({
      title: "traveller",
      typeEditForm: TypeFormEnum.traveller,
    })

    if (traveller) {
      setCurrentTraveller(traveller);
    }
  };

  return (
    <StyledTravellersList>
      {travellers?.map((item: any, index: number) => (
        <StyledTravellersListBox>
          <StyledTravellersListItem>
            <StyledTravellersListItemColumnFirst>
              <StyledTravellersListItemColumnFirstName>
                {t("fullName")}
              </StyledTravellersListItemColumnFirstName>
              <StyledTravellersListItemColumnLastData>
                {item?.fullName}
              </StyledTravellersListItemColumnLastData>
            </StyledTravellersListItemColumnFirst>

            <StyledTravellersListItemColumnLast>
              <StyledTravellersListItemColumnLastData>
                <StyledIconEditWrapper onClick={() => handleClickEdit(item)}>
                  <IconEdit />
                </StyledIconEditWrapper>
              </StyledTravellersListItemColumnLastData>
            </StyledTravellersListItemColumnLast>
          </StyledTravellersListItem>

          <StyledTravellersListItem>
            <StyledTravellersListItemColumnFirst>
              <StyledTravellersListItemColumnFirstName>
                {t("passport")}
              </StyledTravellersListItemColumnFirstName>
              <StyledTravellersListItemColumnLastData>
                {item?.passport}
              </StyledTravellersListItemColumnLastData>
            </StyledTravellersListItemColumnFirst>

            <StyledTravellersListItemColumnLast>
              <StyledTravellersListItemColumnFirstName>
                {t("loyaltyNumber")}
              </StyledTravellersListItemColumnFirstName>
              <StyledTravellersListItemColumnLastData>
                {item?.loyaltyNumber}
              </StyledTravellersListItemColumnLastData>
            </StyledTravellersListItemColumnLast>
          </StyledTravellersListItem>

          <StyledTravellersListItem>
            <StyledTravellersListItemColumnFirst>
              <StyledTravellersListItemColumnFirstName>
                {t("phone")}
              </StyledTravellersListItemColumnFirstName>
              <StyledTravellersListItemColumnLastData>
                {item?.phone}
              </StyledTravellersListItemColumnLastData>
            </StyledTravellersListItemColumnFirst>

            <StyledTravellersListItemColumnLast>
              <StyledTravellersListItemColumnFirstName>
                {t("email")}
              </StyledTravellersListItemColumnFirstName>
              <StyledTravellersListItemColumnLastData>
                {item?.email}
              </StyledTravellersListItemColumnLastData>
            </StyledTravellersListItemColumnLast>
          </StyledTravellersListItem>
        </StyledTravellersListBox>
      ))}
    </StyledTravellersList>
  );
};
