import { useEffect, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { t } from "i18next";
import { BookingsListMobile } from "../../components/BookingsListMobile";
import { Search } from "../../components/Search";
import { TypeFormEnum } from "../../components/ModalFormEdit/types";
import { ModalFormEdit } from "../../components/ModalFormEdit";
import { CurrentEditFormType, OrderListType, PaginationType } from "../../utils/globalTypes";
import { ORDER_LIST } from "../../appolo/graphql/queries/orderList";
import { BookingsTable } from "../../components/BookingsTable";
import {
  StyledAddNew,
  StyledAddNewWrapper,
  StyledBookingsWrapper,
  StyledHeader,
  StyledSearchWrapper,
  StyledTitleSection,
} from "./Bookings.styles";

export const Bookings = () => {
  const [searchValue, setSearchValue] = useState("");

  const [currentEditForm, setCurrentEditForm] = useState<CurrentEditFormType>({
    title: "",
    typeEditForm: "",
  });

  const handleAddBooking = () => {
    setCurrentEditForm({
      title: "booking",
      typeEditForm: TypeFormEnum.newBoking,
    })
  };

  // Количество записей в таблице бронирования (по-умолчанию)
  const countViewBooking = 5;

  // Для таблицы Bookings
  const [getBookings, { data: orderListData, loading: orderListDataLoading }] = useLazyQuery(ORDER_LIST);

  const paginationTableBookings: PaginationType = orderListData?.orderList.pagination;

  const [currentPage, setCurrentPage] = useState(paginationTableBookings?.currentPage ?? 1);

  useEffect(() => {
    getBookings({
      fetchPolicy: 'network-only',
      variables: {
        perPage: countViewBooking,
        page: currentPage, // если переходить дальше чем на 2-ю страницу метод падает
        context: {
          market: "ru",
        },
      }
    })
  }, [currentPage]);

  // Показать все записи в таблице
  const handleViewAll = () => {
    getBookings({
      fetchPolicy: 'network-only',
      variables: {
        perPage: paginationTableBookings?.totalCount, // сейчас метод падает если указать получить все записи
        page: 1,
        context: {
          market: "ru",
        },
      }
    })
  };

  console.log("searchValue: ", searchValue)

  return (
    <StyledBookingsWrapper>
      <StyledHeader>
        <StyledTitleSection>
          {t("bookings")}
        </StyledTitleSection>
        
        <StyledSearchWrapper>
          <Search
            label={t("search")}
            value={searchValue}
            setValue={(value) => setSearchValue(value)}
          />
        </StyledSearchWrapper>

      </StyledHeader>

      {/* Таблица с бронированиями (только desktop) */}
      <BookingsTable 
        bookings={orderListData?.orderList.list ?? []} 
        setCurrentPage={(value: number) => setCurrentPage(value)}
        pageCount={paginationTableBookings?.pageCount}
        orderListDataLoading={orderListDataLoading}
      />

      {/* Бронирования (только mobile) */}
      <BookingsListMobile 
        bookings={orderListData?.orderList.list ?? []} 
        setCurrentPage={(value: number) => setCurrentPage(value)}
        pageCount={paginationTableBookings?.pageCount}
        orderListDataLoading={orderListDataLoading}
      />

      {/* <StyledAddNewWrapper>
        <StyledAddNew onClick={handleAddBooking}>
          {t("addNewBooking")}
        </StyledAddNew>
      </StyledAddNewWrapper> */}

      {/* Модальное окно в котором формы для редактирования данных */}
      <ModalFormEdit
        title={currentEditForm.title}
        typeForm={TypeFormEnum.newBoking}
        currentEditForm={currentEditForm}
        setCurrentEditForm={(value) => setCurrentEditForm(value)}
      />
    </StyledBookingsWrapper>
  );
};
