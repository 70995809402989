import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  StyledCruiseColumn,
  StyledCruiseColumnContent,
  StyledCruiseColumns,
  StyledCruiseColumnWrapper,
  StyledCruiseHeaderColumn,
  StyledCruiseWrapper,
  StyledNameShip,
  StyledRouteDate,
  StyledRouteItem,
  StyledRouteLink,
  StyledRouteName,
  StyledRoutePort,
  StyledRoutesColumns,
  StyledSectionTitle,
} from "./Cruise.styles";
import { CRUISE_DATA } from "./consts";
import { IconLoylaty } from "../../icons/IconLoylaty";
import { ModalInfo } from "../ModalInfo";
import { CruisesTable } from "../CruisesTable";
import { CRUISES_DATA } from "../CruisesTable/consts";


export const Cruise = () => {
  const { t } = useTranslation();

  const [openModalCruise, setOpenModalCruise] = useState(false);

  return (
    <StyledCruiseWrapper>
      <StyledSectionTitle>
        {t("cruise")}   
      </StyledSectionTitle>
      <StyledCruiseColumns>
        <StyledCruiseColumnWrapper>
          <StyledCruiseColumn>
            <StyledCruiseHeaderColumn>
              {t("cruiseCode")}
            </StyledCruiseHeaderColumn>

            <StyledCruiseColumnContent>
              {CRUISE_DATA.cruiseCode.value}
            </StyledCruiseColumnContent>
          </StyledCruiseColumn>

          <StyledCruiseColumn>
            <StyledCruiseHeaderColumn>
              {t("cruiseName")}
            </StyledCruiseHeaderColumn>
            <StyledCruiseColumnContent>
              {CRUISE_DATA.cruiseName.value}
            </StyledCruiseColumnContent>
          </StyledCruiseColumn>
        </StyledCruiseColumnWrapper>

        <StyledCruiseColumnWrapper>
          <StyledCruiseColumn>
            <StyledCruiseHeaderColumn>
              {t("ship")}
            </StyledCruiseHeaderColumn>
            <StyledCruiseColumnContent className="ship">
              <IconLoylaty /> 
              <StyledNameShip>
                {CRUISE_DATA.ship.value}
              </StyledNameShip>
            </StyledCruiseColumnContent>
          </StyledCruiseColumn>

          <StyledCruiseColumn>
            <StyledCruiseHeaderColumn>
              {t("boardingTime")}
            </StyledCruiseHeaderColumn>
            <StyledCruiseColumnContent>
              {CRUISE_DATA.boardingTime.value}
            </StyledCruiseColumnContent>
          </StyledCruiseColumn>
        </StyledCruiseColumnWrapper>

        <StyledRoutesColumns>
          <StyledRouteItem>
            <StyledRouteName>
              {CRUISE_DATA.route.startPort}
            </StyledRouteName>
            <StyledRouteDate>
              {CRUISE_DATA.route.date[0].day}
            </StyledRouteDate>
            <StyledRoutePort>
              {CRUISE_DATA.route.date[0].port}
            </StyledRoutePort>
          </StyledRouteItem>

          <StyledRouteLink
            onClick={() => setOpenModalCruise(true)}
          >
            Route
          </StyledRouteLink>

          <StyledRouteItem>
            <StyledRouteName>
              {CRUISE_DATA.route.startPort}
            </StyledRouteName>
            <StyledRouteDate>
              {CRUISE_DATA.route.date[1].day}
            </StyledRouteDate>
            <StyledRoutePort>
              {CRUISE_DATA.route.date[1].port}
            </StyledRoutePort>
          </StyledRouteItem>
        </StyledRoutesColumns>
      </StyledCruiseColumns>

      {/* Модальное окно "Details" */}
      <ModalInfo
        title="cruise"
        open={openModalCruise}
        setOpen={(value: boolean) => setOpenModalCruise(value)}
        size="1200px"
      >
        <CruisesTable 
          cruises={CRUISES_DATA}
        />
      </ModalInfo>
    </StyledCruiseWrapper>
  );
};
