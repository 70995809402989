import { TypeFormEnum } from "../components/ModalFormEdit/types"

export type CurrentEditFormType = {
  title: string,
  typeEditForm: TypeFormEnum | string
}

export type CountriesListType = {
  value: string
}

export type CitiesListType = {
  value: string
}

export type OrderListItem = {
  bookedTimeLeft: number
  bookedTo: string
  cancellationConditions: string
  companyComment: string
  createdAt: string
  cruises: [
    {
      cruiseId: number
      cabins: [
        {
          id: number
          number: string
          deck: string
          roomClass: string
        }
      ]
      dateEndRaw: string
      dateStartRaw: string
      destinationPort: {
        cityName: string
        portId: number
        portName: string
      }
      destinations: [
        {
          id: number
          name: string
        }
      ]
      duration: number
      isGroupOrder: boolean
      motorshipId: number
      motorshipName: string
      name: string
      originPort: {
        cityName: string
        portId: number
        portName: string
      }
      position: number
      reservationKind: string
      services: any
    }
  ]
  currency: string
  isCancelable: boolean
  isPayable: boolean
  isRefundable: boolean
  managerComment: string
  market: any
  number: string
  paymentStatus: PaymentStatusEnum
  price: {
    amount: number
    currency: string
  }
  refundStatus: string
  source: any
  status: string
  uuid: string
  travellers: TravellerType[]
  paymentSchedule: PaymentScheduleType[]
  refunds: RefundType[]
  payments: PaymentType[]
  owner: UserType
  responsible: UserType
  customer: UserType
  paymentsTotal: number
  paymentLink: string
  document: DocumentType[]
}

export type TravellerType = {
  id: number
  reservations: {
    cruiseId: number
    cabinId: number
    placeId: number
    room: string
    roomClass: string
    tariffName: string
  }
  firstname: string
  lastname: string
  middlename: string
  hasNoMiddlename: boolean
  gender: any
  dob: string
  contactPhone: {
    code: string
    number: string
  }
  contactEmail: string
  citizenship: number
  document: DocumentType
}

export type RefundType =  {
  number: string
  orderUuid: string
  id: number
  status: any
  compensation: number
  amountTotal: number
  amountOnline: number
  amountOffline: number
  isTotal: boolean
  penalty: number
  reason: number
  comment: string
  positions: [{
    position: number
    type: number
    name: string
    amount: number
    items: any
  }]
  createdAt: string
}

export type UserType = {
  id: number
  firstname: string
  lastname: string
  middlename: string
  fullname: string
  hasNoMiddlename: boolean
  gender: any
  dob: string
  contactPhone: {
    code: string
    number: string
  }
  contactEmail: string
  bonusCardNumber: string
  document: DocumentType
  company: {
    id: number
    name: string
    longName: string
    inn: string
    email: string
    phone: {
      code: string
      number: string
    }
  }
}

export type PaymentType = {
  id: number
  orderUuid: string
  status: any
  amount: number
  method: any
  comment: string
  createdAt: string
}

export type DocumentType = {
  link: string
  name: string
  kind: any
}

export type PaymentScheduleType = {
  dueDate: string
  actualDate: string
  amount: {
    amount: number
    currency: string
  }
  isPaid: boolean
  isOverdue: boolean
}

export type OrderListType = {
  orderList: {
    list: OrderListItem[]
    pagination: PaginationType
  }
}

export type PaginationType = {
  currentPage: number
  pageCount: number
  perPage: number
  totalCount: number
}

export enum PaymentStatusEnum {
  notPaid = "NOT_PAID",
  partially = "PARTIALLY",
  paid = "PAID",
  overpaid = "OVERPAID"
}

export enum CurrencyEnum {
  RUB = "RUB",
  EUR = "EUR",
  GBP = "GBP",
  USD = "USD"
}