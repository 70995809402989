export const IconDelete = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
  >
    <path
      fill="#004155"
      fillRule="evenodd"
      d="M15.812 6H4.187l.696 11.125A2 2 0 0 0 6.879 19h6.242a2 2 0 0 0 1.996-1.875L15.812 6Zm-8.624 7.905a.641.641 0 1 0 .907.907L10 12.907l1.905 1.905a.641.641 0 1 0 .907-.907L10.907 12l1.905-1.905a.641.641 0 1 0-.907-.907L10 11.093 8.095 9.188a.641.641 0 1 0-.907.907L9.093 12l-1.905 1.905ZM17 4a1 1 0 0 0-1-1h-2V2a2 2 0 0 0-2-2H8a2 2 0 0 0-2 2v1H4a1 1 0 0 0 0 2h12a1 1 0 0 0 1-1Zm-5-1H8V2h4v1Z"
      clipRule="evenodd"
    />
  </svg>
)

