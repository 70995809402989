export const CRUISES_DATA = [
  {
    day: 2,
    date: "6 August 2023",
    country: "Iceland",
    location: "Reykjavik, port Reykjavik",
    arriveDate: "08:00 am",
    arriveTime: "UTC + 0",
    duration: "10h",
    departDate: "06:00 pm",
    departTime: "UTC + 0",
  },
  {
    day: 3,
    date: "16 August 2023",
    country: "Iceland",
    location: "Reykjavik, port Reykjavik",
    arriveDate: "08:00 am",
    arriveTime: "UTC + 0",
    duration: "10h",
    departDate: "06:00 pm",
    departTime: "UTC + 0",
  },
  {
    day: 5,
    date: "20 August 2023",
    country: "Iceland",
    location: "Reykjavik, port Reykjavik",
    arriveDate: "08:00 am",
    arriveTime: "UTC + 0",
    duration: "10h",
    departDate: "06:00 pm",
    departTime: "UTC + 0",
  }
]

export const HEAD_CEALS = [
  {
    id: "day",
    numeric: false,
    disablePadding: true,
    label: "Day",
  },
  {
    id: "date",
    numeric: true,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "country",
    numeric: true,
    disablePadding: false,
    label: "Country",
  },
  {
    id: "location",
    numeric: true,
    disablePadding: false,
    label: "Location",
  },
  {
    id: "arrive",
    numeric: true,
    disablePadding: false,
    label: "Arrive",
  },
  {
    id: "duration",
    numeric: true,
    disablePadding: false,
    label: "Duration",
  },
  {
    id: "depart",
    numeric: true,
    disablePadding: false,
    label: "Depart",
  },
];
