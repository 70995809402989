import { Button } from '@mui/material'
import styled from 'styled-components'
import { mainTheme } from "../../theme"

export const StyledDocumentsWrapper = styled.div`
  background: ${mainTheme.main.colors.white};
  padding: 12px;
  margin-top: 12px;
`

export const StyledTitle = styled.div`
  font-size: ${mainTheme.main.fontSizes.large};
  font-weight: ${mainTheme.main.fontWeights.boldExtra};
  color: ${mainTheme.main.colors.black};
  font-family: ${mainTheme.main.fonts.chapRegular};
`

export const StyledDocumentsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;
`

export const StyledDocumentsListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`

export const StyledDocumentIconWrapper = styled.div``

export const StyledDocumentLink = styled.div`
  text-decoration: underline;
  font-size: ${mainTheme.main.fontSizes.medium};
  font-weight: ${mainTheme.main.fontWeights.normal};
  color: ${mainTheme.main.colors.blue};
  font-family: ${mainTheme.main.fonts.dmsRegular};
`

export const StyledButtonDownloadAll = styled(Button)`
  &.MuiButtonBase-root {
    background: ${mainTheme.main.colors.white};
    border-radius: 24px;
    border: 1px solid ${mainTheme.main.colors.blue};
    color: ${mainTheme.main.colors.blue};
    font-size: 16px;
    font-family: ${mainTheme.main.fonts.dmsRegular};
    text-transform: none;
    width: 100%;
    padding: 2px;
    margin-top: 16px;
  }
`