import { useState } from "react";
import { t } from "i18next";
import { Search } from "../../components/Search";
import { TravellersTable } from "../../components/TravellersTable";
import { TRAVELLERS_TEST_DATA } from "../../components/TravellersTable/consts";
import { TypeFormEnum } from "../../components/ModalFormEdit/types";
import {
  StyledAddNew,
  StyledAddNewWrapper,
  StyledSearchWrapper,
  StyledTableTravellersWrapper,
  StyledTravellersHeader,
  StyledTravellersListMobileWrapper,
  StyledTravellersMain,
  StyledTravellersTitle,
  StyledTravellersWrapper,
} from "./Travellers.styles";
import { ModalFormEdit } from "../../components/ModalFormEdit";
import { TravellersListMobile } from "../../components/TravellersListMobile";
import { CurrentEditFormType } from "../../utils/globalTypes";


export const Travellers = () => {
  const [searchValue, setSearchValue] = useState("");
  const [currentTraveller, setCurrentTraveller] = useState(null);

  const [currentEditForm, setCurrentEditForm] = useState<CurrentEditFormType>({
    title: "",
    typeEditForm: "",
  });

  const handleEditTraveller = () => {
    setCurrentEditForm({
      title: "traveller",
      typeEditForm: TypeFormEnum.traveller,
    })
  };

  console.log("searchValue: ", searchValue);
  console.log("currentTraveller: ", currentTraveller);

  return (
    <StyledTravellersWrapper>
      <StyledTravellersMain>
        <StyledTravellersHeader>
          <StyledTravellersTitle>
            {t("travellers")}
          </StyledTravellersTitle>

          <StyledSearchWrapper>
            <Search
              label={t("search")}
              value={searchValue}
              setValue={(value) => setSearchValue(value)}
            />
          </StyledSearchWrapper>
        </StyledTravellersHeader>

        {/* Таблица с Путешественниками (только desktop) */}
        <StyledTableTravellersWrapper>
          <TravellersTable 
            travellers={TRAVELLERS_TEST_DATA}
            setCurrentEditForm={(value: any) => setCurrentEditForm(value)}
            setCurrentTraveller={(value: any) => setCurrentTraveller(value)}
          />
        </StyledTableTravellersWrapper>

        {/* Блок с Путешественниками (только mobile) */}
        <StyledTravellersListMobileWrapper>
          <TravellersListMobile 
            travellers={TRAVELLERS_TEST_DATA}
            setCurrentEditForm={(value) => setCurrentEditForm(value)}
            setCurrentTraveller={(value: any) => setCurrentTraveller(value)}
          />
        </StyledTravellersListMobileWrapper>

        <StyledAddNewWrapper>
          <StyledAddNew onClick={handleEditTraveller}>
            {t("addNewTraveller")}
          </StyledAddNew>
        </StyledAddNewWrapper>
      </StyledTravellersMain>

      {/* Модальное окно в котором формы для редактирования данных */}
      <ModalFormEdit
        title={currentEditForm.title}
        typeForm={TypeFormEnum.traveller}
        currentEditForm={currentEditForm}
        setCurrentEditForm={(value) => setCurrentEditForm(value)}
      />
    </StyledTravellersWrapper>
  );
};
