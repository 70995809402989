import styled from 'styled-components';
import { mainTheme } from "../../theme"

export const StyledFormAuthWrapper = styled.div`
  margin-top: 15px;
  
  form {
    display: flex;
    flex-direction: column;
    gap: 20px;

    label {
      color: ${mainTheme.main.colors.gray};
      font-size: ${mainTheme.main.fontSizes.small};
      font-weight: ${mainTheme.main.fontWeights.normal};
    }
    
    textFieldForm {
      border-bottom: 1px solid ${mainTheme.main.colors.merin};
    }
  }
`

export const StyledButtonLogin = styled.button`
  border-radius: 20px;
  height: 40px;
  color: ${mainTheme.main.colors.white};
  background: ${mainTheme.main.colors.blue};
  font-size: ${mainTheme.main.fontSizes.small};
  font-weight: ${mainTheme.main.fontWeights.normal};
  border: none;
  margin-top: 30px;
`

export const StyledError = styled.div`
  color: ${mainTheme.main.colors.red};
  font-size: ${mainTheme.main.fontSizes.xsmall};
  font-weight: ${mainTheme.main.fontWeights.normal};
  margin-top: -10px;
`

export const StyledFooter = styled.div`
  display: flex;
  justify-content: space-between;
`

export const StyledLink = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-size: ${mainTheme.main.fontSizes.medium};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
`