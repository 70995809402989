export const IconVerifyError = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={12}
    fill="none"
  >
    <path
      fill="#FA600A"
      fillRule="evenodd"
      d="M4.485 1.458c.673-1.166 2.357-1.166 3.03 0l4.042 7c.674 1.167-.168 2.625-1.515 2.625H1.959c-1.347 0-2.19-1.458-1.516-2.625l4.042-7Zm2.02.584a.583.583 0 0 0-1.01 0l-4.042 7a.583.583 0 0 0 .506.875h8.083c.449 0 .73-.487.505-.875l-4.042-7ZM6 4.083c.322 0 .583.261.583.584v1.75a.583.583 0 0 1-1.166 0v-1.75c0-.323.261-.584.583-.584Zm.583 4.084a.583.583 0 1 0-1.166 0v.583a.583.583 0 0 0 1.166 0v-.583Z"
      clipRule="evenodd"
    />
  </svg>
)
