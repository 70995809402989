const IconPdfDoc = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
  >
    <path
      fill="#004155"
      d="M11.023 19.573c-.245 0-.41.024-.496.049v1.57c.101.024.228.03.403.03.638 0 1.031-.322 1.031-.867 0-.488-.338-.782-.938-.782Zm4.65.017c-.268 0-.44.023-.543.047v3.48c.102.024.268.024.417.024 1.09.009 1.799-.591 1.799-1.86.008-1.107-.64-1.691-1.674-1.691Z"
    />
    <path
      fill="#004155"
      d="M18.667 2.667H8a2.667 2.667 0 0 0-2.667 2.666v21.334A2.667 2.667 0 0 0 8 29.333h16a2.667 2.667 0 0 0 2.667-2.666v-16l-8-8Zm-6.003 18.92c-.412.386-1.02.56-1.728.56a2.984 2.984 0 0 1-.41-.024v1.901H9.333v-5.248a10.08 10.08 0 0 1 1.625-.11c.742 0 1.27.142 1.627.426.338.27.567.71.567 1.23 0 .523-.174.965-.489 1.265Zm5.076 1.806c-.56.466-1.412.687-2.453.687-.624 0-1.066-.04-1.366-.08v-5.223a10.598 10.598 0 0 1 1.626-.11c1.01 0 1.665.181 2.177.568.553.41.9 1.065.9 2.005 0 1.017-.372 1.72-.884 2.153Zm4.927-3.7h-2.043v1.215h1.91v.979h-1.91v2.138h-1.208v-5.318h3.25v.986Zm-4-7.693h-1.334V5.333L24 12h-5.333Z"
    />
  </svg>
)
export default IconPdfDoc;
