import styled from 'styled-components'
import { mainTheme } from "../../theme"

export const StyledModalWrapper = styled.div``

export const StyledContentWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 320px;
  padding: 20px;
  background: ${mainTheme.main.colors.white};
  outline: none;
`

export const StyledTitle = styled.div`
  font-size: ${mainTheme.main.fontSizes.large};
  color: ${mainTheme.main.colors.blueMain};
  font-weight: ${mainTheme.main.fontWeights.bold};
  text-transform: uppercase;
`

export const StyledMain = styled.div``