import { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./i18n";
import Theme from "./theme";
import FontStyles from "./utils/globalStyles";
import { ApolloGraphqlProvider } from "./appolo/ApolloGraphqlProvider";


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Suspense fallback={<div>Loading</div>}>
    <ApolloGraphqlProvider>
      <BrowserRouter>
        <Theme>
          <FontStyles />
          <App />
        </Theme>
      </BrowserRouter>
    </ApolloGraphqlProvider>
  </Suspense>
);
