import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { ModalFormEditProps, TypeFormEnum } from "./types";
import { FormEditPersonalInformation } from "../Forms/FormEditPersonalInformation";
import { FormEditPassportDetails } from "../Forms/FormEditPassportDetails";
import { StyledTitle } from "./ModalFormEdit.styles";
import { FormTraveller } from "../Forms/FormTraveller";
import { FormAddBooking } from "../Forms/FormAddBooking";
import { FormRegistration } from "../Forms/FormRegistration";
import { FormForgotPassword } from "../Forms/FormForgotPassword/FormForgotPassword";
import { FormEditAdditionalRequirements } from "../Forms/FormEditAdditionalRequirements";
import { FormEditEmergencyContact } from "../Forms/FormEditEmergencyContact";
import { FormEditArrangementsBooked } from "../Forms/FormEditArrangementsBooked";
import { FormEditBedType } from "../Forms/FormEditBedType";
import { FormComment } from "../Forms/FormComment";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    minWidth: '320px',
    width: "100%",
    boxSizing: "border-box"
  },

  "& .MuiDialogContent-root": {
    padding: '0px 20px 20px 20px',
    boxSizing: "border-box"
  },

  "& .MuiDialogActions-root": {
    padding: '20px',
  },
}));

export const ModalFormEdit = ({
  title,
  typeForm,
  currentEditForm,
  setCurrentEditForm,
  children
}: ModalFormEditProps) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);

    setCurrentEditForm({
      title: "",
      typeEditForm: "",
    });
  };

  const renderFormEdit = () => {
    // Форма в разделе "Personal Information"
    if (currentEditForm.typeEditForm === TypeFormEnum.personalInformation) {
      return (
        <div>
          <FormEditPersonalInformation 
            setOpen={(value: boolean) => setOpen(value)}
          />
        </div>
      )
    }

    // Форма в разделе "Passport Details"
    if (currentEditForm.typeEditForm === TypeFormEnum.passportDetails) {
      return (
        <div>
          <FormEditPassportDetails 
            setOpen={(value: boolean) => setOpen(value)}
          />
        </div>
      )
    }


    // Форма в разделе "Traveller"
    if (currentEditForm.typeEditForm === TypeFormEnum.traveller) {
      return (
        <div>
          <FormTraveller 
            setOpen={(value: boolean) => setOpen(value)}
          />
        </div>
      )
    }

    // Форма в разделе "Add new Booking"
    if (currentEditForm.typeEditForm === TypeFormEnum.newBoking) {
      return (
        <div>
          <FormAddBooking 
            setOpen={(value: boolean) => setOpen(value)}
          />
        </div>
      )
    }

    // Форма "Registration"
    if (currentEditForm.typeEditForm === TypeFormEnum.registration) {
      return (
        <div>
          <FormRegistration
            setOpen={(value: boolean) => setOpen(value)}
          />
        </div>
      )
    }

    // Форма "Forgot Password"
    if (currentEditForm.typeEditForm === TypeFormEnum.forgotPassword) {
      return (
        <div>
          <FormForgotPassword
            setOpen={(value: boolean) => setOpen(value)}
          />
        </div>
      )
    }

    // Форма "Additional requirements"
    if (currentEditForm.typeEditForm === TypeFormEnum.additionalRequirements) {
      console.log("!!!!!")
      return (
        <div>
          <FormEditAdditionalRequirements 
            setOpen={(value: boolean) => setOpen(value)}
          />
        </div>
      )
    }

    // Форма "Emergency contact details"
    if (currentEditForm.typeEditForm === TypeFormEnum.emergencyContact) {
      return (
        <div>
          <FormEditEmergencyContact 
            setOpen={(value: boolean) => setOpen(value)}
          />
        </div>
      )
    }

    // Форма "Details of Travel arrangements"
    if (currentEditForm.typeEditForm === TypeFormEnum.travelArrangement) {
      return (
        <div>
          <FormEditArrangementsBooked 
            setOpen={(value: boolean) => setOpen(value)}
          />
        </div>
      )
    }

    // Форма "Bed type"
    if (currentEditForm.typeEditForm === TypeFormEnum.bedType) {
      return (
        <div>
          <FormEditBedType 
            setOpen={(value: boolean) => setOpen(value)}
          />
        </div>
      )
    }

    // Форма "Comment"
    if (currentEditForm.typeEditForm === TypeFormEnum.comment) {
      return (
        <div>
          <FormComment 
            setOpen={(value: boolean) => setOpen(value)}
          />
        </div>
      )
    }
    
    return (
      <div>
        {children}
      </div>
    )
  };

  useEffect(() => {
    if (currentEditForm.typeEditForm.length) {
      setOpen(true);
    }
  }, [currentEditForm]);

  return (
    <BootstrapDialog onClose={handleClose} open={open}>
      <StyledTitle>{t(title)}</StyledTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent>
        {renderFormEdit()}
      </DialogContent>
    </BootstrapDialog>
  );
};
