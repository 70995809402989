import styled from 'styled-components';
import { mainTheme } from '../../../../theme';

export const StyledCustomSelectWrapper = styled.div`
  .MuiFormControl-root {
    div {
      background: ${mainTheme.main.colors.white};
    }
  }

  .MuiSelect-select {
    font-family: ${mainTheme.main.fonts.dmsRegular};
    color: ${mainTheme.main.colors.black};
    font-size: ${mainTheme.main.fontSizes.large};
    font-weight: ${mainTheme.main.fontWeights.normal};
  }

  .MuiInputLabel-root {
    font-size: ${mainTheme.main.fontSizes.large};
    font-weight: ${mainTheme.main.fontWeights.normal};
    color: ${mainTheme.main.colors.black} !important;
    font-family: ${mainTheme.main.fonts.dmsRegular};
    opacity: 0.5;
    z-index: 2;
  }
`