import styled from "styled-components";
import { Button } from "@mui/material";
import { mainTheme } from "../../../theme";

export const StyledFormFieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 20px;
`

export const StyledFormFieldsWrapperTwoColumns = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, 1fr) minmax(auto, 1fr);
  gap: 20px;

  .MuiTextField-root {
    width: 100%;
  }
`

export const StyledButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 30px;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;

export const StyledButtonSave = styled(Button)`
  &.MuiButton-root {
    border-radius: 24px;
    color: ${mainTheme.main.colors.white};
    font-size: ${mainTheme.main.fontSizes.large};
    font-weight: ${mainTheme.main.fontWeights.normal};
    font-family: ${mainTheme.main.fonts.dmsRegular};
    background: ${mainTheme.main.colors.blue};
    width: 153px;
    border: 1px solid ${mainTheme.main.colors.blue};
    text-transform: capitalize;
    box-shadow: none;
    padding: 4px;

    &:hover {
      background: ${mainTheme.main.colors.blue};
    }

    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
      width: 100%;
    }
  }
`;

export const StyledButtonCancel = styled(Button)`
  &.MuiButton-root {
    border-radius: 20px;
    color: ${mainTheme.main.colors.black};
    font-size: ${mainTheme.main.fontSizes.medium};
    font-weight: ${mainTheme.main.fontWeights.normal};
    background: ${mainTheme.main.colors.white};
    width: 138px;
    border: 1px solid ${mainTheme.main.colors.graySilver};
    box-shadow: none;

    &:hover {
      background: ${mainTheme.main.colors.white};
    }

    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
      width: 100%;
    }
  }
`;