import { useTranslation } from "react-i18next";
import { CUSTOMER_DATA } from "./consts";
import IconEdit from "../../icons/IconEdit";
import {
  StyledCustomerWrapper,
  StyledCustomerColumn,
  StyledCustomerHeaderColumn,
  StyledCustomerColumnContent,
  StyledCustomerRow,
  StyledButtonEdit,
  StyledStateroomsSectionTitle,
} from "./CustomerMobile.styles";

export const CustomerMobile = () => {
  const { t } = useTranslation();

  const handleEdit = () => {
    console.log("handleEdit")
  };

  return (
    <StyledCustomerWrapper>
      <StyledStateroomsSectionTitle>
        {t("customer")} 
      </StyledStateroomsSectionTitle>
      <StyledCustomerRow>
        <StyledCustomerColumn>
          <StyledCustomerHeaderColumn>
            {t("fullName")}
          </StyledCustomerHeaderColumn>
          <StyledCustomerColumnContent>
            {CUSTOMER_DATA.fullName.value}
          </StyledCustomerColumnContent>
        </StyledCustomerColumn>

        <StyledCustomerColumn>
          <StyledButtonEdit onClick={handleEdit}>
            <IconEdit />
          </StyledButtonEdit>
        </StyledCustomerColumn>
      </StyledCustomerRow>

      <StyledCustomerRow>
        <StyledCustomerColumn>
          <StyledCustomerHeaderColumn>{t("phone")}</StyledCustomerHeaderColumn>
          <StyledCustomerColumnContent>
            {CUSTOMER_DATA.phone.value}
          </StyledCustomerColumnContent>
        </StyledCustomerColumn>

        <StyledCustomerColumn className="last">
          <StyledCustomerHeaderColumn>{t("email")}</StyledCustomerHeaderColumn>
          <StyledCustomerColumnContent>
            {CUSTOMER_DATA.email.value}
          </StyledCustomerColumnContent>
        </StyledCustomerColumn>
      </StyledCustomerRow>

      <StyledCustomerRow>
        <StyledCustomerColumn>
          <StyledCustomerHeaderColumn>
            {t("loyaltyNumber")}
          </StyledCustomerHeaderColumn>
          <StyledCustomerColumnContent>
            {CUSTOMER_DATA.loyaltyNumber.value}
          </StyledCustomerColumnContent>
        </StyledCustomerColumn>
      </StyledCustomerRow>
    </StyledCustomerWrapper>
  );
};
