import styled from 'styled-components'
import { mainTheme } from "../../theme"

export const StyledPaymentScheduleWrapper = styled.div`
  background: ${mainTheme.main.colors.white};
  padding: 12px;
  margin-top: 12px;
`

export const AccordionWrapper = styled.div`
  .MuiPaper-root {
    background: ${mainTheme.main.colors.white};

    box-shadow: none;
    border: none;
  }

  .MuiAccordionSummary-content {
    div {
      font-size: ${mainTheme.main.fontSizes.large};
      font-weight: ${mainTheme.main.fontWeights.boldExtra};
      color: ${mainTheme.main.colors.black};
      font-family: ${mainTheme.main.fonts.chapRegular};
    }
  }

  .MuiButtonBase-root {
    padding-left: 0;
    padding-right: 0;
    box-shadow: none;
    border: none;
  }

  .MuiAccordionDetails-root {
    padding: 0;
  } 
`

export const StyledPaymentInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const StyledPaymentInfoItem = styled.div`
  display: flex;
 flex-direction: row;
 justify-content: space-between;
`

export const StyledPaymentInfoItemColumn = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledPrice = styled.div`
  font-size: ${mainTheme.main.fontSizes.medium};
  font-weight: ${mainTheme.main.fontWeights.normal};
  color: ${mainTheme.main.colors.black};
  font-family: ${mainTheme.main.fonts.dmsRegular};
`

export const StyledDate = styled.div`
  font-size: ${mainTheme.main.fontSizes.medium};
  font-weight: ${mainTheme.main.fontWeights.normal};
  color: ${mainTheme.main.colors.merin};
  font-family: ${mainTheme.main.fonts.dmsRegular};
`

export const StyledStatusPay = styled.div`
  font-size: ${mainTheme.main.fontSizes.xsmall};
  font-weight: ${mainTheme.main.fontWeights.normal};
  color: #971CA1;
  font-family: ${mainTheme.main.fonts.interRegular};
  padding: 4px 6px;
  border-radius: 4px;
  background: #FCDBFF;
`
