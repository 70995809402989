import i18next from 'i18next';
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from 'react-i18next';
import enLang from './locales/en/translation.json';
import ruLang from './locales/ru/translation.json';

// Язык по-умолчанию
export const defaultNS = 'en';

i18next.use(LanguageDetector).use(initReactI18next).init({
  debug: true,
  lng: defaultNS,
  resources: {
    en: {
      translation: enLang
    },
    ru: {
      translation: ruLang
    }
  }
})

export default i18next;