import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  StyledButtonCancel,
  StyledButtonSave,
  StyledButtonsWrapper,
  StyledStateroomsCabinButton,
  StyledStateroomsCabinsWrapper,
  StyledStateroomsColumn,
  StyledStateroomsColumnContent,
  StyledStateroomsColumnEditButton,
  StyledStateroomsColumnEditWrapper,
  StyledStateroomsColumnsWrapper,
  StyledStateroomsColumnTitle,
  StyledStateroomsSectionTitle,
  StyledStateroomsWrapper,
  StyledTextInfo,
} from "./Staterooms.styles";
import {
  STATEROOM_CABINS_NUMBER,
  STATEROOM_DATA,
  STATEROOM_TRAVELERS_DATA,
} from "./consts";
import IconEdit from "../../icons/IconEdit";
import { TravelersTable } from "../TravelersTable";
import { TypeFormEnum } from "../ModalFormEdit/types";
import { CurrentEditFormType } from "../../utils/globalTypes";
import { ModalFormEdit } from "../ModalFormEdit";
import { ModalInfo } from "../ModalInfo";

export const Staterooms = () => {
  const { t } = useTranslation();

  const [currentCabin, setCurrentCabin] = useState<any>(
    STATEROOM_CABINS_NUMBER[0]
  );

  const [currentEditForm, setCurrentEditForm] = useState<CurrentEditFormType>({
    title: "",
    typeEditForm: "",
  });

  console.log("currentEditForm!!!: ", currentEditForm);

  return (
    <StyledStateroomsWrapper>
      <StyledStateroomsSectionTitle>
        {t("staterooms")}
      </StyledStateroomsSectionTitle>

      {/* Секция с номер кают */}
      <StyledStateroomsCabinsWrapper>
        {STATEROOM_CABINS_NUMBER?.map((item) => (
          <StyledStateroomsCabinButton
            className={item?.number === currentCabin?.number ? "active" : ""}
            onClick={() => setCurrentCabin(item)}
          >
            {item?.number}
          </StyledStateroomsCabinButton>
        ))}
      </StyledStateroomsCabinsWrapper>

      {/* Секция с информацией о выбраной каюте */}
      <StyledStateroomsColumnsWrapper>
        <StyledStateroomsColumn>
          <StyledStateroomsColumnTitle>
            {t("stateRoomNumber")}
          </StyledStateroomsColumnTitle>
          <StyledStateroomsColumnContent>
            {STATEROOM_DATA?.number}
          </StyledStateroomsColumnContent>
        </StyledStateroomsColumn>

        <StyledStateroomsColumn>
          <StyledStateroomsColumnTitle>{t("deck")}</StyledStateroomsColumnTitle>
          <StyledStateroomsColumnContent>
            {STATEROOM_DATA?.deck}
          </StyledStateroomsColumnContent>
        </StyledStateroomsColumn>

        <StyledStateroomsColumn>
          <StyledStateroomsColumnTitle>
            {t("category")}
          </StyledStateroomsColumnTitle>
          <StyledStateroomsColumnContent>
            {STATEROOM_DATA?.category}
          </StyledStateroomsColumnContent>
        </StyledStateroomsColumn>

        <StyledStateroomsColumn>
          <StyledStateroomsColumnEditWrapper>
            <StyledStateroomsColumnTitle>
              {t("bedType")}
            </StyledStateroomsColumnTitle>
            <StyledStateroomsColumnEditButton
              onClick={() =>
                setCurrentEditForm({
                  title: "Additional requirements",
                  typeEditForm: TypeFormEnum.bedType,
                })
              }
            >
              <IconEdit />
            </StyledStateroomsColumnEditButton>
          </StyledStateroomsColumnEditWrapper>

          <StyledStateroomsColumnContent>
            {STATEROOM_DATA?.bedType}
          </StyledStateroomsColumnContent>
        </StyledStateroomsColumn>
      </StyledStateroomsColumnsWrapper>

      <StyledStateroomsSectionTitle className="guest">
        {t("guest")}
      </StyledStateroomsSectionTitle>

      <TravelersTable
        travelers={STATEROOM_TRAVELERS_DATA}
        setCurrentEditForm={(value) => setCurrentEditForm(value)}
      />

      {/* Модальное окно в котором формы для редактирования данных */}
      <ModalFormEdit
        title={currentEditForm.title}
        currentEditForm={currentEditForm}
        setCurrentEditForm={(value) => setCurrentEditForm(value)}
      >
        {currentEditForm.typeEditForm === TypeFormEnum.healthDeclaration && (
          <div>
            <StyledTextInfo>
              You can submit your Medical Screening Form. You will be redirected
              to the submission page
            </StyledTextInfo>
            <StyledButtonsWrapper>
              <StyledButtonCancel>
                {t("cancel")}
              </StyledButtonCancel>
              <StyledButtonSave>
                {t("save")}
              </StyledButtonSave>
            </StyledButtonsWrapper>
          </div>
        )}
      </ModalFormEdit>
    </StyledStateroomsWrapper>
  );
};
