import { t } from "i18next";
import { PassportDetailsProps } from "./types";
import { TypeFormEnum } from "../ModalFormEdit/types";
import { EmailSubscriptions } from "../EmailSubscriptions";
import {
  StyledChange,
  StyledPassportDetailsList,
  StyledPassportDetailsListColumnWrapper,
  StyledPassportDetailsListItemColumn,
  StyledPassportDetailsListItemColumnTitle,
  StyledPassportDetailsListItemContent,
  StyledPassportDetailsSection,
  StyledPassportDetailsSectionItem,
  StyledPassportDetailsSectionItemTitle,
} from "./PassportDetails.styles";



export const PassportDetails = ({ editForm }: PassportDetailsProps) => {

  const handleChange = () => {
    if (editForm) {
      editForm({
        title: "passportDetails",
        typeEditForm: TypeFormEnum.passportDetails
      })
    }
  }

  return (
    <StyledPassportDetailsSection>
      <StyledPassportDetailsSectionItem>
        <StyledPassportDetailsSectionItemTitle>
          {t("passportDetails")}
        </StyledPassportDetailsSectionItemTitle>

        <StyledPassportDetailsList>
          <StyledPassportDetailsListColumnWrapper>
            <StyledPassportDetailsListItemColumn>
              <StyledPassportDetailsListItemColumnTitle>
                {t("number")}
              </StyledPassportDetailsListItemColumnTitle>
              <StyledPassportDetailsListItemContent>
                308 *** *72
              </StyledPassportDetailsListItemContent>
            </StyledPassportDetailsListItemColumn>

            <StyledPassportDetailsListItemColumn>
              <StyledPassportDetailsListItemColumnTitle>
                {t("placeOfBirth")}
              </StyledPassportDetailsListItemColumnTitle>
              <StyledPassportDetailsListItemContent>
                London, United Kingdom
              </StyledPassportDetailsListItemContent>
            </StyledPassportDetailsListItemColumn>
          </StyledPassportDetailsListColumnWrapper>

          <StyledPassportDetailsListColumnWrapper>
            <StyledPassportDetailsListItemColumn>
              <StyledPassportDetailsListItemColumnTitle>
                {t("issueDate")}
              </StyledPassportDetailsListItemColumnTitle>
              <StyledPassportDetailsListItemContent>
                08.12.2014
              </StyledPassportDetailsListItemContent>
            </StyledPassportDetailsListItemColumn>
          </StyledPassportDetailsListColumnWrapper>

          <StyledPassportDetailsListColumnWrapper>
            <StyledPassportDetailsListItemColumn className="lastColumn">
              <StyledPassportDetailsListItemColumnTitle>
                {t("expirationDate")}
              </StyledPassportDetailsListItemColumnTitle>
              <StyledPassportDetailsListItemContent>
                08.12.2024
              </StyledPassportDetailsListItemContent>
            </StyledPassportDetailsListItemColumn>

            <StyledPassportDetailsListItemColumn className="lastColumn">
              <StyledPassportDetailsListItemColumnTitle>
                {t("placeOfIssue")}
              </StyledPassportDetailsListItemColumnTitle>
              <StyledPassportDetailsListItemContent>
                London, United Kingdom
              </StyledPassportDetailsListItemContent>
            </StyledPassportDetailsListItemColumn>
          </StyledPassportDetailsListColumnWrapper>

          <StyledPassportDetailsListColumnWrapper>
            <StyledPassportDetailsListItemColumn className="lastColumn">
              <StyledPassportDetailsListItemColumnTitle>
                {t("citizenship")}
              </StyledPassportDetailsListItemColumnTitle>
              <StyledPassportDetailsListItemContent>
                UK
              </StyledPassportDetailsListItemContent>
            </StyledPassportDetailsListItemColumn>
          </StyledPassportDetailsListColumnWrapper>
        </StyledPassportDetailsList>

        <StyledChange onClick={handleChange}>
          {t("edit")}
        </StyledChange>
      </StyledPassportDetailsSectionItem>

      {/* <StyledPassportDetailsSectionItem className="emailSubscriptions">
        <EmailSubscriptions />
      </StyledPassportDetailsSectionItem> */}
    </StyledPassportDetailsSection>
  );
};
