import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { MenuItem, TextField } from "@mui/material";
import { mainTheme } from "../../../theme";
import { CustomSelect } from "../components/CustomSelect";
import { FieldDatePicker } from "../components/FieldDatePicker";
import { VariantCustomSelectEnum } from "../components/CustomSelect/types";
import { COUNTRY } from "./consts";
import { FormAddBookingProps } from "./types";
import {
  StyledFormAddBookingWrapper,
  StyledFormAddBookingItemsRow,
  StyledFormAddBookingItem,
  StyledFormAddBookingItemsList,
  StyledFormAddBookingAnatation,
  StyledButtonsWrapper,
  StyledButtonCancel,
  StyledButtonSave,
} from "./FormAddBooking.styles";

interface IFormAddBookingInput {
  number?: string;
  issueDate?: string;
  expirationDate?: string;
  placeOfBirth?: string;
  placeOfIssue?: string;
  citizenship?: string;
}

export const FormAddBooking = ({ setOpen }: FormAddBookingProps) => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    number: yup.string(),
    issueDate: yup.string(),
    expirationDate: yup.string(),
    placeOfBirth: yup.string(),
    placeOfIssue: yup.string(),
    citizenship: yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<IFormAddBookingInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      number: "308 537 172",
      issueDate: "04.12.1983",
      expirationDate: "04.12.1983",
      placeOfBirth: "London, United Kingdom",
      placeOfIssue: "London, United Kingdom",
      citizenship: "1",
    },
  });

  const onSubmit = async (data: IFormAddBookingInput) => {
    const formValue = {
      ...data,
      issueDate: dayjs(data.issueDate).format("YYYY.MM.DD"),
      expirationDate: dayjs(data.expirationDate).format("YYYY.MM.DD"),
    };
    console.log("formValue: ", formValue);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <StyledFormAddBookingWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledFormAddBookingItemsRow>
          <StyledFormAddBookingItem>
            <Controller
              control={control}
              name="number"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label={t("number")}
                  variant="standard"
                  onChange={onChange}
                  value={value}
                  InputProps={{
                    className: "textFieldForm",
                    style: {
                      color: mainTheme.main.colors.black,
                      fontSize: mainTheme.main.fontSizes.large,
                      fontWeight: mainTheme.main.fontWeights.normal,
                      fontFamily: mainTheme.main.fonts.dmsRegular,
                    },
                  }}
                />
              )}
            />
          </StyledFormAddBookingItem>

          <StyledFormAddBookingItem>
            <Controller
              control={control}
              name="issueDate"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <FieldDatePicker
                  onChange={onChange}
                  value={value ? dayjs(value) : null}
                  label="issueDate"
                />
              )}
            />
          </StyledFormAddBookingItem>

          <StyledFormAddBookingItem>
            <Controller
              control={control}
              name="expirationDate"
              render={({ field: { onChange, onBlur, value, ref } }) => {
                const test = dayjs(value);
                console.log("test: ", test);
                return (
                  <FieldDatePicker
                    onChange={onChange}
                    value={value ? dayjs(value) : null}
                    label="expirationDate"
                  />
                );
              }}
            />
          </StyledFormAddBookingItem>
        </StyledFormAddBookingItemsRow>

        <StyledFormAddBookingItemsList>
          <StyledFormAddBookingItem>
            <Controller
              control={control}
              name="placeOfBirth"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label={t("placeOfBirth")}
                  variant="standard"
                  onChange={onChange}
                  value={value}
                  InputProps={{
                    className: "textFieldForm",
                    style: {
                      color: mainTheme.main.colors.black,
                      fontSize: mainTheme.main.fontSizes.large,
                      fontWeight: mainTheme.main.fontWeights.normal,
                      fontFamily: mainTheme.main.fonts.dmsRegular,
                    },
                  }}
                />
              )}
            />
          </StyledFormAddBookingItem>
          <StyledFormAddBookingItem>
            <Controller
              control={control}
              name="placeOfIssue"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label={t("placeOfIssue")}
                  variant="standard"
                  onChange={onChange}
                  value={value}
                  InputProps={{
                    className: "textFieldForm",
                    style: {
                      color: mainTheme.main.colors.black,
                      fontSize: mainTheme.main.fontSizes.large,
                      fontWeight: mainTheme.main.fontWeights.normal,
                      fontFamily: mainTheme.main.fonts.dmsRegular,
                    },
                  }}
                />
              )}
            />
          </StyledFormAddBookingItem>

          <StyledFormAddBookingItem>
            <Controller
              control={control}
              name="citizenship"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <CustomSelect
                  label="citizenship"
                  onChange={onChange}
                  defaultValue="1"
                  variant={VariantCustomSelectEnum.standard}
                >
                  {COUNTRY.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </CustomSelect>
              )}
            />
          </StyledFormAddBookingItem>
        </StyledFormAddBookingItemsList>

        <StyledFormAddBookingAnatation>
          {t("anatationEditPassportDetails")}
        </StyledFormAddBookingAnatation>

        <StyledButtonsWrapper>
          <StyledButtonCancel variant="contained" onClick={handleCancel}>
            {t("cancel")}
          </StyledButtonCancel>
          <StyledButtonSave
            type="submit"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            {t("save")}
          </StyledButtonSave>
        </StyledButtonsWrapper>
      </form>
    </StyledFormAddBookingWrapper>
  );
};
