export const BOOKING_DETAILS_INFO_DATA = {
  bookingCode: {
    label: "Booking code",
    value: "16920-03"
  },
  status: {
    label: "Status",
    value: "Booked"
  },
  bookedAt: {
    label: "Booked at",
    value: "August 29, 2024 10:20 am (UTC +1)"
  },
  bookingTimeLimit: {
    label: "Booking time limit",
    value: "until August 24, 2024 08:00 am (UTC +1)"
  }
}