export const IconPhone = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10}
    height={11}
    fill="none"
  >
    <g clipPath="url(#a)">
      <path
        fill="#004155"
        fillRule="evenodd"
        d="M3.405 7.095C5.007 8.698 6.492 8.873 6.928 8.89c.526.02 1.064-.41 1.296-.851-.37-.435-.854-.773-1.382-1.138-.312.311-.697.89-1.209.683a5.092 5.092 0 0 1-1.639-1.077 5.114 5.114 0 0 1-1.077-1.639c-.208-.512.373-.898.685-1.21-.366-.537-.697-1.033-1.131-1.385-.447.233-.88.767-.86 1.3.016.436.192 1.92 1.794 3.523Zm3.492 2.627c-.6-.022-2.3-.257-4.082-2.037C1.036 5.904.8 4.204.779 3.603c-.033-.915.667-1.803 1.477-2.15a.612.612 0 0 1 .602.066c.67.488 1.132 1.229 1.53 1.809a.627.627 0 0 1-.074.797l-.565.565c.13.29.398.79.835 1.227.438.437.937.704 1.227.836l.565-.566a.626.626 0 0 1 .799-.07c.591.41 1.286.865 1.793 1.513a.612.612 0 0 1 .079.62c-.349.813-1.231 1.506-2.15 1.472Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .5h10v10H0z" />
      </clipPath>
    </defs>
  </svg>
)
