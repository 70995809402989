import styled from 'styled-components'
import { mainTheme } from "../../theme"

export const StyledCustomerWrapper = styled.div`
  gap: 12px;
  background: ${mainTheme.main.colors.white};
  padding: 16px;
  box-shadow: 0px 3px 14px -6px rgba(0,0,0,0.40);
  margin-top: 12px;
`

export const StyledStateroomsSectionTitle = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-weight: ${mainTheme.main.fontWeights.bold};
  font-family: ${mainTheme.main.fonts.chapRegular};
  font-size: ${mainTheme.main.fontSizes.large};
  margin-bottom: 14px;
`

export const StyledCustomerHeaderColumn = styled.div`
  color: ${mainTheme.main.colors.merin};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  font-size: ${mainTheme.main.fontSizes.small};
`

export const StyledCustomerColumns = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 20px; 
`

export const StyledCustomerColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

export const StyledCustomerColumnContent = styled.div`
  color: ${mainTheme.main.colors.black};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  font-size: ${mainTheme.main.fontSizes.medium};

  &.row {
    display: flex;
    flex-direction: row;
    gap: 4px;
  }
`

export const StyledIconWrapper = styled.div``