import { useTranslation } from "react-i18next";
import { AccordionBooking } from "../AccordionBooking";
import {
  AccordionWrapper,
  StyledAccordionContent,
  StyledCategory,
  StyledNameCategory,
  StyledPriceCategory,
  StyledPriceDetailsDescriptions,
  StyledPriceDetailsItem,
  StyledPriceDetailsPriceTotal,
  StyledPriceDetailsPriceTotalAmount,
  StyledPriceDetailsPriceTotalName,
  StyledPriceDetailsWrapper,
  StyledSubCategoryItem,
  StyledSubCategoryName,
  StyledSubCategoryPrice,
  StyledSubCategoryWrapper,
  StyledType,
} from "./PriceDetails.styles";
import { MESSAGE, PRICE_DETAILS } from "./consts";

export const PriceDetails = () => {
  const { t } = useTranslation();

  return (
    <StyledPriceDetailsWrapper>
      <StyledPriceDetailsDescriptions>{MESSAGE}</StyledPriceDetailsDescriptions>

      <AccordionWrapper>
        <AccordionBooking title={t("priceDetails")}>
          <StyledAccordionContent>
            {PRICE_DETAILS?.map((item) => (
              <StyledPriceDetailsItem>
                <StyledCategory>
                  <StyledNameCategory>{item?.nameSection}</StyledNameCategory>
                  <StyledPriceCategory>{item?.price}</StyledPriceCategory>
                </StyledCategory>

                {!!item?.sales?.length && (
                  <StyledSubCategoryWrapper>
                    {item?.sales?.map((subCategory) => (
                      <StyledSubCategoryItem>
                        <StyledSubCategoryName>
                          <div>
                            {subCategory?.name}
                          </div>
                          <StyledType>
                            {subCategory?.type}
                          </StyledType>
                        </StyledSubCategoryName>

                        <StyledSubCategoryPrice>
                          {subCategory?.price}
                        </StyledSubCategoryPrice>
                      </StyledSubCategoryItem>
                    ))}
                  </StyledSubCategoryWrapper>
                )}
              </StyledPriceDetailsItem>
            ))}
            <StyledPriceDetailsPriceTotal>
              <StyledPriceDetailsPriceTotalName>
                {t("paymentTotal")}
              </StyledPriceDetailsPriceTotalName>
              <StyledPriceDetailsPriceTotalAmount>
                55,650 $
              </StyledPriceDetailsPriceTotalAmount>
            </StyledPriceDetailsPriceTotal>
          </StyledAccordionContent>
        </AccordionBooking>
      </AccordionWrapper>
    </StyledPriceDetailsWrapper>
  );
};
