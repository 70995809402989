/* eslint-disable */
import { useState } from "react";
import { Link } from "react-router-dom";
import { t } from "i18next";
import moment from "moment";
import { CurrencyEnum, OrderListItem, PaymentStatusEnum } from "../../utils/globalTypes";
import { Pagination, Stack } from "@mui/material";
import {
  StyledBookingsMobile,
  StyledBookingsList,
  StyledBookingRow,
  StyledBookingColumn,
  StyledBookingColumnTitle,
  StyledColumnColumnDescription,
  StyledBookindStatus,
  StyledNameShip,
  StyledCodeShip,
  StyledColumnColumnDateTime,
  StyledBookingRowWrapper,
  StyledRouteWrapper,
  StyledRouteStart,
  StyledRouteEnd,
  StyledPlace,
  StyledColumnRoomWrapper,
  StyledColumnPassengersWrapper,
  StyledColumnPassengersTotal,
  StledPriceColumn,
  StledPriceColumnRow,
  StledPriceTotal,
  StledPriceStatus,
  StledPriceDebt,
  StledPricePaid,
  StyledColumnPaymentSchedule,
  StyledTableCellStatusTitle,
  StyledPaginationWrapper,
} from "./BookingsListMobile.styles";

type BookingsListMobileProps = {
  bookings: OrderListItem[];
  setCurrentPage?: (value: number) => void
  pageCount?: number
  orderListDataLoading?: boolean
};

export const BookingsListMobile = ({ bookings, setCurrentPage, pageCount, orderListDataLoading, }: BookingsListMobileProps) => {
  moment.locale("en");

  // Вернет иконку валюты
  const getCurrencyIcon = (currency: string) => {
    return (
      <>
        {currency === CurrencyEnum.EUR && (
          <span>&euro;</span>
        )}
        {currency === CurrencyEnum.GBP && (
          <span>&pound;</span>
        )}
        {currency === CurrencyEnum.RUB && (
          <span>&#8381;</span>
        )}
        {currency === CurrencyEnum.USD && (
          <span>$</span>
        )}
      </>
    )
  };

  const status = (booking: OrderListItem) => {
    if (booking?.paymentStatus === PaymentStatusEnum.notPaid) {
      return (
        <StyledTableCellStatusTitle
          className={PaymentStatusEnum.notPaid}
        >
          {booking?.paymentStatus}
        </StyledTableCellStatusTitle>
      )
    }

    if (booking?.paymentStatus === PaymentStatusEnum.paid) {
      return (
        <StyledTableCellStatusTitle
          className={PaymentStatusEnum.paid}
        >
          {booking?.paymentStatus}
        </StyledTableCellStatusTitle>
      )
    }

    if (booking?.paymentStatus === PaymentStatusEnum.partially) {
      return (
        <StyledTableCellStatusTitle
          className={PaymentStatusEnum.partially}
        >
          {booking?.paymentStatus}
        </StyledTableCellStatusTitle>
      )
    }

    if (booking?.paymentStatus === PaymentStatusEnum.overpaid) {
      return (
        <StyledTableCellStatusTitle
          className={PaymentStatusEnum.overpaid}
        >
          {booking?.paymentStatus}
        </StyledTableCellStatusTitle>
      )
    }
  }


  const [page, setPage] = useState(1);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);

    if (setCurrentPage) {
      setCurrentPage(value);
    }
  };


  return (
    <StyledBookingsMobile>
      <StyledBookingsList>
        {bookings?.map((booking: OrderListItem) => {
          const getNameTimeZone = booking?.createdAt?.replace(/[^a-zA-Z]+/g, "");
          // Массив кают (может ли быть несколько элементов в массиве cruise - ?)
          const cabinsArr = booking?.cruises?.map((cruise) => cruise)[0]?.cabins;
          // Массив пассажиров
          const passengers = booking?.travellers?.map((traveller) => traveller);
          // Стоимость с разделением на десятки (через запятую)
          const price = (booking.price?.amount / 100)?.toString()?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
          // Массив paymentSchedule
          const paymentSchedule = booking?.paymentSchedule;

          return (
            <StyledBookingRowWrapper>
              {/* Booking Code */}
              <StyledBookingRow>
                <StyledBookingColumn>
                  <StyledBookingColumnTitle>
                    {t("bookingCode")}
                  </StyledBookingColumnTitle>
                  <StyledColumnColumnDescription>
                    <Link to={`/bookingDetails/${booking?.uuid}`}>
                      {booking?.number}
                    </Link>
                  </StyledColumnColumnDescription>

                  <StyledBookindStatus>{booking?.status}</StyledBookindStatus>
                </StyledBookingColumn>

                {/* Ship */}
                <StyledBookingColumn>
                  <StyledBookingColumnTitle>
                    {t("ship")}
                  </StyledBookingColumnTitle>
                  {booking?.cruises?.map((cruise, index) => (
                    <>
                      <StyledNameShip>{cruise.motorshipName}</StyledNameShip>
                      <StyledCodeShip>{cruise.motorshipId}</StyledCodeShip>
                    </>
                  ))}
                </StyledBookingColumn>
              </StyledBookingRow>

              {/* Date */}
              <StyledBookingRow style={{ marginTop: "14px" }}>
                {booking?.cruises?.map((cruise, index) => (
                  <>
                    <StyledBookingColumn>
                      <StyledColumnColumnDescription>
                        {moment(cruise.dateStartRaw, "YYYY-MM-DD hh:mm").format(`ddd, DD MMM YY`)}
                      </StyledColumnColumnDescription>

                      <StyledColumnColumnDateTime>
                        {moment(cruise.dateStartRaw, "YYYY-MM-DD hh:mm").format("hh:mm a")}
                        {getNameTimeZone}
                        {moment(cruise.dateStartRaw, "YYYY-MM-DD hh:mm").format(`Z`).slice(0, -3)}
                      </StyledColumnColumnDateTime>
                    </StyledBookingColumn>

                    <StyledBookingColumn>
                      <StyledColumnColumnDescription>
                        {moment(cruise.dateEndRaw, "YYYY-MM-DD hh:mm").format(`ddd, DD MMM YY`)}
                      </StyledColumnColumnDescription>

                      <StyledColumnColumnDateTime>
                        {moment(cruise.dateEndRaw, "YYYY-MM-DD hh:mm").format("hh:mm a")}
                        {getNameTimeZone}
                        {moment(cruise.dateEndRaw, "YYYY-MM-DD hh:mm").format(`Z`).slice(0, -3)}
                      </StyledColumnColumnDateTime>
                    </StyledBookingColumn>
                  </>
                ))}
              </StyledBookingRow>

              {/* Route */}
              <StyledBookingRow>
                <StyledRouteWrapper>
                  {booking?.cruises?.map((cruise, index) => (
                    <>
                      <StyledBookingColumn>
                        <StyledRouteStart>
                          {cruise?.originPort?.cityName}
                        </StyledRouteStart>
                      </StyledBookingColumn>

                      <StyledBookingColumn>
                        <StyledRouteEnd>
                          {cruise?.destinationPort?.cityName}
                        </StyledRouteEnd>
                      </StyledBookingColumn>
                    </>
                  ))}
                </StyledRouteWrapper>
              </StyledBookingRow>

              <StyledBookingRow>
                {booking?.cruises?.map((cruise, index) => (
                  <>
                    <StyledBookingColumn>
                      <StyledColumnColumnDateTime>
                        {moment(cruise.dateStartRaw, "YYYY-MM-DD hh:mm").format(`dddd, DD MMM YY`)}
                      </StyledColumnColumnDateTime>

                      <StyledColumnColumnDateTime>
                        {cruise?.originPort?.portName}
                      </StyledColumnColumnDateTime>
                    </StyledBookingColumn>

                    <StyledBookingColumn>
                      <StyledColumnColumnDateTime>
                        {moment(cruise.dateEndRaw, "YYYY-MM-DD hh:mm").format(`dddd, DD MMM YY`)}
                      </StyledColumnColumnDateTime>

                      <StyledColumnColumnDateTime>
                        {cruise?.destinationPort?.portName}
                      </StyledColumnColumnDateTime>
                    </StyledBookingColumn>
                  </>
                ))}
              </StyledBookingRow>

              {/* Stateroom */}
              <StyledBookingRow style={{ marginTop: "14px" }}>
                <StyledBookingColumn>
                  <StyledBookingColumnTitle>
                    {/* @ts-ignore */}
                    {t("stateroom")}
                  </StyledBookingColumnTitle>
                </StyledBookingColumn>

                <StyledBookingColumn>
                  <StyledColumnRoomWrapper>
                    {cabinsArr?.map((cabin) => (
                      <>
                        <StyledColumnColumnDescription>
                          {cabin?.number ?? "-"}
                        </StyledColumnColumnDescription>
                        <StyledPlace>
                          {cabin?.roomClass ?? "-"}
                        </StyledPlace>
                      </>
                    ))}
                  </StyledColumnRoomWrapper>
                </StyledBookingColumn>
              </StyledBookingRow>

              {/* Passengers */}
              <StyledBookingRow style={{ marginTop: "14px" }}>
                <StyledBookingColumn>
                  <StyledBookingColumnTitle>
                    {t("passengers")}
                  </StyledBookingColumnTitle>
                </StyledBookingColumn>

                <StyledBookingColumn>
                  <StyledColumnPassengersWrapper>
                  {passengers?.map((passenger, index) => (
                    <StyledColumnColumnDescription key={index}>
                       {passenger.lastname}   {passenger.firstname}    {passenger.middlename}
                    </StyledColumnColumnDescription>
                  ))}
                  </StyledColumnPassengersWrapper>

                  <StyledColumnPassengersTotal>
                    {t("total")}: {passengers?.length}
                  </StyledColumnPassengersTotal>
                </StyledBookingColumn>
              </StyledBookingRow>

              {/* Price */}
              <StyledBookingRow style={{ marginTop: "14px" }}>
                <StyledBookingColumn>
                  <StyledBookingColumnTitle>
                    {t("price")}
                  </StyledBookingColumnTitle>
                </StyledBookingColumn>

                <StyledBookingColumn>
                  <StledPriceColumn>
                    <StledPriceColumnRow>
                      <StledPriceTotal>    
                        {getCurrencyIcon(booking?.price?.currency)}
                        {price}
                      </StledPriceTotal>

                      {status(booking)}
                    </StledPriceColumnRow>

                    <StledPriceColumnRow className="noGap">
                      <StledPriceDebt>-</StledPriceDebt>
                      <StledPricePaid>-</StledPricePaid>
                    </StledPriceColumnRow>
                  </StledPriceColumn>
                </StyledBookingColumn>
              </StyledBookingRow>

              {/* Payment Schedule */}
              <StyledBookingRow
                style={{
                  borderTop: "1px solid #D9D9D9",
                  marginTop: "12px",
                  paddingTop: "12px",
                }}
              >
                <StyledBookingColumn>
                  <StyledBookingColumnTitle>
                    {t("paymentSchedule")}
                  </StyledBookingColumnTitle>
                </StyledBookingColumn>
                <StyledColumnPaymentSchedule>
                  {paymentSchedule?.map((schedule, index) => {
                    const paymentSchedulePrice = (schedule?.amount.amount / 100)?.toString()?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
                    return (
                      <StyledBookingColumn key={index}>
                        <StyledColumnColumnDescription>
                          {getCurrencyIcon(schedule.amount.currency)} {paymentSchedulePrice}
                        </StyledColumnColumnDescription>

                        <StyledColumnColumnDateTime>
                          until {moment(schedule?.dueDate, 'YYYY-MM-DD hh:mm').format('DD MMM, YY')}
                        </StyledColumnColumnDateTime>
                      </StyledBookingColumn>
                    )
                  })}
                </StyledColumnPaymentSchedule>
              </StyledBookingRow>
            </StyledBookingRowWrapper>
          );
        })}
      </StyledBookingsList>

      <StyledPaginationWrapper>
        <Stack spacing={0}>
          <Pagination
            variant="outlined"
            shape="rounded"
            count={pageCount}
            page={page}
            onChange={handleChange}
          />
        </Stack>
      </StyledPaginationWrapper>
    </StyledBookingsMobile>
  );
};
