import styled from 'styled-components'
import { mainTheme } from "../../theme"

// Бронирования (только mobile)
export const StyledBookingsMobile = styled.div`
  display: none;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    display: flex;
    flex-direction: column;
  }
`

export const StyledBookingsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
`

export const StyledBookingRowWrapper = styled.div`
  background: ${mainTheme.main.colors.white};
  padding: 16px;
  margin-top: 12px;
  box-shadow: 0px 3px 14px -6px rgba(0,0,0,0.40);

  &:first-child {
    margin-top: 0;
  }
`
export const StyledBookingRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 4px;
`

export const StyledBookingColumn = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 4px;

  &:last-child {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: column;
    gap: 4px;
  }
  
  &.lastColumn {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: column;
    gap: 4px;
  }

`

export const StyledBookingColumnTitle = styled.div`
  color: ${mainTheme.main.colors.merin};
  font-size: ${mainTheme.main.fontSizes.small};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
`

export const StyledColumnColumnDescription = styled.div`
  a {
    color: ${mainTheme.main.colors.black};
    font-size: 15px;
    font-weight: ${mainTheme.main.fontWeights.normal};
    font-family: ${mainTheme.main.fonts.dmsRegular};
  }
`

export const StyledColumnColumnDateTime = styled.div`
  color: ${mainTheme.main.colors.merin};
  font-size: ${mainTheme.main.fontSizes.xsmall};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
`

export const StyledBookindStatus = styled.div`
  border-radius: 4px;
  background: ${mainTheme.main.colors.blueLigth};
  padding: 3px 12px;
  color: ${mainTheme.main.colors.blueFantom};
  font-size: ${mainTheme.main.fontSizes.xsmall};
  font-weight: ${mainTheme.main.fontWeights.boldExtra};
  font-family: ${mainTheme.main.fonts.dmsRegular};
`

export const StyledNameShip = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-size: ${mainTheme.main.fontSizes.xsmall};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.interRegular};
  text-decoration: underline;
  text-transform: uppercase;
`

export const StyledCodeShip = styled.div`
  color: ${mainTheme.main.colors.merin};
  font-size: 11px;
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
`

export const StyledRouteWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  border-top: 2px solid ${mainTheme.main.colors.yellow};
  width: 100%;
  margin-top: 10px;
`

export const StyledRouteStart = styled.div`
  position: relative;
  font-size: 15px;
  font-weight: ${mainTheme.main.fontWeights.bold};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  color: ${mainTheme.main.colors.black};
  margin-top: 5px;

  &:before {
    content: '';
    position: absolute;
    top: -11px;
    left: 0px;
    display: flex;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background: ${mainTheme.main.colors.white};
    border: 2px solid ${mainTheme.main.colors.yellow};
  }
`

export const StyledRouteEnd = styled.div`
  position: relative;
  color: ${mainTheme.main.colors.black};
  font-size: 15px;
  font-weight: ${mainTheme.main.fontWeights.bold};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  margin-top: 5px;

  &:before {
    content: '';
    position: absolute;
    top: -11px;
    right: 0px;
    display: flex;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background: ${mainTheme.main.colors.white};
    border: 2px solid ${mainTheme.main.colors.yellow};
  }
`

export const StyledRouteName = styled.div`
  color: ${mainTheme.main.colors.black};
  font-size: 15px;
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
`

export const StyledColumnRoomWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`

export const StyledPlace = styled.div`
  background: #e0e8eb;
  border-radius: 4px;
  padding: 2px 6px;
  color: ${mainTheme.main.colors.merin};
  font-size: ${mainTheme.main.fontSizes.xsmall};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.interRegular};
`

export const StyledColumnPassengersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: flex-end;
`

export const StyledColumnPassengersTotal = styled.div`
  color: ${mainTheme.main.colors.grayOpacity};
  font-size: 15px;
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
`

export const StledPriceColumn = styled.div`
  display: flex;
  flex-direction: column;
`

export const StledPriceColumnRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;

  &.noGap {
    gap: 4px;
    justify-content: flex-end;
    margin-top: 4px;
  }
`

export const StledPriceTotal = styled.div`
  color: ${mainTheme.main.colors.black};
  font-size: 15px;
  font-weight: ${mainTheme.main.fontWeights.bold};
  font-family: ${mainTheme.main.fonts.dmsRegular};
`

export const StledPriceStatus = styled.div`
  padding: 4px 6px;
  color: ${mainTheme.main.colors.olivic};
  font-size: ${mainTheme.main.fontSizes.xsmall};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.interRegular};
  display: flex;
  justify-content: center;
`

export const StledPriceDebt = styled.div`
  color: ${mainTheme.main.colors.red};
  font-size: 9px;
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
`

export const StledPricePaid = styled.div`
  color: ${mainTheme.main.colors.grayOpacity};
  font-size: 9px;
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
`

export const StyledColumnPaymentSchedule = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`

export const StyledTableCellStatusTitle = styled.div`
  color: ${mainTheme.main.colors.merin};
  font-size: ${mainTheme.main.fontSizes.xsmall};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  vertical-align: baseline;
  padding: 4px 6px;
  border-radius: 4px;

  &.NOT_PAID {
    background: #fde3eb;
    color: #9E2044;
  }

  &.PAID {
    background: #A5FC8F;
    color: #088905;
  }

  &.PARTIALLY {
    background: #E3F097;
    color: #5C732C;
  }

  &.OVERPAID {
    background: #FCDBFF;
    color: #971CA1;
  }
`

export const StyledPaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  .MuiButtonBase-root {
    background: ${mainTheme.main.colors.white};
    margin: 0;
    color: ${mainTheme.main.colors.blue};
    font-size: ${mainTheme.main.fontSizes.medium};
    font-weight: ${mainTheme.main.fontWeights.normal};
    font-family: ${mainTheme.main.fonts.dmsRegular};
    width: 42px;
    height: 35px;
    border-radius: 0px;
    border: 1px solid ${mainTheme.main.colors.graySilver};

    &.Mui-selected {
      background: ${mainTheme.main.colors.blue};
      color: ${mainTheme.main.colors.white};
      border: 1px solid ${mainTheme.main.colors.blue};
    }
  }
`