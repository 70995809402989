import { Box } from "@mui/material";
import styled from "styled-components";
import { mainTheme } from "../../theme";


export const StyledFieldWrapper = styled(Box)`
  position: relative;
  width: 100%;
  display: flex;

  fieldset {
    display: none;
  }

  .MuiTextField-root {
    width: 100%;
  }

  .MuiFormLabel-root {
    padding-left: 20px;
    border: none;
    outline: none;
  }

  .MuiInputBase-root {
    border: 1px solid ${mainTheme.main.colors.merin};
    border-radius: 1px;
    height: 44px;
    outline: none;
    min-width: 100%;
  }

  .MuiInputBase-input {
    padding-left: 40px;
    color: ${mainTheme.main.colors.black};
    font-size: 13px;
    font-weight: ${mainTheme.main.fontWeights.normal};
    font-family: ${mainTheme.main.fonts.dmsRegular};
    height: 44px;
    outline: none;
    border: none;
  }
`

export const StyledIconWrapper = styled.div`
  position: absolute;
  top: 7px;
  left: 5px;
`