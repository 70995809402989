import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { StyledAccordionBookingTitle, StyledAccordionBookingWrapper } from "./AccordionBooking.styles";
import { AccordionBookingProps } from "./types";

export const AccordionBooking = ({ title, children }: AccordionBookingProps) => {
  return (
    <StyledAccordionBookingWrapper>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <StyledAccordionBookingTitle>
            {title}
          </StyledAccordionBookingTitle>
        </AccordionSummary>
        <AccordionDetails className="accordionContent">
          {children}
        </AccordionDetails>
      </Accordion>
    </StyledAccordionBookingWrapper>
  );
};
