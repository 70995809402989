import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { MenuItem } from "@mui/material";
import { CustomSelect } from "../components/CustomSelect";
import { VariantCustomSelectEnum } from "../components/CustomSelect/types";
import { BOOT, PARKA } from "./consts";
import {
  StyledButtonCancel,
  StyledButtonSave,
  StyledButtonsWrapper,
  StyledFormFieldsWrapper,
} from "./FormEditAdditionalRequirements.styles";

export type FormEditAdditionalRequirementsdProps = {
  setOpen: (value: boolean) => void;
};

type FormEditAdditionalRequirementsInputType = {
  bootSize: string;
  parkaSize: string;
};

export const FormEditAdditionalRequirements = ({
  setOpen,
}: FormEditAdditionalRequirementsdProps) => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    bootSize: yup.string().required(t("fieldRequired")),
    parkaSize: yup.string().required(t("fieldRequired")),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormEditAdditionalRequirementsInputType>({
    resolver: yupResolver(schema),
    defaultValues: {
      bootSize: "8",
      parkaSize: "XL",
    },
  });

  const onSubmit = async (data: FormEditAdditionalRequirementsInputType) => {
    const formValue = {
      ...data,
    };
    console.log("formValue: ", formValue);
  };

  const handleCancel = () => {
    if (setOpen) {
      setOpen(false);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledFormFieldsWrapper>
          <div>
            <Controller
              control={control}
              name="bootSize"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <CustomSelect
                  label={t("bootSizeEU")}
                  onChange={onChange}
                  variant={VariantCustomSelectEnum.standard}
                  defaultValue={value}
                >
                  {BOOT?.map((item) => (
                    <MenuItem key={item.size} value={item?.size}>
                      {item.size}
                    </MenuItem>
                  ))}
                </CustomSelect>
              )}
            />
          </div>

          <div>
            <Controller
              control={control}
              name="parkaSize"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <CustomSelect
                  label={t("parkaSize")}
                  onChange={onChange}
                  variant={VariantCustomSelectEnum.standard}
                  defaultValue={value}
                >
                  {PARKA?.map((item) => (
                    <MenuItem key={item.size} value={item?.size}>
                      {item.size}
                    </MenuItem>
                  ))}
                </CustomSelect>
              )}
            />
          </div>
        </StyledFormFieldsWrapper>

        <StyledButtonsWrapper>
          <StyledButtonCancel variant="contained" onClick={handleCancel}>
            {t("cancel")}
          </StyledButtonCancel>

          <StyledButtonSave
            type="submit"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            {t("save")}
          </StyledButtonSave>
        </StyledButtonsWrapper>
      </form>
    </div>
  );
};
