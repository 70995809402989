import { Button } from '@mui/material'
import styled from 'styled-components'
import { mainTheme } from "../../theme"

export const StyledStateroomsWrapper = styled.div`
  background: ${mainTheme.main.colors.white};
  padding: 16px;
  box-shadow: 0px 3px 14px -6px rgba(0,0,0,0.40);
  margin-top: 12px;
`

export const StyledStateroomsSectionTitle = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-weight: ${mainTheme.main.fontWeights.bold};
  font-family: ${mainTheme.main.fonts.chapRegular};
  font-size: ${mainTheme.main.fontSizes.large};
  margin-bottom: 14px;

  &.guest {
    margin-top: 20px;
    margin-bottom: 0px;
  }
`

export const StyledStateroomsCabinsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 14px;
  margin-bottom: 20px;
`

export const StyledStateroomsCabinButton = styled(Button)`
  &.MuiButtonBase-root {
    background: ${mainTheme.main.colors.white};
    border-radius: 0px;

    color: ${mainTheme.main.colors.grayOpacity};
    font-weight: ${mainTheme.main.fontWeights.bold};
    font-family: ${mainTheme.main.fonts.dmsRegular};
    font-size: 16px;
    min-width: auto;
    padding: 0px;
  }

  &.active {
    &.MuiButtonBase-root {
      color: ${mainTheme.main.colors.yellow};
      font-weight: ${mainTheme.main.fontWeights.bold};
      border-bottom: 3px solid ${mainTheme.main.colors.yellow};
    }
  }
`

export const StyledStateroomsColumnsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 20px; 
`

export const StyledStateroomsColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

export const StyledStateroomsColumnTitle = styled.div`
  color: ${mainTheme.main.colors.merin};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  font-size: ${mainTheme.main.fontSizes.small};
`

export const StyledStateroomsColumnContent = styled.div`
  color: ${mainTheme.main.colors.black};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  font-size: ${mainTheme.main.fontSizes.medium};
`

export const StyledStateroomsColumnEditWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const StyledStateroomsColumnEditButton = styled(Button)`
  &.MuiButtonBase-root {
    background: ${mainTheme.main.colors.white};
    border-radius: 0px;
    min-width: auto;
    margin: 0;
    padding: 0;
    margin-left: 10px;
  }
`

export const StyledTextInfo = styled.div`
  color: ${mainTheme.main.colors.black};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  font-size: ${mainTheme.main.fontSizes.medium};
`

export const StyledButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 30px;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;

export const StyledButtonSave = styled(Button)`
  &.MuiButton-root {
    border-radius: 24px;
    color: ${mainTheme.main.colors.white};
    font-size: ${mainTheme.main.fontSizes.large};
    font-weight: ${mainTheme.main.fontWeights.normal};
    font-family: ${mainTheme.main.fonts.dmsRegular};
    background: ${mainTheme.main.colors.blue};
    width: 153px;
    border: 1px solid ${mainTheme.main.colors.blue};
    text-transform: capitalize;
    box-shadow: none;
    padding: 4px;

    &:hover {
      background: ${mainTheme.main.colors.blue};
    }

    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
      width: 100%;
    }
  }
`;

export const StyledButtonCancel = styled(Button)`
  &.MuiButton-root {
    border-radius: 20px;
    color: ${mainTheme.main.colors.black};
    font-size: ${mainTheme.main.fontSizes.medium};
    font-weight: ${mainTheme.main.fontWeights.normal};
    background: ${mainTheme.main.colors.white};
    width: 138px;
    border: 1px solid ${mainTheme.main.colors.graySilver};
    box-shadow: none;

    &:hover {
      background: ${mainTheme.main.colors.white};
    }

    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
      width: 100%;
    }
  }
`;