import { RoutesLinksType } from "../../utils/routesLinks";
import { StyledLink, StyledNavigationWrapper } from "./Navigation.styles";
import { useCurrentPath } from "../../hooks/useCurrentPath";

export type NavigationProps = {
  links: RoutesLinksType[];
};

export const Navigation = ({ links }: NavigationProps) => {
  const currentPath = useCurrentPath();

  const availableLinks = links?.filter((link) => !link?.ignore)

  return (
    <StyledNavigationWrapper>
      {availableLinks?.map((item) => {
        return (
          <StyledLink
            key={item.name}
            to={item.path}
            className={
              (currentPath?.includes(item.key) && "active") ||
              (!currentPath?.length && item.key === "profile" && "active") ||
              ((currentPath?.includes("bookingDetails") && item.key === "bookings") && "active")
            }
          >
            {item.name}
          </StyledLink>
        );
      })}
    </StyledNavigationWrapper>
  );
};
