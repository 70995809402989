import styled from 'styled-components'
import { mainTheme } from "../../theme"

export const StyledMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 20px;
`

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

export const StyledHeaderColumn = styled.div`
  color: ${mainTheme.main.colors.merin};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  font-size: ${mainTheme.main.fontSizes.small};
`

export const StyledColumnContent = styled.div`
  color: ${mainTheme.main.colors.black};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  font-size: ${mainTheme.main.fontSizes.medium};
`

export const StyledWrapperOneColumn = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
`

export const StyledWrapperTwoColumns = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, 1fr) minmax(auto, 1fr);
  gap: 20px;

  &.dash {
    padding-bottom: 20px;
    border-bottom: 1px solid ${mainTheme.main.colors.graySilver}
  }
`


