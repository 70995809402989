export const TRAVELLERS_TEST_DATA = [
  {
    fullName: "Mr. William James Smith",
    passport: "308 445 572",
    loyaltyNumber: "1244 5678 9012 3456",
    phone: "+44 077 1417 0414",
    email: "j.smith@yahoo.com"
  },
  {
    fullName: "Mrs. Barbara Smith",
    passport: "308 002 634",
    loyaltyNumber: "-",
    phone: "+44 077 1248 2695",
    email: "b.smith@yahoo.com"
  },
  {
    fullName: "Mr. William James Smith",
    passport: "308 445 572",
    loyaltyNumber: "1244 5678 9012 3456",
    phone: "+44 077 1417 0414",
    email: "j.smith@yahoo.com"
  },
]

export const HEAD_CEALS = [
  {
    id: "fullName",
    numeric: false,
    disablePadding: true,
    label: "Full name",
  },
  {
    id: "passport",
    numeric: true,
    disablePadding: false,
    label: "Passport",
  },
  {
    id: "loyaltyNumber",
    numeric: true,
    disablePadding: false,
    label: "Loyalty number",
  },
  {
    id: "phone",
    numeric: true,
    disablePadding: false,
    label: "Phone",
  },
  {
    id: "email",
    numeric: true,
    disablePadding: false,
    label: "Email",
  },
];
