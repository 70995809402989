import styled from 'styled-components'
import { mainTheme } from "../../theme"

// Стили для таблицы
export const StyledTableWrapper = styled.div`
  background: ${mainTheme.main.colors.white};

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    /* display: none; */
  }

  .MuiTableCell-head {
    padding-left: 0;
    color: ${mainTheme.main.colors.white};
    font-size: ${mainTheme.main.fontSizes.small};
    font-weight: ${mainTheme.main.fontWeights.normal};
    font-family: ${mainTheme.main.fonts.dmsRegular};
    text-transform: capitalize;
    background: ${mainTheme.main.colors.merin};
    padding: 0px;

    &:first-child {
      padding-left: 8px;
    }
    
    &:last-child {
      padding-right: 8px;
    }
  }

  .MuiTableRow-root {
    &:hover {
      background: ${mainTheme.main.colors.white} !important;
    }
  }

  .MuiTableCell-body {
    padding-left: 0;
    color: ${mainTheme.main.colors.black};
    font-size: 14px;
    font-weight: ${mainTheme.main.fontWeights.normal};
    font-family: ${mainTheme.main.fonts.dmsRegular};

    &:first-child {
      padding-left: 8px;
    }
    
    &:last-child {
      padding-right: 8px;
      text-align: right;
    }
  }
`

export const StyledTableCellText = styled.div`
  color: ${mainTheme.main.colors.black};
  font-size: 14px;
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};

  &.fantomColor {
    color: ${mainTheme.main.colors.merin};
  }

  &.grayColor {
    color: ${mainTheme.main.colors.grayOpacity};
  }
`

export const StyledTwoRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

export const StyledTwoRowColumn = styled.div`
 &.fantomColor {
    color: ${mainTheme.main.colors.merin};
  }
`
