export const HEAD_CEALS = [
  {
    id: "№",
    numeric: false,
    disablePadding: true,
    label: "№",
  },
  {
    id: "guest",
    numeric: true,
    disablePadding: false,
    label: "Guest",
  },
  {
    id: "contacts",
    numeric: true,
    disablePadding: false,
    label: "Contacts",
  },
  {
    id: "additionalRequirements",
    numeric: true,
    disablePadding: false,
    label: "Additional requirements",
  },
  {
    id: "travelArrangement",
    numeric: true,
    disablePadding: false,
    label: "Travel arrangement",
  },
  {
    id: "healthDeclaration",
    numeric: true,
    disablePadding: false,
    label: "Health declaration",
  },
  {
    id: "farePrice",
    numeric: true,
    disablePadding: false,
    label: "Fare / Price",
  },
  {
    id: "Travel documents",
    numeric: true,
    disablePadding: false,
    label: "travelDocuments",
  },
];

export const STATEROOM_TRAVELERS_DATA = [
  {
    id: "123",
    initials: "Mr",
    fio: "William James Smith",
    birth: "25.07.1975",
    country: "UK",
    document: {
      statusSuccessful: true,
      series: "30**",
      number: "**72",
    },
    loyalty: "123456789",
    emergency: {
      statusSuccessful: true,
      phone: "+44 077 1417 0414",
      email: "j.smith@yahoo.com",
    },
    additional: {
      statusSuccessful: true,
      boot: {
        bootSizeType: "EU",
        size: "8",
      },
      parka: {
        size: "XL",
      },
    },
    arrangement: {
      statusSuccessful: true,
    },
    health: {
      statusSuccessful: true,
    },
    price: {
      fare: "Door-to-door",
      total: "$ 55,650",
    },
    documents: "",
  },
  {
    id: "456",
    initials: "Msr",
    fio: "Barbara Smith",
    birth: "25.07.1990",
    country: "FR",
    document: {
      statusSuccessful: false,
      series: "40**",
      number: "**32",
    },
    loyalty: "371923721",
    emergency: {
      statusSuccessful: false,
      phone: "+44 071 1433 0431",
      email: "test@test.com",
    },
    additional: {
      boot: {
        bootSizeType: "EU",
        size: "3",
      },
      parka: {
        size: "S",
      },
    },
    arrangement: {
      statusSuccessful: false,
    },
    health: {
      statusSuccessful: false,
    },
    price: {
      fare: "Door-to-door",
      total: "$ 80,200",
    },
    documents: "",
  },
];

export const STATEROOM_CABINS_NUMBER = [
  {
    id: "1",
    number: "105"
  },
  {
    id: "2",
    number: "106"
  },
  {
    id: "3",
    number: "107"
  }
];

export const STATEROOM_DATA = {
  id: "12021",
  number: "105",
  deck: "6",
  category: "Premium suite",
  bedType: "Single sofa bed or extra bed",
};
