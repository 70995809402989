import { Dialog } from "@mui/material";
import styled, { css } from "styled-components";
import { mainTheme } from "../../theme";

export const StyledTitle = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-size: ${mainTheme.main.fontSizes.large};
  font-weight: ${mainTheme.main.fontWeights.bold};
  font-family: ${mainTheme.main.fonts.chapRegular};
  text-transform: uppercase;
  padding: 20px;
`;

export const StyledModal = styled(Dialog)<{ size?: string }>`
  .MuiPaper-root {
    min-width: 320px;
    width: 100%;
    box-sizing: border-box;

    ${(props) => css`
      ${props.size
        ? css`
            max-width: ${props.size};
          `
        : css`
            max-width: 600px;
          `}
    `}
  }

  .MuiDialogContent-root {
    padding: 0px 20px 20px 20px;
    box-sizing: border-box;
  }

  .MuiDialogActions-root {
    padding: 20px;
  }
`;
