import { createGlobalStyle } from "styled-components";
import DMSansRegural from "../fonts/dmSans/DMSans-Regular.ttf";
import ChapRegular from "../fonts/chap/ChapRegular.ttf";
import InterRegular from "../fonts/inter/Inter-Regular.ttf";
import ArsenalRegular from "../fonts/arsenal/Arsenal-Regular.ttf"
import ArsenalBold from "../fonts/arsenal/Arsenal-Bold.ttf"
import { mainTheme } from "../theme";

const FontStyles = createGlobalStyle`

@font-face {
  font-family: 'DMSansRegural';
  src: url(${DMSansRegural});
  src: url(${DMSansRegural}) format('ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'ChapRegular';
  src: url(${ChapRegular});
  src: url(${ChapRegular}) format('ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'InterRegular';
  src: url(${InterRegular});
  src: url(${InterRegular}) format('ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'ArsenalRegular';
  src: url(${ArsenalRegular});
  src: url(${ArsenalRegular}) format('ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'ArsenalBold';
  src: url(${ArsenalBold});
  src: url(${ArsenalBold}) format('ttf');
  font-weight: 700;
  font-style: normal;
}

body {
  font-family: DMSansRegural !important;
  background: ${mainTheme.main.colors.whiteGray};
  margin: 0;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    /* background: #fefefe; */
  }
}
`

export default FontStyles;