export const TRANSACTIONS_TEST_DATA = [
  {
    dateTime: "12.05.2023 - 17:20",
    operationType: "Bonus Accrual",
    bookingCode: "16920-23",
    bonuses: {
      number: "+ 808",
      up: true
    }
  },
  {
    dateTime: "12.05.2023 - 17:22",
    operationType: "Bonus Accrual",
    bookingCode: "16920-23",
    bonuses: {
      number: " - 608",
      up: false
    }
  },
  {
    dateTime: "12.05.2023 - 17:30",
    operationType: "Bonus Accrual",
    bookingCode: "16920-24",
    bonuses: {
      number: " - 808",
      up: false
    }
  }
]

export const HEAD_CEALS = [
  {
    id: "dateBooking",
    numeric: false,
    disablePadding: true,
    label: "Date / Time",
  },
  {
    id: "operationType",
    numeric: true,
    disablePadding: false,
    label: "Operation / Types",
  },
  {
    id: "bookingCode",
    numeric: true,
    disablePadding: false,
    label: "Booking code",
  },
  {
    id: "bonuses",
    numeric: true,
    disablePadding: false,
    label: "Bonuses",
  },
];
