import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ADDITIONAL_SERVICES } from "../AdditionalServices/consts";
import { IconDelete } from "../../icons/IconDelete";
import { ModalInfo } from "../ModalInfo";
import { ServiceDetails } from "../ServiceDetails/ServiceDetails";
import {
  AdditionalServicesMobileWrapper,
  StyledAdditionalServiceItem,
  StyledAdditionalServiceRow,
  StyledAdditionalServiceRowItem,
  StyledAdditionalServiceRowItemText,
  StyledAdditionalServiceRowWrapper,
  StyledAdditionalServicesSectionTitle,
  StyledAdditionalServiceTypeName,
  StyledButtonCancel,
  StyledButtonDelete,
  StyledButtonDeleteWrapper,
  StyledButtonSave,
  StyledButtonsWrapper,
  StyledModalContent,
  StyledModalContentTwoColumns,
  StyledNameService,
} from "./AdditionalServicesMobile.styles";

export const AdditionalServicesMobile = () => {
  const { t } = useTranslation();

  const [selectedService, setSelectedService] = useState();

  const [openModalDetails, setOpenModalDetails] = useState(false);
  const [openModalCancelService, setOpenModalCancelService] = useState(false);
  const [openModalSuccessfully, setOpenModalSuccessfully] = useState(false);

  const handleDelete = (item: any) => {
    setSelectedService(item);
    setOpenModalCancelService(true);
  };

  const handleYes = () => {
    setOpenModalCancelService(false);
    setOpenModalSuccessfully(true);
  };

  console.log("selectedService: ", selectedService);

  return (
    <AdditionalServicesMobileWrapper>
      <StyledAdditionalServicesSectionTitle>
        {t("additionalServices")}
      </StyledAdditionalServicesSectionTitle>
      {ADDITIONAL_SERVICES?.map((item: any, index) => (
        <StyledAdditionalServiceItem key={item?.title}>
          <StyledAdditionalServiceTypeName>
            {t(item.title)}
          </StyledAdditionalServiceTypeName>

          <StyledAdditionalServiceRowWrapper>
            {item?.services.map((service: any) => (
              <StyledAdditionalServiceRow>
                <StyledAdditionalServiceRowItem className="name">
                  <StyledNameService
                    onClick={() => setOpenModalDetails(true)}
                  >
                    {service.name}
                  </StyledNameService>
                  <StyledButtonDeleteWrapper>
                    <StyledButtonDelete onClick={() => handleDelete(service)}>
                      <IconDelete />
                    </StyledButtonDelete>
                  </StyledButtonDeleteWrapper>
                </StyledAdditionalServiceRowItem>

                <StyledAdditionalServiceRowItem className="borderRow">
                  <div>{t("fullName")}</div>
                  <StyledAdditionalServiceRowItemText>
                    {service.traveller}
                  </StyledAdditionalServiceRowItemText>
                </StyledAdditionalServiceRowItem>

                <StyledAdditionalServiceRowItem className="borderRow">
                  <div>{t("price")}</div>
                  <StyledAdditionalServiceRowItemText>
                    {service.price}
                  </StyledAdditionalServiceRowItemText>
                </StyledAdditionalServiceRowItem>
              </StyledAdditionalServiceRow>
            ))}
          </StyledAdditionalServiceRowWrapper>
        </StyledAdditionalServiceItem>
      ))}

      {/* Модальное окно "Details" */}
      <ModalInfo
        title="Details"
        open={openModalDetails}
        setOpen={(value: boolean) => setOpenModalDetails(value)}
      >
        <ServiceDetails />
      </ModalInfo>

      {/* Модальное окно "Cancel Service" */}
      <ModalInfo
        title="cancelService"
        open={openModalCancelService}
        setOpen={(value: boolean) => setOpenModalCancelService(value)}
      >
        <StyledModalContent>{t("questionCancel")}</StyledModalContent>

        <StyledButtonsWrapper>
          <StyledButtonCancel onClick={() => setOpenModalCancelService(false)}>
            {t("cancel")}
          </StyledButtonCancel>
          <StyledButtonSave onClick={handleYes}>{t("yes")}</StyledButtonSave>
        </StyledButtonsWrapper>
      </ModalInfo>

      {/* Модальное окно "Your request has been successfully..." */}
      <ModalInfo
        title=""
        open={openModalSuccessfully}
        setOpen={(value: boolean) => setOpenModalSuccessfully(value)}
      >
        <StyledModalContentTwoColumns>
          <StyledModalContent>
            {t("yourRequestBeenSuccessfully")}
          </StyledModalContent>

          <StyledButtonSave onClick={() => setOpenModalSuccessfully(false)}>
            {t("close")}
          </StyledButtonSave>
        </StyledModalContentTwoColumns>
      </ModalInfo>
    </AdditionalServicesMobileWrapper>
  );
};
