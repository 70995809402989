import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { ModalInfoProps } from "./types";
import {
  StyledModal,
  StyledTitle,
} from "./ModalInfo.styles";

const BootstrapDialog = styled(Dialog)(({ size }: any) => ({
  "& .MuiPaper-root": {
    minWidth: "320px",
    width: "100%",
    boxSizing: "border-box",
  },

  "& .MuiDialogContent-root": {
    padding: "0px 20px 20px 20px",
    boxSizing: "border-box",
  },

  "& .MuiDialogActions-root": {
    padding: "20px",
  },
}));

export const ModalInfo = ({
  title,
  open,
  setOpen,
  children,
  size,
}: ModalInfoProps) => {
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <StyledModal onClose={handleClose} open={open} size={size}>
      <StyledTitle>{t(title)}</StyledTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent>{children}</DialogContent>
    </StyledModal>
  );
};
