import { useTranslation } from "react-i18next";
import { StyledBookingDetailsInfoColumn, StyledBookingDetailsInfoColumnContent, StyledBookingDetailsInfoColumns, StyledBookingDetailsInfoHeaderColumn, StyledBookingDetailsInfoHeaderColumns, StyledBookingDetailsInfoWrapper } from "./BookingDetailsInfo.styles"
import { BOOKING_DETAILS_INFO_DATA } from "./consts"

export const BookingDetailsInfo = () => {
  const { t } = useTranslation();
  
  return (
    <StyledBookingDetailsInfoWrapper>
      <StyledBookingDetailsInfoColumns>
        <StyledBookingDetailsInfoColumn>
          <StyledBookingDetailsInfoHeaderColumn>
            {t("bookingCode")}
          </StyledBookingDetailsInfoHeaderColumn>
          <StyledBookingDetailsInfoColumnContent className="code">
            {BOOKING_DETAILS_INFO_DATA.bookingCode.value}
          </StyledBookingDetailsInfoColumnContent>
        </StyledBookingDetailsInfoColumn>

        <StyledBookingDetailsInfoColumn>
          <StyledBookingDetailsInfoHeaderColumn>
            {t("status")}
          </StyledBookingDetailsInfoHeaderColumn>
          <StyledBookingDetailsInfoColumnContent className="status">
            {BOOKING_DETAILS_INFO_DATA.status.value}
          </StyledBookingDetailsInfoColumnContent>
        </StyledBookingDetailsInfoColumn>

        <StyledBookingDetailsInfoColumn>
          <StyledBookingDetailsInfoHeaderColumn>
            {t("bookedAt")}
          </StyledBookingDetailsInfoHeaderColumn>
          <StyledBookingDetailsInfoColumnContent>
            {BOOKING_DETAILS_INFO_DATA.bookedAt.value}
          </StyledBookingDetailsInfoColumnContent>
        </StyledBookingDetailsInfoColumn>

        <StyledBookingDetailsInfoColumn>
          <StyledBookingDetailsInfoHeaderColumn>
            {t("bookingTimeLimit")}
          </StyledBookingDetailsInfoHeaderColumn>
          <StyledBookingDetailsInfoColumnContent className="timeLimit">
            {BOOKING_DETAILS_INFO_DATA.bookingTimeLimit.value}
          </StyledBookingDetailsInfoColumnContent>
        </StyledBookingDetailsInfoColumn>
      </StyledBookingDetailsInfoColumns>
    </StyledBookingDetailsInfoWrapper>
  )
}