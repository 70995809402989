import { useState } from "react";
import { useTranslation } from "react-i18next";
import IconEdit from "../../icons/IconEdit";
import { IconLoylaty } from "../../icons/IconLoylaty";
import { IconVerifySuccess } from "../../icons/IconVerifySuccess";
import { IconVerifyError } from "../../icons/IconVerifyError";
import { IconDownloadDocuments } from "../../icons/IconDownloadDocuments";
import {
  StyledButtonDownload,
  StyledDetails,
  StyledDetailsWrapper,
  StyledGuestDataItem,
  StyledGuestDataItemContent,
  StyledGuestDataItemTitle,
  StyledGuestDataItemWrapper,
  StyledGuestDataWrapper,
  StyledGuestNumberButton,
  StyledSelectedGuestWrapper,
  StyledStateroomsCabinButton,
  StyledStateroomsCabinsWrapper,
  StyledStateroomsColumn,
  StyledStateroomsColumnContent,
  StyledStateroomsColumnEditButton,
  StyledStateroomsColumnEditWrapper,
  StyledStateroomsColumnsWrapper,
  StyledStateroomsColumnTitle,
  StyledStateroomsSectionTitle,
  StyledStateroomsWrapper,
} from "./StateroomsMobile.styles";
import {
  STATEROOM_CABINS_NUMBER,
  STATEROOM_DATA,
  STATEROOM_TRAVELER_DATA,
} from "./consts";
import { CurrentEditFormType } from "../../utils/globalTypes";
import { TypeFormEnum } from "../ModalFormEdit/types";
import { ModalFormEdit } from "../ModalFormEdit";
import {
  StyledButtonCancel,
  StyledButtonSave,
  StyledButtonsWrapper,
  StyledTextInfo,
} from "../Staterooms/Staterooms.styles";

export const StateroomsMobile = () => {
  const { t } = useTranslation();

  const [currentCabin, setCurrentCabin] = useState<any>(
    STATEROOM_CABINS_NUMBER[0]
  );

  const [currentEditForm, setCurrentEditForm] = useState<CurrentEditFormType>({
    title: "",
    typeEditForm: "",
  });

  const handleClickEdit = (value: { title: string; typeEditForm: string }) => {
    setCurrentEditForm(value);
  };

  console.log("currentEditForm: ", currentEditForm);

  return (
    <StyledStateroomsWrapper>
      <StyledStateroomsSectionTitle>
        {t("staterooms")}
      </StyledStateroomsSectionTitle>

      {/* Секция с номер кают */}
      <StyledStateroomsCabinsWrapper>
        {STATEROOM_CABINS_NUMBER?.map((item) => (
          <StyledStateroomsCabinButton
            className={item?.number === currentCabin?.number ? "active" : ""}
            onClick={() => setCurrentCabin(item)}
          >
            {item?.number}
          </StyledStateroomsCabinButton>
        ))}
      </StyledStateroomsCabinsWrapper>

      {/* Секция с информацией о выбраной каюте */}

      {/* Stateroom number / Deck */}
      <StyledStateroomsColumnsWrapper>
        <StyledStateroomsColumn>
          <StyledStateroomsColumnTitle>
            {t("stateRoomNumber")}
          </StyledStateroomsColumnTitle>
          <StyledStateroomsColumnContent>
            {STATEROOM_DATA?.number}
          </StyledStateroomsColumnContent>
        </StyledStateroomsColumn>

        <StyledStateroomsColumn className="last">
          <StyledStateroomsColumnTitle>{t("deck")}</StyledStateroomsColumnTitle>
          <StyledStateroomsColumnContent>
            {STATEROOM_DATA?.deck}
          </StyledStateroomsColumnContent>
        </StyledStateroomsColumn>
      </StyledStateroomsColumnsWrapper>

      {/* Category / Bed type */}
      <StyledStateroomsColumnsWrapper>
        <StyledStateroomsColumn>
          <StyledStateroomsColumnTitle>
            {t("category")}
          </StyledStateroomsColumnTitle>
          <StyledStateroomsColumnContent>
            {STATEROOM_DATA?.category}
          </StyledStateroomsColumnContent>
        </StyledStateroomsColumn>

        <StyledStateroomsColumn className="last">
          <StyledStateroomsColumnEditWrapper>
            <StyledStateroomsColumnTitle>
              {t("bedType")}
            </StyledStateroomsColumnTitle>
            <StyledStateroomsColumnEditButton
              onClick={() =>
                setCurrentEditForm({
                  title: "Additional requirements",
                  typeEditForm: TypeFormEnum.bedType,
                })
              }
            >
              <IconEdit />
            </StyledStateroomsColumnEditButton>
          </StyledStateroomsColumnEditWrapper>

          <StyledStateroomsColumnContent>
            {STATEROOM_DATA?.bedType}
          </StyledStateroomsColumnContent>
        </StyledStateroomsColumn>
      </StyledStateroomsColumnsWrapper>

      <StyledStateroomsSectionTitle className="guest">
        {t("guest")}
      </StyledStateroomsSectionTitle>

      <StyledSelectedGuestWrapper>
        <StyledGuestNumberButton>1</StyledGuestNumberButton>
        <StyledGuestNumberButton>2</StyledGuestNumberButton>
        <StyledGuestNumberButton>3</StyledGuestNumberButton>
      </StyledSelectedGuestWrapper>

      <StyledGuestDataWrapper>
        <>
          {/* Full name */}
          <StyledGuestDataItemWrapper>
            <StyledGuestDataItem>
              <StyledGuestDataItemTitle>
                {t("fullName")}
              </StyledGuestDataItemTitle>
              <StyledGuestDataItemContent>
                {STATEROOM_TRAVELER_DATA?.fio}
              </StyledGuestDataItemContent>
            </StyledGuestDataItem>

            <StyledGuestDataItem>
              <StyledStateroomsColumnEditButton
                onClick={() =>
                  handleClickEdit({
                    title: "traveller",
                    typeEditForm: TypeFormEnum.traveller,
                  })
                }
              >
                <IconEdit />
              </StyledStateroomsColumnEditButton>
            </StyledGuestDataItem>
          </StyledGuestDataItemWrapper>

          {/* Date of birth / Citizenship */}
          <StyledGuestDataItemWrapper>
            <StyledGuestDataItem>
              <StyledGuestDataItemTitle>
                {t("dateOfBirth")}
              </StyledGuestDataItemTitle>
              <StyledGuestDataItemContent>
                {STATEROOM_TRAVELER_DATA?.birth}
              </StyledGuestDataItemContent>
            </StyledGuestDataItem>

            <StyledGuestDataItem className="last">
              <StyledGuestDataItemTitle>
                {t("citizenship")}
              </StyledGuestDataItemTitle>
              <StyledGuestDataItemContent>
                {STATEROOM_TRAVELER_DATA?.country}
              </StyledGuestDataItemContent>
            </StyledGuestDataItem>
          </StyledGuestDataItemWrapper>

          {/* Passport / Loyalty number */}
          <StyledGuestDataItemWrapper>
            <StyledGuestDataItem>
              <StyledGuestDataItemTitle>
                {t("passport")}
              </StyledGuestDataItemTitle>
              <StyledGuestDataItemContent>
                {STATEROOM_TRAVELER_DATA?.document.series}
                {STATEROOM_TRAVELER_DATA?.document.number}
              </StyledGuestDataItemContent>
            </StyledGuestDataItem>

            <StyledGuestDataItem className="last">
              <StyledGuestDataItemTitle>
                {t("loyaltyNumber")}
              </StyledGuestDataItemTitle>
              <StyledGuestDataItemContent>
                <IconLoylaty />
                {STATEROOM_TRAVELER_DATA?.loyalty}
              </StyledGuestDataItemContent>
            </StyledGuestDataItem>
          </StyledGuestDataItemWrapper>

          {/* Phone / Email */}
          <StyledGuestDataItemWrapper>
            <StyledGuestDataItem>
              <StyledGuestDataItemTitle>{t("phone")}</StyledGuestDataItemTitle>
              <StyledGuestDataItemContent>
                {STATEROOM_TRAVELER_DATA?.phone}
              </StyledGuestDataItemContent>
            </StyledGuestDataItem>

            <StyledGuestDataItem className="last">
              <StyledGuestDataItemTitle>{t("email")}</StyledGuestDataItemTitle>
              <StyledGuestDataItemContent>
                <IconLoylaty />
                {STATEROOM_TRAVELER_DATA?.email}
              </StyledGuestDataItemContent>
            </StyledGuestDataItem>
          </StyledGuestDataItemWrapper>

          {/* Emergency contact */}
          <StyledGuestDataItemWrapper className="borderRow">
            <StyledGuestDataItem>
              <StyledGuestDataItemTitle>
                {t("emergencyContact")}
              </StyledGuestDataItemTitle>
            </StyledGuestDataItem>

            <StyledGuestDataItem>
              <StyledGuestDataItemContent>
                <StyledDetailsWrapper>
                  {STATEROOM_TRAVELER_DATA?.emergency?.statusSuccessful ? (
                    <div>{<IconVerifySuccess />}</div>
                  ) : (
                    <div>{<IconVerifyError />}</div>
                  )}
                  <StyledDetails
                    onClick={() =>
                      handleClickEdit({
                        title: "Emergency contact details",
                        typeEditForm: TypeFormEnum.emergencyContact,
                      })
                    }
                  >
                    {t("details")}
                  </StyledDetails>
                </StyledDetailsWrapper>
              </StyledGuestDataItemContent>
            </StyledGuestDataItem>
          </StyledGuestDataItemWrapper>

          {/* Additional requirements */}
          <StyledGuestDataItemWrapper className="borderRow">
            <StyledGuestDataItem>
              <StyledGuestDataItemTitle>
                {t("additionalRequirements")}
              </StyledGuestDataItemTitle>
            </StyledGuestDataItem>

            <StyledGuestDataItem>
              <StyledGuestDataItemContent>
                <StyledDetailsWrapper>
                  {STATEROOM_TRAVELER_DATA?.emergency?.statusSuccessful ? (
                    <div>{<IconVerifySuccess />}</div>
                  ) : (
                    <div>{<IconVerifyError />}</div>
                  )}
                  <StyledDetails
                    onClick={() =>
                      handleClickEdit({
                        title: "Additional requirements",
                        typeEditForm: TypeFormEnum.additionalRequirements,
                      })
                    }
                  >
                    {t("details")}
                  </StyledDetails>
                </StyledDetailsWrapper>
              </StyledGuestDataItemContent>
            </StyledGuestDataItem>
          </StyledGuestDataItemWrapper>

          {/* Boot Size EU */}
          <StyledGuestDataItemWrapper className="borderRow">
            <StyledGuestDataItem>
              <StyledGuestDataItemTitle>
                {t("bootSizeEU")}
              </StyledGuestDataItemTitle>
            </StyledGuestDataItem>

            <StyledGuestDataItem>
              <StyledGuestDataItemContent>
                {STATEROOM_TRAVELER_DATA.additional.boot.size}
              </StyledGuestDataItemContent>
            </StyledGuestDataItem>
          </StyledGuestDataItemWrapper>

          {/* Parka size */}
          <StyledGuestDataItemWrapper className="borderRow">
            <StyledGuestDataItem>
              <StyledGuestDataItemTitle>
                {t("parkaSize")}
              </StyledGuestDataItemTitle>
            </StyledGuestDataItem>

            <StyledGuestDataItem>
              <StyledGuestDataItemContent>
                {STATEROOM_TRAVELER_DATA.additional.parka.size}
              </StyledGuestDataItemContent>
            </StyledGuestDataItem>
          </StyledGuestDataItemWrapper>

          {/* Travel arrangement */}
          <StyledGuestDataItemWrapper className="borderRow">
            <StyledGuestDataItem>
              <StyledGuestDataItemTitle>
                {t("travelArrangement")}
              </StyledGuestDataItemTitle>
            </StyledGuestDataItem>

            <StyledGuestDataItem>
              <StyledGuestDataItemContent>
                <StyledDetailsWrapper>
                  {STATEROOM_TRAVELER_DATA?.emergency?.statusSuccessful ? (
                    <div>{<IconVerifySuccess />}</div>
                  ) : (
                    <div>{<IconVerifyError />}</div>
                  )}
                  <StyledDetails
                    onClick={() =>
                      handleClickEdit({
                        title:
                          "Details of travel arrangements booked independently of Swan Hellenic",
                        typeEditForm: TypeFormEnum.travelArrangement,
                      })
                    }
                  >
                    {t("details")}
                  </StyledDetails>
                </StyledDetailsWrapper>
              </StyledGuestDataItemContent>
            </StyledGuestDataItem>
          </StyledGuestDataItemWrapper>

          {/* Health declaration */}
          <StyledGuestDataItemWrapper className="borderRow">
            <StyledGuestDataItem>
              <StyledGuestDataItemTitle>
                {t("healthDeclaration")}
              </StyledGuestDataItemTitle>
            </StyledGuestDataItem>

            <StyledGuestDataItem>
              <StyledGuestDataItemContent>
                <StyledDetailsWrapper>
                  {STATEROOM_TRAVELER_DATA?.emergency?.statusSuccessful ? (
                    <div>{<IconVerifySuccess />}</div>
                  ) : (
                    <div>{<IconVerifyError />}</div>
                  )}
                  <StyledDetails
                    onClick={() =>
                      handleClickEdit({
                        title: "Health declaration",
                        typeEditForm: TypeFormEnum.healthDeclaration,
                      })
                    }
                  >
                    {t("details")}
                  </StyledDetails>
                </StyledDetailsWrapper>
              </StyledGuestDataItemContent>
            </StyledGuestDataItem>
          </StyledGuestDataItemWrapper>

          {/* Fare */}
          <StyledGuestDataItemWrapper className="borderRow">
            <StyledGuestDataItem>
              <StyledGuestDataItemTitle>{t("fare")}</StyledGuestDataItemTitle>
            </StyledGuestDataItem>

            <StyledGuestDataItem>
              <StyledGuestDataItemContent>
                {STATEROOM_TRAVELER_DATA.price.fare}
              </StyledGuestDataItemContent>
            </StyledGuestDataItem>
          </StyledGuestDataItemWrapper>

          {/* Price */}
          <StyledGuestDataItemWrapper className="borderRow">
            <StyledGuestDataItem>
              <StyledGuestDataItemTitle>{t("price")}</StyledGuestDataItemTitle>
            </StyledGuestDataItem>

            <StyledGuestDataItem>
              <StyledGuestDataItemContent className="boldContent">
                {STATEROOM_TRAVELER_DATA.price.total}
              </StyledGuestDataItemContent>
            </StyledGuestDataItem>
          </StyledGuestDataItemWrapper>

          {/* Travel documents */}
          <StyledGuestDataItemWrapper className="borderRow">
            <StyledGuestDataItem>
              <StyledGuestDataItemTitle>
                {t("travelDocuments")}
              </StyledGuestDataItemTitle>
            </StyledGuestDataItem>

            <StyledGuestDataItem>
              <StyledGuestDataItemContent>
                <StyledButtonDownload>
                  <IconDownloadDocuments />
                </StyledButtonDownload>
              </StyledGuestDataItemContent>
            </StyledGuestDataItem>
          </StyledGuestDataItemWrapper>
        </>
      </StyledGuestDataWrapper>

      {/* Модальное окно в котором формы для редактирования данных */}
      <ModalFormEdit
        title={currentEditForm.title}
        currentEditForm={currentEditForm}
        setCurrentEditForm={(value) => setCurrentEditForm(value)}
      >
        {currentEditForm.typeEditForm === TypeFormEnum.healthDeclaration && (
          <div>
            <StyledTextInfo>
              You can submit your Medical Screening Form. You will be redirected
              to the submission page
            </StyledTextInfo>
            <StyledButtonsWrapper>
              <StyledButtonCancel>{t("cancel")}</StyledButtonCancel>
              <StyledButtonSave>{t("save")}</StyledButtonSave>
            </StyledButtonsWrapper>
          </div>
        )}
      </ModalFormEdit>
    </StyledStateroomsWrapper>
  );
};
