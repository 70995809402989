export const POLICY = [
  {
    rule: "Up to 90 days before the cruise date (12.02.2024) — no penalty"
  },
  {
    rule: "Up to 60-90 days prior to cruise date (12.05.2024) — 60% penalty, ($10.000)"
  },
  {
    rule: "Up to 30-60 days before cruise date (12.06.2024) — 80% penalty, ($12.000)"
  },
  {
    rule: "Less than 30 days before cruise (after 12.06.2024) — 100% penalty, ($15.000)"
  }
];

export const REMARK = "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco."